import { Injectable } from '@angular/core';
import { DataModelService } from './data-model.service';
import { Group, GroupEntity, MethodReturn, Right, RightAndGroup, RightEntity, RightState, User } from './definitions.service';
import { LogService } from './log.service';

//Erklaerung: Ein user hat eine sub-collection mit rechten (ids kommen aus dem RightEntity enum). Der typ ist: Right (definitions). 
//Ein user gehoert ein- oder mehreren Gruppen an, die wiederrum auch Rechte haben. Die Gruppen-Ids werden dem user in der property: userGroups gegeben.
//Vergabe von Gruppenzugehoerigkeiten: zu definierten Zeitpunkten im Jahr. Z.b. nach der Forma Saison. automatisierter reminder

@Injectable({
  providedIn: 'root'
})
export class UserRightsManagementService {

  private _currentUserRights: RightAndGroup[] = undefined; //Klasse um einfachder damit umzgehen. Eine Liste mit Rechten und die dazugehoerige Gruppe.
  private _currentUsersGroups: Group[] = undefined; //Groups sind als collection in Firestore vorhanden
  private _userId: string = undefined;

  constructor(private dataModel: DataModelService, private log: LogService) {
  }

  //Initially loads all data for the current user
  public async init(userId: string) {
    this._userId = userId
    this._currentUserRights = (await this.retrieveUserRights(userId, true, false, true)).data;
    this._currentUsersGroups = (await this.retrieveUserGroups(userId, true, false, true)).data;
  }

  public isPartOfGroup(groupEntity: number): boolean {
    if (this._userId) {
      return this.testing_isPartOfGroup(groupEntity);
    }
    return false;
  }

  public isPartOfAdminGroup(): boolean {
    if (this._userId) {
      return this.testing_isPartOfGroup(GroupEntity.ADMIN.valueOf());
    }
    return false;
  }

  public hasRight(right: RightEntity): boolean {
    if (this._userId) {

      return this.testing_hasRight(right);
    }
    return false;
  }

  /**
 * Checks if this user is part of all groups that are assigned.
 */
  public isPartOfGroups(groupEntities: number[]): boolean {
    if (groupEntities == undefined) {
      return false;
    }
    groupEntities.forEach(entity => {
      if (!this.testing_isPartOfGroup(entity)) {
        return false;
      }
    });
    return true;
  }

  /**
 * Checks if this user has all the rights that are assigned.
 */
  public hasAllRights(rightEntities: number[]): boolean {
    if (rightEntities == undefined) {
      return false;
    }
    rightEntities.forEach(entity => {
      if (!this.testing_hasRight(entity)) {
        return false;
      }
    });
    return true;
  }


  //--------------------------------------------------------------------------


  /**
   * Checks if the assigned user has a given right.
   * 
   * @param userId - the users id
   * @param right - the right to check
   * @returns a promise for a boolan. Returns true if user has right, false if not.
   */
  public testing_hasRight(right: RightEntity): boolean {
    return this._currentUserRights?.find(x => x.right.id == right.valueOf() && x.right.state == RightState.ALLOW.valueOf()) != undefined;
  }

  //Check if user is part of a group. E.g. if part of the admi group.
  public testing_isPartOfGroup(group: number): boolean {
    return this._currentUsersGroups?.find(x => x.id == group) != undefined;
  }

  /**
   * Gets a specific right. It returns the right and its state by the given identifier.
   * 
   * @param userId - the users id
   * @param right - the right to check
   * @returns the right and group combination (if available), undefined if not.
   */
  private async getRight(right: RightEntity): Promise<RightAndGroup> {
    return this._currentUserRights?.find(x => x.right.id == right.valueOf());
  }

  /**
   * Checks if the state of the right is on 'allowed'.
   * 
   * @param userId - the users id
   * @param right - the right to check
   * @returns a promise for a boolan. Returns true if user has right, false if not.
   */
  public async testing_allowed(right: RightEntity): Promise<boolean> {
    var hasRight = await this.testing_hasRight(right);
    if (hasRight) {
      return (await this.getRight(right)).right.state == RightState.ALLOW;
    }
    return false;
  }

  /**
   * Retrieve rights of a user. Includes also inherited rights.
   * 
   * @param userId - user id
   * @returns the promise of a MethodReturn class instance
   */
  private async retrieveUserRights(userId: string, save: boolean = true, useCacheIfAvailable: boolean = false, forceReload: boolean = false): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    var foundIssues = [];
    var loaded = await this.testing_getEffectiveUserRights(userId, foundIssues, useCacheIfAvailable, forceReload);
    if (loaded.result) {
      this.log.log("Successfully loaded user rights");
      if (save)
        this._currentUserRights = loaded.data;
      ret.result = true;
      ret.data = loaded.data;
      return ret;
    } else {
      this.log.error(loaded.errorMessage);
    }
    return ret;
  }

  /**
 * Retrieve groups of a user
 * 
 * @param userId - user id
 * @returns the promise of a MethodReturn class instance
 */
  private async retrieveUserGroups(userId: string, save: boolean = true, useCacheIfAvailable: boolean = false, forceReload: boolean = false): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    var foundIssues = [];
    var loaded = await this.testing_getUserGroups(userId, foundIssues, useCacheIfAvailable, forceReload);
    if (loaded.result) {
      this.log.log("Successfully loaded user groups");
      if (save)
        this._currentUsersGroups = loaded.data;
      ret.result = true;
      ret.data = loaded.data;
      return ret;
    } else {
      this.log.error(loaded.errorMessage);
    }
    return ret;
  }


  /**
   * Get all effective rights of a user. It depends on the personal rights and all inherited rights from all assigned groups.
   * 
   * @param userId - the users id
   * @param outFoundIssues - list of found issues while gathering rights
   * @returns the promise of a MethodReturn class instance
   */
  public async testing_getEffectiveUserRights(userId: string, outFoundIssues: string[] = [], useCacheIfAvailable: boolean = false, forceReload: boolean = false): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    if (userId == undefined || userId == "") {
      ret.errorMessage = "Undefined user id";
      return ret;
    }
    var userBelongsToGroups: Group[] = [];

    if (useCacheIfAvailable) {
      var cacheAvailable = this._currentUserRights != undefined;
      if (cacheAvailable) {
        if (!forceReload) {
          ret.result = true;
          ret.data = this._currentUserRights;
          return ret;
        }
      }
    }

    //get user data
    var loadedUser = await this.dataModel.getUser(userId, true);
    if (!loadedUser.result)
      return loadedUser;
    var effectiveRights: RightAndGroup[] = [];
    var userData: User = loadedUser.data;
    var allGroups: Group[] = [];
    var userSpecificRights: Right[] = [];

    //get groups and rights data
    var usersGroupsIds: number[] = userData.userGroups;

    //get all groups data (if user is assigned to groups)
    if (usersGroupsIds.length > 0) {
      var loadedGroupData = await this.dataModel.getGroups(false, true);
      if (!loadedGroupData.result) {
        this.log.error("Unable to retrieve all groups data")
        var msg = "Unable to retrieve all groups data";
        outFoundIssues.push(msg);
        ret.errorMessage = msg;
        return ret;
      } else
        allGroups = loadedGroupData.data;
    }

    //get user specific rights
    var loadedUserRights = await this.dataModel.getUserSpecificRights(userId, true);
    if (!loadedUserRights.result) {
      var msg = `Unable to retrieve user rights for user with id: ${userId}`;
      outFoundIssues.push(msg);
      ret.errorMessage = msg;
      return ret;
    } else
      userSpecificRights = loadedUserRights.data;

    //get all groups the user belongs to 
    userBelongsToGroups = allGroups.filter(x => usersGroupsIds.includes(x.id));
    this.gatherAllGroups(userBelongsToGroups, allGroups);

    //sort (lowest levels first)
    userBelongsToGroups = userBelongsToGroups.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0)

    //iterate over all groups the user belongs to
    for (var group of userBelongsToGroups) {

      //get group rights
      var loadedGroupRights = await this.dataModel.getGroupRights(group.id, true);
      if (!loadedGroupRights.result) {
        var msg = `Unable to retrieve user rights for user with id: ${userId}`;
        outFoundIssues.push(msg);
        ret.errorMessage = msg;
      } else {
        group.rights = loadedGroupRights.data;

        //iterate over group rights
        for (var right of group.rights) {

          var found = this.getEffectiveRightHelper(right, group, allGroups);
          if (found.result) {
            var foundData: RightAndGroup = found.data;

            //check if already present
            var existing = effectiveRights.find(x => {
              return x.right.id == foundData.right.id;
            });

            if (existing != undefined) {
              var existingGroup: Group = existing.group;
              var existingRight: Right = existing.right;
              var existingLevel: number = existing.group.parent_id;
              var toCheckGroup: Group = foundData.group;
              var toCheckRight: Right = foundData.right;
              var toCheckLevel: number = foundData.group.parent_id;

              //same level?
              if (existingLevel == toCheckLevel) {
                //same state? only a conflict if different states
                if (existingRight.state == toCheckRight.state) {
                  var msg = `Duplicate right found with different values found: (right id: ${existingRight.id} | state: ${existingRight.state} | group id: ${existingGroup.id}) vs. (right id: ${toCheckRight.id} | state: ${toCheckRight.state} | group id: ${toCheckGroup.id})`;
                  this.log.error(msg);
                  outFoundIssues.push(msg);
                }
              }
            } else
              effectiveRights.push(found.data);//right not existing in results -> add          
          }
        };
      }
    };

    //now lets check the user specific rights. If there is one defined, it will override any from group. Otherwise, the right is take right away.
    userSpecificRights.forEach(r => {
      var ixExisting = effectiveRights.findIndex(x => x.right.id == r.id);
      if (ixExisting >= 0) {
        var toInsert: RightAndGroup = { right: r, group: undefined };
        effectiveRights[ixExisting] = toInsert;
      } else {
        var toInsert: RightAndGroup = { right: r, group: undefined };
        effectiveRights.push(toInsert);
      }
    });

    ret.result = true;
    ret.data = effectiveRights;
    return ret;
  }


  /**
 * Get all groups a user is assigned to. Includes also the inherited.
 * 
 * @param userId - the users id
 * @param outFoundIssues - list of found issues while gathering groups
 * @returns the promise of a MethodReturn class instance
 */
  private async testing_getUserGroups(userId: string, outFoundIssues: string[] = [], useCacheIfAvailable: boolean = false, forceReload: boolean = false): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    if (userId == undefined || userId == "") {
      ret.errorMessage = "Undefined user id";
      return ret;
    }

    if (useCacheIfAvailable) {
      var cacheAvailable = this._currentUsersGroups != undefined;
      if (cacheAvailable) {
        if (!forceReload) {
          ret.result = true;
          ret.data = this._currentUsersGroups;
          return ret;
        }
      }
    }

    //get user data
    var loadedUser = await this.dataModel.getUser(userId, true);
    if (!loadedUser.result)
      return loadedUser;
    var userData: User = loadedUser.data;
    var allGroups: Group[] = [];

    //get groups and rights data
    var usersGroupsIds: number[] = userData.userGroups;

    //get all groups data (if user is assigned to groups)
    if (usersGroupsIds.length > 0) {
      var loadedGroupData = await this.dataModel.getGroups(false, true);
      if (!loadedGroupData.result) {
        this.log.error("Unable to retrieve all groups data")
        var msg = "Unable to retrieve all groups data";
        outFoundIssues.push(msg);
        ret.errorMessage = msg;
        return ret;
      } else
        allGroups = loadedGroupData.data;
    }

    //get all groups the user belongs to 
    // var userBelongsToGroups: Group[] = allGroups.filter(x => usersGroupsIds.find(y => y == x.id) != undefined);
    var userBelongsToGroups: Group[] = allGroups.filter(x => { return usersGroupsIds.includes(x.id) });
    this.gatherAllGroups(userBelongsToGroups, allGroups);

    //sort (lowest levels first)
    userBelongsToGroups = userBelongsToGroups.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0)

    ret.result = true;
    ret.data = userBelongsToGroups;
    return ret;
  }


  /**
   * Iterates through the group levels to get the effective rights.
   * 
   * @param right - the right to check
   * @param group - the group to check
   * @param allGroups - a list of all available groups
   * @returns a MethodReturn
   */
  private getEffectiveRightHelper(right: Right, group: Group, allGroups: Group[]): MethodReturn {
    var ret: MethodReturn = { result: false };
    var retData: RightAndGroup;

    if (group == undefined) {
      ret.errorMessage = "Group not found";
      return ret;
    }
    var upperGroup = allGroups.find(x => x.id == group.parent_id);
    var foundInThisGroup = false;

    //iterate over group rights
    group.rights.forEach(x => {

      if (x.id == right.id) {
        foundInThisGroup = true;

        if (x.state == RightState.ALLOW.valueOf() || x.state == RightState.DECLINE.valueOf()) {
          var insert: RightAndGroup = { right: x, group: group };
          retData = insert;
          ret.result = true;
          return ret;
        }
        else if (x.state == RightState.INHERIT) {
          //check for right in higher leveled group
          ret = this.getEffectiveRightHelper(right, upperGroup, allGroups);
          if (ret.result)
            retData = ret.data;
          return ret;
        } else {
          // right is undefined
          this.log.error(`Right: id: ${right.id}, name: ${right.name} is undefined.`);
          ret.errorMessage = `Right not found in group: (id: ${group.id}, name: ${group.name}).`;
          return ret;
        }
      }
    });

    if (!foundInThisGroup) {
      //right was not found in this group. But it's possible that the right is defined a level up
      //check for right in higher leveled group
      ret = this.getEffectiveRightHelper(right, upperGroup, allGroups);
      if (ret.result)
        retData = ret.data;
    }
    ret.data = retData;
    return ret;
  }


  /**
   * Gathers all groups by parsing through the tree to the top.
   * 
   * @param intialList - the list of groups that are assigned to a user.
   * @param allGroups - a list of all available groups.
   * @returns a list of found groups
   */
  private gatherAllGroups(intialList: Group[], allGroups: Group[]): Group[] {
    var ret: Group[] = intialList;
    intialList.forEach(element => {
      var found: Group[] = this.gatherAllGroupsHelper(element, allGroups);
      found.forEach(item => {
        var alreadyExisting = ret.find(x => x.id == item.id);
        if (alreadyExisting == undefined)
          ret.push(item);
      });
    });
    return ret;
  }

  /**
   * Helper to interate through the groups tree.
   * 
   * @param current - the current group to check
   * @param allGroups - a list of all available groups
   * @returns a list of found groups
   */
  private gatherAllGroupsHelper(current: Group, allGroups: Group[]): Group[] {
    var ret: Group[] = [];
    //check if parent is available
    var foundParent = allGroups.find(x => x.id == current.parent_id);
    if (foundParent == undefined) {
      //parent not found -> end
      return ret;
    } else {
      ret.push(foundParent);
      var nextItems = this.gatherAllGroupsHelper(foundParent, allGroups);
      //add found
      nextItems.forEach(element => {
        ret.push(element);
      });
    }
    return ret;
  }

  //------------------------Testing ------------------------

  public setTestGroups(groups: Group[]) {
    this.dataModel.setTestGroups(groups);
  }

  public setTestUsers(users: User[]) {
    this.dataModel.setTestUsers(users);
  }

}
