import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { Group, MethodReturn, Right, User } from '../services/definitions.service';
import { MockFirestoreService } from './mock-firestore-service';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class MockDataModelService {
  groups: Group[] = [];
  users: User[] = [];

  constructor(private db: MockFirestoreService) { }

  private async getData(collectionName: string): Promise<any> {
    return this.db
      .collection(collectionName)
      .valueChanges()
      .subscribe((data) => {
        return data;
      });
  }

  public get(collectionName: string): Promise<MethodReturn> {
    return this.getData(collectionName);
  }

  public async getGroup(id: number): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    var found = this.groups.find(x => x.id == id);
    if (found) {
      ret.data = found;
      ret.result = true;
    }
    return await ret;
  }

  public addGroup(group: Group): MethodReturn {
    var ret: MethodReturn = { result: false };
    var found = this.groups.find(x => x.id == group.id);
    if (!found) {
      this.groups.push(group);
      ret.result = true;
      return ret;
    }
    ret.errorMessage = "Group already existing";
    return ret;
  }

  public async getGroupRights(groupId: number) {
    var ret: MethodReturn = { result: false };
    var found = this.groups.find(x => x.id == groupId);
    if (found) {
      ret.data = found.rights ?? [];
      ret.result = true;
    }
    return await ret;
  }

  public addGroupRight(group: Group, right: Right) {
    var ret: MethodReturn = { result: false };
    var found = this.groups.find(x => x.id == group.id);
    if (found) {

      //check if a conflict would appear
      var conflict = this.checkForDuplicateOnSameLevel(right, found.parent_id);
      if (conflict) {
        var msg = `The assigned right (id: ${right.id}, name:  ${right.name}) is already present in group of same level(${found.parent_id}) : (id: ${group.id}, name: ${group.name})`;
        ret.errorMessage = msg;
        console.log(msg);
        return ret;
      }

      //get rights
      var groupRights: Right[] = found.rights ?? [];
      var existing = groupRights.find(x => x.id == right.id);

      if (existing == undefined) {
        if (found.rights == undefined)
          found.rights = [];
        found.rights.push(right);//add
        ret.result = true;
      }
    }
    return ret;
  }

  /**
   * Checks for a group on the same level, that contains this right.
   * 
   * @param right - the right to check for
   * @param level - the level to compare
   * @returns true if a duplicate was found, false if not.
   */
  private checkForDuplicateOnSameLevel(right: Right, level: number): boolean {
    //search for a group on the same level, that contains this right.
    var found = this.groups.find(x => x.parent_id == level);
    if (found == undefined)
      return false;//no duplicate group on this level found

    //get group's rights
    var groupRights = found.rights;
    if (groupRights == undefined)
      return false;

    var dupRightFound = groupRights.find(x => x.id == right.id);
    if (dupRightFound != undefined)
      return true;
    return false;
  }

  //NOTE: For the sake of simplification for testing we assume that the user rights are not in a sub collection.
  public async getUserSpecificRights(id: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: true };
    var foundUser = this.getUser(id);
    if (!(await foundUser).result) {
      return foundUser;
    }
    ret.data = (await foundUser).data.rights ?? [];
    return await ret;
  }

  //NOTE: For the sake of simplification for testing we assume that the user rights are not in a sub collection.
  public async addUserSpecificRight(id: string, right: Right): Promise<MethodReturn> {
    var ret: MethodReturn = { result: true };
    var foundUser = this.getUser(id);
    if (!(await foundUser).result) {
      return foundUser;
    }
    var user: User = (await foundUser).data;
    if (user.rights == undefined) {
      user.rights = [];
    }
    user.rights.push(right);
    ret.data = (await foundUser).data.rights;
    return await ret;
  }

  public getFirstUser(): User {
    return this.users[0];
  }

  public async getUser(id: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    var found = this.users.find(x => x.uid == id);
    if (found) {
      ret.data = found;
      ret.result = true;
    }
    return await ret;
  }

  public addUser(user: User): MethodReturn {
    var ret: MethodReturn = { result: false };
    var found = this.users.find(x => x.uid == user.uid);
    if (!found) {
      this.users.push(user);
      ret.result = true;
      return ret;
    }
    ret.errorMessage = "User already existing";
    return ret;
  }

  public async getGroups(): Promise<MethodReturn> {
    var ret: MethodReturn = { result: true };
    ret.data = this.groups;
    return await ret;
  }

  public addGroupToUser(user: User, group: Group): MethodReturn {
    var ret: MethodReturn = { result: false };
    var groups = user.userGroups ?? [];

    var existing = groups.includes(group.id);
    if (existing) {
      ret.errorMessage = `User already in group: (id: ${group.id}, name: ${group.name})`;
      return ret;
    }

    user.userGroups.push(group.id);
    ret.result = true;
    return ret;
  }

  public setTestGroups(groups: Group[]) {
    this.groups = groups;
  }

  public getTestGroups(): Group[] {
    return this.groups ?? [];
  }

  public setTestUsers(users: User[]) {
    this.users = users;
  }

  public getTestUsers(): User[] {
    return this.users ?? [];
  }
}
