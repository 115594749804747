import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonFunctionsService } from '../services/common-functions.service';
import { DataModelService } from '../services/data-model.service';
import { LogService } from '../services/log.service';
import {
  AccountingYearWorkHour,
  Event,
  EventViewMode,
  IEvent,
  Properties,
  Sub_WorkPackage,
  User,
} from './../services/definitions.service';
import {
  ComponentState,
  ThreadSafeService,
} from '../services/thread-safe-component-state.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  providers: [ThreadSafeService], // Provide the service here
})
export class EventsComponent implements OnInit {
  public allEvents: IEvent[] = [];
  public pastEvents: IEvent[] = [];
  public users: User[] = [];
  public accountingYear: AccountingYearWorkHour;
  public userOptions: User[];
  public enumViewMode = EventViewMode;
  private userData: User;
  public centerTextMyShifts: string = '';
  public centerTextDoneShifts: string = '';
  public centerTextShiftRegister: string = '';
  swipeCoord: [number, number];
  selectedTab: number;
  swipeTime: number;
  private componentState: ComponentState;
  private debugModeOn: boolean = false;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private threadSafeService: ThreadSafeService
  ) {
    this.threadSafeService.variable$.subscribe((value) => {
      this.componentState = value;
      if (this.componentState.doneLoading()) {
        if (this.debugModeOn)
          console.log('(EventsComponent) completely loaded');
      }
    });
    this.threadSafeService.setName('EventsComponent');

    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  ngOnInit(): void {
    this.loadEvents();
    this.loadAccountingYear();
    this.loadUsers();
  }

  //Load all events
  private async loadEvents() {
    var ret = await this.dataModel.get(Event(), true, false);
    if (ret.result) {
      //filtere nach events die freigegeben und nicht abgelaufen sind
      this.allEvents = this.common.loadNonOutdatedEvents(ret.data);
      this.pastEvents = this.common.loadPastEvents(ret.data, true);//only of this year

      if (this.allEvents.length == 0) {
        this.centerTextMyShifts = 'Bei keiner Schicht angemeldet';
        this.centerTextShiftRegister = 'Keine Schichten vorhanden';
      }

      if (this.pastEvents.length == 0) {
        this.centerTextDoneShifts = 'Keine abgeschlossenen Schichten';
      } else {
        //load workpackages for these events, because we are not using intermediate view <event-view>
        this.pastEvents.forEach((event) => {
          this.loadWorkPackages(event);
        });
        //sort
        this.pastEvents.sort((a, b) =>
          a[Properties.Start] < b[Properties.Start] ? 1 : -1
        );
      }

      //data of this component has been loaded
      this.threadSafeService.setComponentDataLoaded();

      //set list of workpackages that have to be resolved
      this.threadSafeService.setSubComponentsIdsList(
        this.getAllEventViewIds(this.allEvents)
      );
    }
  }

  //gets all event ids
  private getAllEventViewIds(events: IEvent[]): string[] {
    var ids: string[] = [];
    if (events) {
      events.forEach((element) => {
        ids.push(element.id);
      });
    }
    return ids;
  }

  //Raised when all sub components of a view have been loaded
  public eventViewLoaded(event: IEvent) {
    // console.log(`(EventComponent): sub comp. event view (view mode: ${EventViewMode[EventViewMode.Reader]}) loaded: `+ event.id);
    this.threadSafeService.setSubComponentLoaded(event.id);
  }

  private async loadUsers() {
    this.common.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.users = allUsersData;
          this.userOptions = options;
        }
      },
      false,
      true
    );
  }

  //loads the current accounting year
  private loadAccountingYear() {
    this.dataModel.loadCurrentAccountingYear().then(
      (fulfilled) => {
        this.accountingYear = fulfilled.data;
      },
      (rejectMsg) => {
        this.common.showErrorToast(
          'Konnte Daten des Abrechnungsjahr nicht laden'
        );
        this.log.error(rejectMsg, EventsComponent.name);
      }
    );
  }

  //Handle swipe between tabs
  public swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;
      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        if (this.selectedTab == undefined) this.selectedTab = 0;

        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        console.info(swipe);
        if (swipe === 'next') {
          const isFirst = this.selectedTab === 0;
          if (this.selectedTab <= 3) {
            this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
          }
          console.log('Swipe left — INDEX: ' + this.selectedTab);
        } else if (swipe === 'previous') {
          const isLast = this.selectedTab === 4;
          if (this.selectedTab >= 1) {
            this.selectedTab = this.selectedTab - 1;
          }
          console.log('Swipe right — INDEX: ' + this.selectedTab);
        }
      }
    }
  }

  //show debug info about a single work package
  public showDebugInfo(event: IEvent, viewMode: EventViewMode) {
    if (!this.debugModeOn) {
      return '';
    }
    return `Overall visibility (${event.id}) (mode: ${viewMode}): ${this.common.showEventView(event, viewMode, this.userData)}`;
  }

  //Load all work packages of an event
  private async loadWorkPackages(event: IEvent) {
    var path = `${Event()}/${event.id}/${Sub_WorkPackage()}`;
    var wpLoaded = await this.dataModel.get(path, false, true);
    if (!wpLoaded.result) {
      this.common.showErrorToast('Schichten konnten nicht geladen werden');
      this.log.error(wpLoaded.errorMessage);
    } else {
      event.workPackages = wpLoaded.data;
    }
  }
}
