import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsAdminComponent } from './events-admin/events-admin.component';
import { HomeComponent } from './home/home.component';
import { IdCardComponent } from './id-card/id-card.component';
import { LoginComponent } from './login/login.component';
import { PointAccountsAdminComponent } from './point-accounts-admin/point-accounts-admin.component';
import { PointReceiveComponent } from './point-receive/point-receive.component';
import { PointSendComponent } from './point-send/point-send.component';
import { ProtocolViewComponent } from './protocol-view/protocol-view.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RoleGuardService } from './services/role-guard.service';
import { StatisticsComponent } from './statistics/statistics.component';
import { WorkingHourAdminComponent } from './working-hour-admin/working-hour-admin.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { SettingsComponent } from './settings/settings.component';
import { PreloadAllModules } from '@angular/router';
import { EventsComponent } from './events/events.component';
import { DbSyncUploadComponent } from './db-sync-upload/db-sync-upload.component';
import { CalendarComponent } from './calendar/calendar/calendar.component';
import { GroupEntity, RightEntity } from './services/definitions.service';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'id-card',
    component: IdCardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'working-hours',
    component: WorkingHoursComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'point-send',
    component: PointSendComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'protocols',
    component: ProtocolViewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'point-receive',
    component: PointReceiveComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRights: [RightEntity.FCT_RECEIVE_ENC_POINTS]
    },
  },
  {
    path: 'working-hour-admin',
    component: WorkingHourAdminComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: [GroupEntity.ADMIN],
    },
  },
  {
    path: 'point-accounts-admin',
    component: PointAccountsAdminComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: [GroupEntity.ADMIN],
    },
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: [GroupEntity.ADMIN],
    },
  },
  {
    path: 'protocol-view',
    component: ProtocolViewComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: [GroupEntity.ADMIN],
    },
  },
  {
    path: 'events-admin',
    component: EventsAdminComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: [GroupEntity.EVENT_ADMIN], //This an AND. User has to have all defined rights
    },
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'db-sync-upload',
    component: DbSyncUploadComponent
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
