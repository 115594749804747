import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import {
  IEvent,
  IWorkPackage,
  Sub_Participant,
  EventState,
  User,
} from 'src/app/services/definitions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import { LogService } from 'src/app/services/log.service';
import 'firebase/firestore';

@Component({
  selector: 'report-wp-view',
  templateUrl: './report-wp-view.component.html',
  styleUrls: ['./report-wp-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ReportWpViewComponent implements OnInit {
  @Input() data: IWorkPackage;
  @Input() event: IEvent;
  @Output() show = new EventEmitter<boolean>(); // shall indicate if the work packages has decided for itself to show

  public userData: User;
  public showComponent: boolean = false;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  // Determine "show"
  // Rules:
  // visible: participates shift, is shift organizer, is event organizer, when shift and event are over
  // invisible: does not participate shift, when shift and event are running
  public doShow(): boolean {
    var participatesShift = this.participatesShift();
    var isShiftOrganizer = this.isShiftOrganizer();
    var isEventOrganizer = this.isEventOrganizer();
    var shiftOver = this.shiftOver();

    if (
      (participatesShift || isShiftOrganizer || isEventOrganizer) &&
      shiftOver
    ) {
      this.show.emit(true);
      this.showComponent = true;
    } else {
      this.show.emit(false);
      this.showComponent = false;
    }
    return this.showComponent;
  }

  ngOnInit(): void {
    //load participants
    if (this.data?.participants == undefined) {
      this.loadParticipants();
    }
  }

  ngAfterViewInit() {}

  // Loads all participants
  public async loadParticipants() {
    var path = `${this.data.path}/${Sub_Participant()}`;
    var loaded = await this.dataModel.get(path, false, true);
    if (!loaded.result) {
      this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
      this.log.error(loaded.errorMessage);
    } else {
      this.data.participants = loaded.data;
      this.doShow();
    }
  }

  public participatesShift(): boolean {
    return this.common.participatesInShift(this.data, this.userData.uid);
  }

  public isShiftOrganizer(): boolean {
    return this.common.isShiftOrganizer(this.data, this.userData.uid);
  }

  public isEventOrganizer(): boolean {
    return this.common.isEventOrganizer(this.event, this.userData);
  }

  public shiftOver() {
    return this.common.getRunningState(this.data) == EventState.Past;
  }

  // public getCalculatedWorkHours(): number {
  //   return this.common.calculateWorkHoursFromStartAndEnd(this.data.start, this.data.end);
  // }
}
