import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import {
  BottomSheetComponent,
  BottomSheetData,
  ViewDataType,
} from '../bottom-sheet/bottom-sheet.component';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import {
  AppViewVisibilityIndex,
  Properties,
  RightEntity,
  User,
  WorkHoursService,
} from '../services/definitions.service';
import { LogService } from '../services/log.service';
import { UserRightsManagementService } from '../services/user-rights-management.service';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.css'],
})
export class WorkingHoursComponent implements OnInit {
  public userData: User;
  public protocolWorkHoursData: any;
  public usersWorkHoursData: any;
  private accountingYearData: any;
  private workHoursService: WorkHoursService;
  public debug: boolean;


  constructor(
    private bottomSheet: MatBottomSheet,
    private commonFunctions: CommonFunctionsService,
    private spinner: NgxSpinnerService,
  ) {
    this.debug = environment.debug;
    this.workHoursService = new WorkHoursService();
    spinner.show();

    //user data
    this.commonFunctions.registerEventEmitterHandlerUserData((data: User) => {
      this.userData = data;
      this.spinner.hide();
    });
    //work hours
    this.commonFunctions.registerEventEmitterHandlerUserWorkHours((data) => {
      this.usersWorkHoursData = data;
      //sort data by timestamp
      this.usersWorkHoursData.sort((a, b) =>
        a[Properties.Timestamp] < b[Properties.Timestamp] ? 1 : -1
      );
    });
    //protocol work hours
    this.commonFunctions.registerEventEmitterHandlerProtocolWorkHours(
      (data) => {
        this.protocolWorkHoursData = data;
      }
    );
    //accounting years
    this.commonFunctions.registerEventEmitterHandlerAccountingYear((data) => {
      this.accountingYearData = data;
    });
  }

  ngOnInit() { }

  //Berechnet die Mindestanzahl an Arbeitsstunden die der User leisten muss
  public getMinWorkHoursToPerform() {
    if (this.userData && this.userData?.personalData?.birthDate) {
      if (this.accountingYearData) {
        var birthDate = this.userData?.personalData?.birthDate;
        var o: object = birthDate;
        var asDate: Date;

        var parsed = parseInt(o.toString(), 10);
        if (!Number.isNaN(parsed)) {
          //convert to unix firestore timestamp
          asDate = new Date(parsed * 1000);
        } else {
          asDate = birthDate.toDate();
        }

        var multiplyer = this.workHoursService.getPointMultiplyer(asDate);
        //Die Anzahl der Veranstaltungen ist in dem aktuellen accountingYearWorkHour entry festgelegt
        var minWorkHours = this.accountingYearData.data.amountEvents;
        return Math.floor(minWorkHours * multiplyer);
      }
    }
    return undefined;
  }

  public getCircleColor() {
    if (this.userData) {
      var currentWOrkHourCount = this.userData?.sumWorkHours;
      var minHours = this.getMinWorkHoursToPerform();
      if (minHours) {
        if (currentWOrkHourCount >= minHours) {
          return '#00ff00'; //green
        } else {
          return '#fc0303'; //red
        }
      }
    }
    return '#3936e2'; //default
  }

  public showWorkHoursHistory() {
    let data = new BottomSheetData(
      this.usersWorkHoursData,
      ViewDataType.ENCOURAGEMENT_POINTS
    );
    this.bottomSheet.open(BottomSheetComponent, {
      data: data,
    });
  }

  public showInfoDialog() {
    this.commonFunctions.openDialog(
      'Mindestanzahl',
      'Die Anzahl der Arbeitsstunden hängt von deinem Alter und der Anzahl der Veranstaltungen im Jahr ab. Beispiel: Angenommen du bist über 18 Jahre Alt, so ist die Formel: Mindestzahl = Anzahl Veranstaltungen * 2 * (3/3)',
      DialogType.INFO
    );
  }

}
