{{ showGWpDebugInfo() }}

<mat-card *ngIf="showOverall" style="margin-top: 10px">
  <mat-card-header>
    <mat-card-subtitle>
      {{ getDate() | date : "dd.MM.YYYY" }}
    </mat-card-subtitle>
    <mat-card-title>{{ getDayName(getDate()) }}</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngFor="let wps of groupedWps">
      <table
        *ngFor="let wp of wps"
        [style.display]="showWp(wp) || isDebugMode() ? '' : 'none'"
      >
        <tr>
          <td>
            {{ showWpDebugInfo(wp) }}

            <wp-view
              [data]="wp"
              [users]="users"
              [accountingYear]="accountingYear"
              [userOptions]="userOptions"
              [event]="event"
              [viewMode]="viewMode"
              (componentChanged)="wpChanged($event)"
              (componentLoaded)="wpViewLoaded(wp)"
            ></wp-view>
          </td>
        </tr>
      </table>
    </div>
  </mat-card-content>
  <!-- <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions> -->
  <!-- <mat-card-footer>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer> -->
</mat-card>

<mat-card style="margin: 10px" *ngIf="!showOverall">
  <mat-card-header>
    <mat-card-title>{{ getDayName(viewData?.date) }}</mat-card-title>
  </mat-card-header>
  <mat-card-content style="margin-left: 15px"
    >Alle Schichten belegt oder beendet</mat-card-content
  >
</mat-card>
