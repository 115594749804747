<!--Name input field-->
<form class="name-selection-box" *ngIf="userDataLoaded">
  <mat-form-field appearance="fill">
    <mat-label>Namen eingeben...</mat-label>
    <input type="text" placeholder="" aria-label="Namen eingeben..." matInput [formControl]="userEntryControl"
      [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="optionSelected($event.option.value)">
      <mat-option *ngFor="let option of filteredNameOptions | async" [value]="option">
        {{ option.personalData?.firstName }}
        {{ option.personalData?.lastName }} ({{ option.email }})
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="userEntryControl.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="userEntryControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>

<!--Tab group-->
<mat-tab-group mat-align-tabs="center" style="margin-left: 20px; margin-right: 20px">
  <div style="padding-top: 40px">
    <form [formGroup]="form">
      <mat-tab label="Vergebene Punkte">
        <!------------------------------------------------------------------------------------------->

        <div *ngIf="showTable" style="margin: 20px; padding-bottom: 100px">
          <mat-form-field appearance="standard" style="
              font-size: 14px;
              width: 100%;
              padding-left: 10%;
              padding-right: 10%;
            ">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilterTable1($event)" placeholder="" #input />
          </mat-form-field>

          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceTable1" matSort class="mat-elevation-z8" matSortActive="timestamp"
              matSortDirection="desc">
              <!--Amount-->
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Anzahl
                </th>
                <td mat-cell *matCellDef="let row" style="padding-left: 35px">
                  {{ row.amount }}
                </td>
              </ng-container>

              <!--Reason-->
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Grund</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.reason | encPointEntryReason }}
                </td>
              </ng-container>

              <!--Comment-->
              <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Kommentar
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.actionComment }}
                </td>
              </ng-container>

              <!--Given to-->
              <ng-container matColumnDef="givenTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Vergeben an
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.receiverUserName }}
                </td>
              </ng-container>

              <!--timestamp-->
              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Zeitpunkt
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.timestamp.seconds * 1000 | date: "dd.MM.yyyy, H:mm" }}
                  Uhr
                </td>
              </ng-container>

              <!--Created by-->
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Erstellt von
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.actionDoneByName }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable1"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge vorhanden</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
          </div>

          <div class="box">
            <mat-card>
              <table>
                <tr style="text-align: center">
                  <td>
                    Gesamtzahl: <b>{{ calcSum(givenPoints) }}</b>
                  </td>
                </tr>
              </table>
            </mat-card>
          </div>
        </div>

        <!--------------------------------------------------------------------------------------------->
      </mat-tab>

      <mat-tab label="Erhaltene Punkte">
        <!------------------------------------------------------------------------------------------->

        <div *ngIf="showTable" style="margin: 20px; padding-bottom: 100px">
          <mat-form-field appearance="standard" style="
              font-size: 14px;
              width: 100%;
              padding-left: 10%;
              padding-right: 10%;
            ">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilterTable2($event)" placeholder="" #input />
          </mat-form-field>

          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceTable2" matSort class="mat-elevation-z8" matSortActive="timestamp"
              matSortDirection="desc">
              <!--Amount-->
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Anzahl
                </th>
                <td mat-cell *matCellDef="let row" style="padding-left: 35px">
                  {{ row.amount }}
                </td>
              </ng-container>

              <!--Reason-->
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Grund</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.reason | encPointEntryReason }}
                </td>
              </ng-container>

              <!--Comment-->
              <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Kommentar
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.actionComment }}
                </td>
              </ng-container>

              <!--Got from-->
              <ng-container matColumnDef="gotFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Erhalten von
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.actionDoneByName }}
                </td>
              </ng-container>

              <!--timestamp-->
              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Zeitpunkt
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.timestamp.seconds * 1000 | date: "dd.MM.yyyy, H:mm" }}
                  Uhr
                </td>
              </ng-container>

              <!--Created by-->
              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Erstellt von
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.actionDoneByName }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable2"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge vorhanden</td>
              </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
          </div>

          <div class="box">
            <mat-card>
              <table>
                <tr style="text-align: center">
                  <td>
                    Gesamtzahl: <b>{{ calcSum(gotPoints) }}</b>
                  </td>
                </tr>
              </table>
            </mat-card>
          </div>
        </div>

        <!--------------------------------------------------------------------------------------------->
      </mat-tab>
    </form>
  </div>
</mat-tab-group>