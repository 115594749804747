import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CommonFunctionsService,
  DialogType,
} from 'src/app/services/common-functions.service';
import {
  AddParticipantsDialogData,
  AddWpDialogData,
  IEvent,
  IWorkPackage,
  IComponentChanged,
  Sub_Participant,
  EventViewMode,
  EventState,
  User,
  WorkPackageKind,  
  WpViewTitle,
  WpViewVisibilityIndex,
  QrCodeDialogData,
  QrCodeTrigger,
  Properties,
} from 'src/app/services/definitions.service';
import { AddParticipantsComponent } from '../../dialogs/add-participants/add-participants.component';
import { MatDialog } from '@angular/material/dialog';
import { DataModelService } from 'src/app/services/data-model.service';
import { LogService } from 'src/app/services/log.service';
import { AddWpComponent } from '../../dialogs/add-wp/add-wp/add-wp.component';
import { MatAccordion } from '@angular/material/expansion';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { QrCodeDialog } from 'src/app/dialogs/qr-code/qr-code.component';
import { UserRightsManagementService } from 'src/app/services/user-rights-management.service';

@Component({
  selector: 'wp-view',
  templateUrl: './wp-view.component.html',
  styleUrls: ['./wp-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WpViewComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() data: IWorkPackage;
  @Input() users: User[];
  @Input() accountingYear: any;
  @Input() userOptions: User[];
  @Input() event: IEvent;
  @Input() viewMode: EventViewMode;
  @Output() componentChanged = new EventEmitter<IComponentChanged>();
  @Output() componentLoaded = new EventEmitter<boolean>();

  panelOpenState = false;
  wpKindEnum = WorkPackageKind;
  public releaseForUsersChecked = true;
  public visibility = WpViewVisibilityIndex;
  public userData: User;
  public showComponent: boolean = true; // has to initially stay on true for initalizing data
  public componentDisabled: boolean = true;
  public loading: boolean = true;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private authService: AuthService,
    private userRightsManagemetService: UserRightsManagementService
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.update();
    if (this.data) {
      //To avoid: Expression has changed after it was checked
      setTimeout(() => {
        if (this.data?.releasedForUsers) {
          this.releaseForUsersChecked = this.data.releasedForUsers;
        } else {
          this.releaseForUsersChecked = false;
        }
      }, 500);
    }

  }

  // Define rule if to show a work package or not
  public doShow() {
    this.showComponent = this.common.showWpView(
      this.data,
      this.viewMode,
      this.userData
    );
    return this.showComponent;
  }

  //Gets the state of this event: not started, running, past
  public getRunningState(): string {
    return this.common.getRunningState(this.data);
  }

  //Load all work packages of an event
  public async showParticipantsEditDialog() {
    var path = `${this.data.path}/${Sub_Participant()}`;
    var loaded = await this.dataModel.get(path);
    if (!loaded.result) {
      this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
      this.log.error(loaded.errorMessage);
    } else {
      var loadedParticipants = loaded.data;

      var data: AddParticipantsDialogData = {
        result: 0,
        allUserData: this.users,
        workPackage: this.data,
        path: this.data.path,
        parentPath: this.data.parent,
        id: this.data.id,
        participants: loadedParticipants,
      };
      this.dialog
        .open(AddParticipantsComponent, {
          width: '90%',
          data: data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result.result == 1) {
            this.update(true);
          }
        });
    }
  }

  // Loads all participants
  private async update(emitChange: boolean = false) {
    var path = `${this.data.path}/${Sub_Participant()}`;
    var loaded = await this.dataModel.get(path, false, true);
    if (!loaded.result) {
      this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
      this.log.error(loaded.errorMessage);
    } else {

      //Sortiere nach Zeitpunkt der Anmeldung (aktuell noch kein Property dafuer)
      this.data.participants?.sort((a, b) => {
        if (Properties.RegistrationTime in a && Properties.RegistrationTime in b) {
          return a[Properties.RegistrationTime] < b[Properties.RegistrationTime] ? 1 : -1;
        }
        return -1;
      }
      );

      this.loading = false;
      if (emitChange) {
        this.emitChange();
      }
    }
    this.doShow();//trigger once to evaluate var 'showComponent'
  }

  //Edit a work package
  public async edit() {
    if (this.event == undefined) {
      this.common.showErrorToast('Event hat keine Daten');
      return;
    }
    if (this.accountingYear == undefined) {
      this.common.showErrorToast('Daten zum Abrechnungsjahr nicht geladen');
      return;
    }
    //check if construction- and/or dismantling-shift is already present
    var constructionAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.event.workPackages,
      WorkPackageKind.Construction
    );
    var dismantlingAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.event.workPackages,
      WorkPackageKind.Dismantling
    );

    var data: AddWpDialogData = {
      result: 0,
      allUserData: this.users,
      userOptions: this.userOptions,
      path: this.data.path,
      parentPath: this.data.parent,
      type: this.data.type,
      minParticipants: this.data.minParticipants,
      id: this.data.id,
      responsiblePerson: this.data.responsiblePerson,
      responsiblePersonId: this.data.responsiblePersonUid,
      start: this.data.start,
      end: this.data.end,
      eventData: this.event,
      kind: this.data.kind,
      constructionShiftPresent: constructionAlreadyCreated,
      dismantlingShiftPresent: dismantlingAlreadyCreated,
      isUe18: this.data.isUe18,
      skipShiftCollisionCheck: this.data.skipShiftCollision
    };

    this.dialog
      .open(AddWpComponent, {
        width: '550px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          this.emitChange();
        }
      });
  }

  //Raised on button click to delete an work package
  public async delete() {
    this.common
      .openDialog(
        'Löschen',
        'Schicht wirklich löschen?',
        DialogType.INFO,
        'Nein',
        'Ja'
      )
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          //delete all participants of work packages
          var deletedParticipants = await this.dataModel.deleteParticipants(
            this.data.path
          );
          if (!deletedParticipants.result) {
            this.log.error(
              'Failed to delete participants of work package: ' + this.data.id
            );
          }
          //delete work package
          var ret = await this.dataModel.deleteGeneric(this.data.path, false);
          if (ret) {
            this.common.showSuccessToast('Schicht gelöscht');
          } else {
            this.common.showErrorToast('Schicht konnte nicht gelöscht werden');
          }
          this.emitChange();
        }
      });
  }

  //Signals the parent that something has changed in this component
  private emitChange() {
    var changed: IComponentChanged = { id: this.data.id };
    this.componentChanged.emit(changed);
  }

  //Raised when one of the underlying work package views have changed
  public onWpChanged(changeEvent: IComponentChanged) {
    this.update(true);
  }

  //Gets the color for the panel
  public getPanelColorClass(): string {
    var ret = '';
    if (this.panelOpenState) {
      ret = 'panel-open';
    }
    if (this.data) {
      var currentCount = this.data.participants?.length;
      var max = this.data.minParticipants;
      if (currentCount < max) {
        ret = 'panel-to-less-participants';
        //exclude 'Aufbau' and 'Abbau'
        if (this.common.isConstructionOrDismantling(this.data)) ret = '';
      }
      if (
        this.viewMode == EventViewMode.ShiftRegistration ||
        this.viewMode == EventViewMode.MyShifts
      ) {
        ret = '';
      }
    }
    return ret;
  }

  public onComponentChanged() {
    this.update(true);
  }

  public getDayName(date: Date) {
    return this.common.getDayName(date);
  }

  //Gets the panel header color depending on the running state
  public getHeaderColorClass() {
    switch (this.getRunningState()) {
      case EventState.Past:
        return 'panel-header-gray';
      case EventState.Running:
        return 'panel-header-green';
      default:
        return '';
    }
  }

  //Gets the title text for a work package view
  public getTitleText(): WpViewTitle {
    var ret: WpViewTitle = {
      title: this.data.type,
      timeOfShift: this.getTimeOfShiftFromTo(this.data.start, this.data.end),
    };

    switch (this.viewMode) {
      case EventViewMode.ShiftRegistration:
        if (!this.isConstructionOrDismantling()) {
          ret.freePlaces = this.getFreePlacesText();
        }
        break;
      case EventViewMode.Admin:
        ret.amountParticipants = this.data.participants?.length;
        if (!this.isConstructionOrDismantling()) {
          ret.amountMinParticipants = this.data.minParticipants;
        }
        break;
      case EventViewMode.MyShifts:
      case EventViewMode.SimplifiedAdmin:
        ret.dayName = this.getDayName(this.data.start.toDate());
        break;
    }
    if (this.common.shiftHasEnded(this.data)) ret.state = 'beendet';
    else if (this.getRunningState() == EventState.Running)
      ret.state = 'läuft gerade';
    //Construction or dismantling shift
    if (this.isConstructionOrDismantling()) {
      if (this.cdDefinedStartSet() && this.cdDefinedEndSet())
        ret.state = "beendet"
      if (!this.cdDefinedStartSet() && this.cdDefinedEndSet())
        ret.state = "Startzeit noch nicht gesetzt"
      if (!this.cdDefinedStartSet() && !this.cdDefinedEndSet())
        ret.state = "Startzeit noch nicht gesetzt"
      if (this.cdDefinedStartSet() && !this.cdDefinedEndSet())
        ret.state = "Startzeit noch nicht gesetzt"
    }
    return ret;
  }

  /**
   * Gets the start and end time of a shift.
   *
   * This method is responsible for retrieving the start and end time of a given shift.
   * It assumes that the shift object has 'start' and 'end' properties that are Firestore timestamps.
   *
   */
  private getTimeOfShiftFromTo(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): string {
    var s = this.common.firebaseTimestampToTime(start);
    var e = this.common.firebaseTimestampToTime(end);
    return `${s} bis ${e}`;
  }


  private getFreePlacesText() {
    var free = this.data.minParticipants - this.data.participants?.length;
    if (free <= 0) {
      return `Schicht voll`;
    }
    if (free == 1) {
      return `${free} Platz frei`;
    } else {
      return `${free} Plätze frei`;
    }
  }

  //Raised when 'Freigabe' toggle button is toggled
  public async changeReleaseForUsers(event) {
    if (event) {
      var released = event.checked;
      var ret = await this.dataModel.setWpReleasedForUsers(
        this.data.path,
        released
      );
      if (!ret) {
        this.common.showErrorToast(ret.errorMessage);
      }
    }
  }

  //Shows the qr code for shift registration
  public showQrCode() {
    //open dialog
    var data: QrCodeDialogData = {
      title: this.data.type,
      decisionYes: true,
      qrCodeLink: this.data.path,
      qrCodeTrigger: QrCodeTrigger.SHIFT_REGISTRATION,
    };
    this.dialog
      .open(QrCodeDialog, {
        width: '500px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: false,
      })
      .afterClosed();
  }

  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public onlyEventsAdmin(): boolean {
    return this.authService.isEventsAdmin || this.common.isEventOrganizer(this.event, this.userData);
  }

  public participatesShift(): boolean {
    return this.common.participatesInShift(this.data, this.userData.uid);
  }

  public isShiftOrganizer(): boolean {
    return this.common.isShiftOrganizer(this.data, this.userData.uid);
  }

  public isAdmin(): boolean {
    return this.userRightsManagemetService.isPartOfAdminGroup();
  }

  public shiftOver(): boolean {
    return this.common.getRunningState(this.data) == EventState.Past;
  }

  //Special time property for a construction or dismantling shift
  public cdDefinedStartSet(): boolean {
    return this.data && this.data.definedStart != undefined;
  }

  //Special time property for a construction or dismantling shift
  public cdDefinedEndSet(): boolean {
    return this.data && this.data.definedEnd != undefined;
  }

  public isConstructionOrDismantling(): boolean {
    return this.common.isConstructionOrDismantling(this.data);
  }

  //Controls the visibility of specific ui elements depending on the view mode
  public isVisible(index: WpViewVisibilityIndex): boolean {
    switch (index) {
      case WpViewVisibilityIndex.PanelDesc1:
        return this.viewMode == EventViewMode.Admin || this.isShiftOrganizer();
      case WpViewVisibilityIndex.PanelDesc2:
        return this.viewMode == EventViewMode.SimplifiedAdmin;
      case WpViewVisibilityIndex.PanelDesc3:
        return (
          this.viewMode == EventViewMode.ShiftRegistration ||
          this.viewMode == EventViewMode.MyShifts
        );
      case WpViewVisibilityIndex.FullInfoTable:
        return this.viewMode != EventViewMode.ShiftRegistration;
      case WpViewVisibilityIndex.SmallInfoTable:
        return this.viewMode == EventViewMode.ShiftRegistration;
      case WpViewVisibilityIndex.ParticipantsComponents:
        return (
          this.viewMode != EventViewMode.MyShifts
        );
      case WpViewVisibilityIndex.AddButton:
        if (this.event?.processedInBackend) return false;
        return (
          (this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.SimplifiedAdmin) &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case WpViewVisibilityIndex.DeleteButton:
        if (this.event?.processedInBackend) return false;
        return (
          (this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.SimplifiedAdmin) && this.onlyEventsAdmin()
        );
      case WpViewVisibilityIndex.EditButton:
        if (this.event?.processedInBackend) return false;
        return (
          (this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.SimplifiedAdmin) &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case WpViewVisibilityIndex.ParticipantsCount:
        return this.viewMode != EventViewMode.MyShifts;
      case WpViewVisibilityIndex.FreigabeTooggle:
        if (this.event?.processedInBackend) return false;
        if (this.shiftOver()) return false;
        return (
          (this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.SimplifiedAdmin) &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case WpViewVisibilityIndex.ConstructionDismantlingTableRows:
        return this.data.kind == WorkPackageKind.Standard;
      case WpViewVisibilityIndex.Ue18Text:
        return this.data?.isUe18 != undefined && this.data?.isUe18;
      case WpViewVisibilityIndex.SkipShiftCollisionText:
          return this.data.skipShiftCollision ?? false;
      case WpViewVisibilityIndex.QrCodeFooter:
      case WpViewVisibilityIndex.QrCodeButton:
        if (this.event?.processedInBackend) return false;
        if (this.shiftOver()) return false;
        if (!this.isConstructionOrDismantling()) return false;
        if (!this.onlyEventsAdmin() && !this.isShiftOrganizer() && !this.isAdmin()) return false;
        if (index == WpViewVisibilityIndex.QrCodeFooter && this.viewMode == EventViewMode.Admin) return true;
        if (index == WpViewVisibilityIndex.QrCodeButton && this.viewMode == EventViewMode.SimplifiedAdmin) return true;
        return false;
      default:
        return false;
    }
  }
}
