import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import {
  IParticipant,
  ParticipantState,
  IWorkPackage,
  WorkPackageKind,
  IEvent,
  User,
  UserContact,
  UserMembership,
  UserPersonalData,
  EventViewMode,
  Group,
  Right,
  RightState,
  MethodReturn,
  Properties,
} from '../services/definitions.service';
import firebase from 'firebase/app';
import { WpViewComponent } from '../events-admin/components/wp-view/wp-view.component';
import { WpViewButtonComponent } from '../events-admin/components/wp-view-button/wp-view-button.component';
import { EventViewComponent } from '../events-admin/components/event-view/event-view.component';

@Injectable({
  providedIn: 'root',
})
export class MockFirestoreService {
  private mockData: any = {
    collectionName: [
      { id: '1', data: { field1: 'value1', field2: 'value2' } },
      { id: '2', data: { field1: 'value3', field2: 'value4' } },
    ],
    test: [
      { id: '1', data: { field1: 'value1', field2: 'value2' } },
      { id: '2', data: { field1: 'value3', field2: 'value4' } },
    ],
  };

  private testData: any = [];

  constructor() { }

  public collection(collectionName: string) {
    return {
      valueChanges: () => of(this.mockData[collectionName] || []),
      doc: (id: string) => ({
        valueChanges: () =>
          of(
            this.mockData[collectionName]?.find((doc: any) => doc.id === id)
              ?.data || {}
          ),
        set: (data: any) => {
          const index = this.mockData[collectionName]?.findIndex(
            (doc: any) => doc.id === id
          );
          if (index !== -1) {
            this.mockData[collectionName][index].data = data;
          } else {
            this.mockData[collectionName] = this.mockData[collectionName] || [];
            this.mockData[collectionName].push({ id, data });
          }
          return Promise.resolve();
        },
        delete: () => {
          this.mockData[collectionName] = this.mockData[collectionName]?.filter(
            (doc: any) => doc.id !== id
          );
          return Promise.resolve();
        },
      }),
    };
  }

  // Utility functions to generate random data
  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRandomString(length: number): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  //results a timestamp in future
  getRandomTimestamp(): firebase.firestore.Timestamp {
    const now = Date.now();
    const randomTime = this.getRandomInt(now + 1000, now + 1000000000);
    return firebase.firestore.Timestamp.fromMillis(randomTime);
  }

  public getRandomTimestampFromGivenDates(
    start: Date,
    end: Date
  ): firebase.firestore.Timestamp {
    const startTimestamp = start.getTime();
    const endTimestamp = end.getTime();
    const randomTimestamp = new Date(
      startTimestamp + Math.random() * (endTimestamp - startTimestamp)
    );
    return firebase.firestore.Timestamp.fromDate(randomTimestamp);
  }

  public generateRandomParticipant(): IParticipant {
    return {
      state: this.getRandomInt(0, 3) as ParticipantState,
      firstName: this.getRandomString(5),
      lastName: this.getRandomString(7),
      userId: uuidv4(),
      start: this.getRandomTimestamp(),
      end: this.getRandomTimestamp(),
      present: Math.random() < 0.5,
    };
  }

  public generateRandomWorkPackage(): IWorkPackage {
    return {
      type: this.getRandomString(10),
      minParticipants: this.getRandomInt(1, 10),
      responsiblePerson: this.getRandomString(8),
      responsiblePersonUid: uuidv4(),
      start: this.getRandomTimestamp(),
      end: this.getRandomTimestamp(),
      kind: WorkPackageKind.Standard,
      isUe18: Math.random() < 0.5,
    };
  }

  public generateRandomEvent(): IEvent {
    const workPackages = Array.from({ length: this.getRandomInt(1, 5) }, () =>
      this.generateRandomWorkPackage()
    );
    workPackages.forEach((wp) => {
      wp.participants = Array.from({ length: this.getRandomInt(1, 10) }, () =>
        this.generateRandomParticipant()
      );
    });

    return {
      idAccountingYear: this.getRandomString(4),
      name: this.getRandomString(10),
      locationName: this.getRandomString(10),
      description: this.getRandomString(50),
      houseNumber: this.getRandomInt(1, 1000).toString(),
      street: this.getRandomString(15),
      plz: this.getRandomInt(10000, 99999),
      city: this.getRandomString(10),
      responsiblePerson: this.getRandomString(10),
      responsiblePersonId: uuidv4(),
      start: this.getRandomTimestamp(),
      end: this.getRandomTimestamp(),
      initialPath: `/events/${this.getRandomString(10)}`,
      workPackages,
    };
  }

  getRandomEmail(): string {
    const domains = ['example.com', 'test.com', 'sample.org'];
    return `${this.getRandomString(5)}@${domains[this.getRandomInt(0, domains.length - 1)]
      }`;
  }

  getRandomPhoneNumber(): string {
    return `+1-${this.getRandomInt(100, 999)}-${this.getRandomInt(
      100,
      999
    )}-${this.getRandomInt(1000, 9999)}`;
  }

  getRandomDate(pastYears: number): firebase.firestore.Timestamp {
    const date = new Date();
    date.setFullYear(date.getFullYear() - this.getRandomInt(20, pastYears));
    return firebase.firestore.Timestamp.fromDate(date);
  }

  public generateRandomUser(skipUserGroupsCreation: boolean = false): User {
    const firstName = this.getRandomString(6);
    const lastName = this.getRandomString(8);

    const contact: UserContact = {
      emailCompany: this.getRandomEmail(),
      emailPrivate: this.getRandomEmail(),
      telCompany: this.getRandomPhoneNumber(),
      telMobile: this.getRandomPhoneNumber(),
      telPrivate: this.getRandomPhoneNumber(),
    };

    const membership: UserMembership = {
      entryDate: this.getRandomDate(5),
      leavingDate: Math.random() > 0.5 ? this.getRandomDate(1) : undefined,
      number: this.getRandomInt(1000, 9999),
      participatesEncouragementModel: Math.random() > 0.5,
      participatesWorkHoursModel: Math.random() > 0.5,
      state: this.getRandomString(6),
      type: this.getRandomString(4),
    };

    const personalData: UserPersonalData = {
      birthDate: this.getRandomDate(50),
      firstName: firstName,
      lastName: lastName,
      profilePic: `https://example.com/avatar/${this.getRandomString(10)}`,
      street: this.getRandomString(10),
      houseNumber: this.getRandomInt(1, 1000).toString(),
      plz: this.getRandomInt(10000, 99999),
      city: this.getRandomString(6),
    };

    var userGroups: number[] = [];
    if (!skipUserGroupsCreation) {
      userGroups = Array.from({ length: this.getRandomInt(1, 5) }, () =>
        this.getRandomInt(1, 100)
      )
    }

    return {
      contact,
      displayName: `${firstName} ${lastName}`,
      email: contact.emailPrivate,
      membership,
      notificationIds: Array.from({ length: this.getRandomInt(0, 5) }, () =>
        this.getRandomString(10)
      ),
      personalData,
      sumEncouragementPoints: this.getRandomInt(0, 1000),
      sumWorkHours: this.getRandomInt(0, 10000),
      userGroups: userGroups,
      uid: this.getRandomString(20),
    };
  }

  //generates a standard WpView component. Event and WP are in the future. Random participants added (5). WP is released. Event not processed. Min participants: 10.
  public generateBaseWpComponent(
    component: WpViewComponent,
    viewMode: EventViewMode = undefined,
    releasedForUsers: boolean = true
  ): WpViewComponent {
    const wpData = this.generateRandomWorkPackage();
    const userData = this.generateRandomUser();
    const event = this.generateRandomEvent();
    wpData.participants = [];
    wpData.minParticipants = 10;

    wpData.releasedForUsers = releasedForUsers;

    for (let i = 0; i < 5; i++) {
      wpData.participants.push(this.generateRandomParticipant());
    }
    event.workPackages = [wpData];

    component.data = wpData;
    component.userData = userData;
    component.event = event;
    if (viewMode != undefined) {
      component.viewMode = viewMode;
    }
    return component;
  }

  //Adds this user as participant to the shift
  public addUserAsParticipant(component: WpViewComponent): WpViewComponent {
    const participantThisUser = this.generateRandomParticipant();
    participantThisUser.userId = component.userData.uid;
    component.data.participants.push(participantThisUser);
    return component;
  }

  //makes the user the shift leader
  public makeUserShiftLeader(component: WpViewComponent): WpViewComponent {
    component.data.responsiblePersonUid = component.userData.uid;
    return component;
  }

  public makeUserShiftLeaderVB(component: WpViewButtonComponent): WpViewButtonComponent {
    component.wp.responsiblePersonUid = component.getUserData().uid;
    return component;
  }

  //turns the wp to the past (event as well).
  public turnToEnded(component: WpViewComponent): WpViewComponent {
    const pastStartDate = firebase.firestore.Timestamp.fromDate(
      new Date(Date.now() - 1000 * 60 * 60 * 24)
    ); // 1 day in the past
    const pastEndDate = firebase.firestore.Timestamp.fromDate(
      new Date(Date.now() - 1000 * 60 * 60 * 23)
    ); // 23 hours in the past
    component.event.start = pastStartDate;
    component.event.end = pastEndDate;
    component.data.start = pastStartDate;
    component.data.end = pastEndDate;
    return component;
  }

  public setProcessedInBackground(component: WpViewComponent): WpViewComponent {
    component.event.processedInBackend = true;
    return component;
  }

  public makeConstructionShift(component: WpViewComponent): WpViewComponent {
    component.data.kind = WorkPackageKind.Construction;
    return component;
  }

  public makeDismantlingShift(component: WpViewComponent): WpViewComponent {
    component.data.kind = WorkPackageKind.Construction;
    return component;
  }

  public makeUserUnder18(component: WpViewComponent): WpViewComponent {
    const date = new Date();
    const pastYears = this.getRandomInt(0, 17); // Generates a random number between 0 and 17
    date.setFullYear(date.getFullYear() - pastYears);
    component.userData.personalData.birthDate =
      firebase.firestore.Timestamp.fromDate(date);
    return component;
  }

  public setUserUnder18(user: User): User {
    const date = new Date();
    const pastYears = this.getRandomInt(0, 17); // Generates a random number between 0 and 17
    date.setFullYear(date.getFullYear() - pastYears);
    user.personalData.birthDate =
      firebase.firestore.Timestamp.fromDate(date);
    return user;
  }

  public makeShiftFull(component: WpViewComponent): WpViewComponent {
    component.data.minParticipants = 5;
    for (let i = 0; i < 5; i++) {
      component.data.participants.push(this.generateRandomParticipant());
    }
    return component;
  }

  public makeShiftFullVB(component: WpViewButtonComponent): WpViewButtonComponent {
    component.wp.minParticipants = 5;
    for (let i = 0; i < 5; i++) {
      component.wp.participants.push(this.generateRandomParticipant());
    }
    return component;
  }

  public setShiftRunning(shift: IWorkPackage): IWorkPackage {
    shift.start = firebase.firestore.Timestamp.fromDate(new Date());
    return shift;
  }

  public generateBaseWpViewButtonComponent(
    component: WpViewButtonComponent,
    viewMode: EventViewMode
  ): WpViewButtonComponent {
    const wpData = this.generateRandomWorkPackage();
    const event = this.generateRandomEvent();
    wpData.participants = [];
    wpData.minParticipants = 10;
    wpData.releasedForUsers = true;

    for (let i = 0; i < 5; i++) {
      wpData.participants.push(this.generateRandomParticipant());
    }
    event.workPackages = [wpData];

    component.event = event;
    component.wp = wpData;
    component.viewMode = viewMode;
    return component;
  }

  public generateBaseEventViewComponent(
    component: EventViewComponent,
    viewMode: EventViewMode
  ): EventViewComponent {
    const wpData = this.generateRandomWorkPackage();
    const event = this.generateRandomEvent();
    wpData.participants = [];
    wpData.minParticipants = 10;
    wpData.releasedForUsers = true;

    for (let i = 0; i < 5; i++) {
      wpData.participants.push(this.generateRandomParticipant());
    }
    event.workPackages = [wpData];

    component.data = event;
    component.viewMode = viewMode;
    return component;
  }


  public generateRandomGroup(id: number = undefined, parent_id: number = undefined, name: string = undefined): Group {
    var _id = id;
    if (_id == undefined)
      _id = this.getRandomInt(5, 1000);
    var _parent_id = parent_id;
    if (_parent_id == undefined)
      _parent_id = this.getRandomInt(0, 1000);
    var _name = name;
    if (_name == undefined)
      _name = this.getRandomString(8);

    var ret: Group = { id: _id, name: _name, parent_id: _parent_id }
    return ret;
  }

  public generateRandomRights(count: number): Right[] {
    var ret: Right[] = [];
    for (let index = 0; index < count; index++) {
      ret.push({ id: index, name: this.getRandomString(6), state: RightState.ALLOW });
    }
    return ret;
  }

  public generateRandomRight(id: number = undefined, name: string = undefined): Right {
    var _id = id;
    if (_id == undefined)
      _id = this.getRandomInt(5, 1000);
    var _name = name;
    if (_name == undefined)
      _name = this.getRandomString(8);

    var ret: Right = { id: _id, name: _name, state: RightState.ALLOW };
    return ret;
  }
}
