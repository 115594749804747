import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteURL } from './definitions.service';
import { LogPublisherService } from './log-publisher.service';
import { LogPublisher } from './LogPublisher';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  level: LogLevel = LogLevel.All;
  errorCnt: number = 0;
  logWithDate: boolean = true;
  publishers: LogPublisher[];
  byteSize = (str) => new Blob([str]).size;

  constructor(
    private publishersService: LogPublisherService,
    private http: HttpClient
  ) {
    // Set publishers
    this.publishers = this.publishersService.publishers;
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.errorCnt++;
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.errorCnt++;
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      for (let logger of this.publishers) {
        // logger.log(entry).subscribe((response) => console.log(response));
        logger.log(entry);
      }
    }
    //check error count
    if (this.errorCnt > 10) {
      //send mail if too many errors
      this.sendLogsToDev("AutomaticProcedure");
      this.errorCnt = 0;
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      ret = true;
    }
    return ret;
  }

  //Sends a mail with the log entries of this user
  public sendLogsToDev(user: string, timespanDays: number = 2): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var logMessages = localStorage.getItem('logging');

      if (!logMessages) {
        resolve(true);
        return;
      }

      const obj = JSON.parse(logMessages);
      var resArr = [];

      var comparisonDate = new Date(Date.now());
      comparisonDate.setDate(comparisonDate.getDate() - timespanDays);

      //Not older than current date - timespanDays
      obj.forEach((element) => {
        var entryDate = element['entryDate'];
        var asDate = new Date(entryDate);
        if (asDate > comparisonDate) {
          resArr.push(element);
        }
      });

      let formData = new FormData();
      formData.append('messages', JSON.stringify(resArr));
      formData.append('user', user);
      var file_data = formData;
      var msg = 'Logfile konnte nicht versendet werden';

      this.http
        .post(SiteURL + '/backend/send-log-mail.php', file_data)
        .subscribe(
          (res) => {
            resolve(true);
          },
          (err) => {
            //send error response
            this.error(msg, err, LogService.name);
            reject(msg);
          }
        );
    });
  }

  public clearLogs() {
    localStorage.removeItem('logging');
    this.info('Deleted all log files data');
  }

  //Check if log is to big. If yes, clear
  public deleteOldLogs() {
    var logs = localStorage.getItem('logging');
    const bytes = this.byteSize(logs);
    var mb = bytes / 1024 / 1024;
    this.info('Current log file size: ' + mb + ' mb');
    if (mb > 1) {
      this.clearLogs();
    }
  }
}

export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    let ret: string = '';

    if (this.logWithDate) {
      ret = new Date() + ' - ';
    }

    ret += 'Type: ' + LogLevel[this.level];
    ret += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p == 'object')) {
      ret = '';

      // Build comma-delimited string
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
