<form *ngIf="userDataLoaded">
  <table class="name-selection-box">
    <tbody>
      <tr>
        <mat-form-field appearance="fill">
          <mat-label>Namen eingeben...</mat-label>
          <input type="text" placeholder="" aria-label="Namen eingeben..." matInput [formControl]="myControl"
            [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="optionSelected($event.option.value)">
            <mat-option *ngFor="let option of filteredNameOptions | async" [value]="option">
              {{ option.personalData?.firstName }}
              {{ option.personalData?.lastName }} ({{ option.email }})
            </mat-option>
          </mat-autocomplete>
          <button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="myControl.setValue(''); this.showTable = false">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </tr>
    </tbody>
  </table>
</form>

<div *ngIf="showTable" style="margin: 20px; padding-bottom: 100px">
  <hr />

  <mat-form-field appearance="standard" style="font-size: 14px; width: 100%; padding-left: 20px">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="timestamp"
      matSortDirection="desc">
      <!--Select-->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <!-- <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!--Amount-->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Anzahl</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 15px">
          {{ row.amount }}
        </td>
      </ng-container>

      <!--Action-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
        <td mat-cell *matCellDef="let row">
          {{ row.action | workHourEntryAction }}
        </td>
      </ng-container>

      <!--Reason-->
      <!-- <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grund</th>
        <td mat-cell *matCellDef="let row">
          {{ row.reason | workHourEntryReason }}
        </td>
      </ng-container> -->

      <!--Comment-->
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kommentar</th>
        <td mat-cell *matCellDef="let row">{{ row.actionComment }}</td>
      </ng-container>

      <!--Done By-->
      <ng-container matColumnDef="doneby">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eingetragen von
        </th>
        <td mat-cell *matCellDef="let row">{{ row.actionDoneByName }}</td>
      </ng-container>

      <!--timestamp-->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeitpunkt</th>
        <td mat-cell *matCellDef="let row">
          {{ row.timestamp.seconds * 1000 | date: "dd.MM.yyyy, H:mm" }} Uhr
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="clickedRows.add(row); selection.toggle(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)" *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keine Einträge vorhanden</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
  </div>

  <!-- <div style="margin-left: 20%; margin-top: 30px; margin-right: 20%">
    <mat-card>
      <table>
        <tr style="text-align: center">
          <td>Gesamtzahl</td>
          <td style="text-align: left">
            <b>{{ sumWorkHours }}</b>
          </td>
        </tr>
      </table>
    </mat-card>
  </div> -->

  <button mat-fab class="history-btn" color="primary" aria-label="Hinzufügen/Entnehmen"
    (click)="buttonActionAddWorkHour()">
    <mat-icon class="history-icon">edit</mat-icon>
  </button>

  <button mat-fab class="delete-btn" color="warn" aria-label="Loeschen"
    *ngIf="selection.hasValue() && isVisible(visibility.Btn_delete_Work_Hours)" (click)="buttonActionDeleteEntry() ">
    <mat-icon class="history-icon">remove</mat-icon>
  </button>

  <!-- <div>
    <h3>Click Log</h3>
  </div>

  <div *ngIf="!clickedRows.size">Clicked rows will be logged here</div>

  <ul>
    <li *ngFor="let clickedRow of clickedRows">
      Clicked on {{ clickedRow.name }}
    </li>
  </ul> -->
</div>