import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { MockDataModelService } from './mock-data-model.service';
import { LogService } from '../services/log.service';
import {
  User,
  Event,
  IEvent,
  Sub_WorkPackage,
  IWorkPackage,
  WorkPackageKind,
  EventViewMode,
} from '../services/definitions.service';

export enum EventState {
  Running = 'Aktuell laufend',
  NotBegan = 'Hat noch nicht begonnen',
  Past = 'Beendet',
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service that provides common utility functions for the application.
 */
/**
 * Service class that provides common utility functions.
 */
export class MockCommonFunctionsService {
  constructor(
    private dataModel: MockDataModelService,
    private log: LogService
  ) {}

  /**
   * Loads all events that are released for users and have not expired.
   *
   * @param {IEvent[]} allEvents - The list of all events.
   * @param {boolean} filterEnded - Filter out events that are outdated.
   * @returns {IEvent[]} - Returns a list of events that are released for users and have not expired.
   */
  public loadAllEvents(
    allEvents: IEvent[],
    filterEnded: boolean = true
  ): IEvent[] {
    var today = new Date();

    //filtere nach events die freigegeben und nicht abgelaufen sind
    var data: IEvent[] = allEvents;
    if (filterEnded) {
      var ret = data.filter((x) => {
        var end = new Date(x.end.seconds * 1000);
        if (x.releasedForUsers && today.getTime() < end.getTime()) {
          return true;
        }
        return false;
      });
      return ret;
    }
    return data;
  }

  /**
   * Checks if the current user is a shift organizer and shows the events administration.
   * @param user - The user object to check.
   * @returns A promise that resolves to a boolean indicating whether to show the events administration or not.
   */
  public async showEventPlanningForShiftOrganzier(
    user: User
  ): Promise<boolean> {
    // check if current user is a shift organizer. If yes, show events administration
    var ret = await this.dataModel.get(Event());
    if (ret.result) {
      if (ret.data && ret.data.length == 0) {
        this.log.debug('No events found.', MockCommonFunctionsService.name);
        return false;
      }
      var allEvents = this.loadAllEvents(ret.data, false);

      if (allEvents) {
        return (
          (await this.userIsShiftOrganizerHelper(allEvents, user)) || false
        );
      }
    }
    return false;
  }

  //Checks if user is a shift organizer
  private userIsShiftOrganizerHelper(
    events: IEvent[],
    userData: User
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (events && events.length > 0) {
        events.forEach((event) => {
          var path = `${Event()}/${event.id}/${Sub_WorkPackage()}`;

          this.dataModel.get(path).then(
            (wpLoaded) => {
              var wps = wpLoaded.data || [];
              wps.forEach((workPackage) => {
                if (this.isShiftOrganizer(workPackage, userData.uid)) {
                  resolve(true);
                }
              });
              resolve(false);
            },
            (error) => {
              this.log.error(
                `Error while loading work packages for event ${event.id}: ${error}`
              );
              resolve(false);
            }
          );
        });
      } else {
        resolve(false);
      }
    });
  }

  //checks if current user is the shift organizer
  public isShiftOrganizer(wp: IWorkPackage, userUId: string): boolean {
    if (wp && userUId) {
      return wp.responsiblePersonUid == userUId;
    }
    return false;
  }

  public isEventOrganizer(event: IEvent, userData: User): boolean {
    return event.responsiblePersonId == userData.uid;
  }

  public participatesInShift(wp: IWorkPackage, currentUserId: string): boolean {
    if (wp.participants && wp.participants.length > 0) {
      //check if this user is a participant. If so, show the event
      var found = wp.participants?.find((x) => x.userId == currentUserId);
      if (found != undefined) {
        //user found as participant -> show event
        return true;
      }
    }
    return false;
  }

  public isShiftFull(wp: IWorkPackage): boolean {
    return wp.participants?.length >= wp.minParticipants;
  }

  public shiftHasEnded(wp: IWorkPackage): boolean {
    return this.getRunningState(wp) == EventState.Past;
  }

  //Gets the state of this object: not started, running, past
  public getRunningState(object: any): string {
    if (object && object.start != undefined && object.end != undefined) {
      var now = new Date();
      var startAsDate = new Date(object.start.seconds * 1000);
      var endAsDate = new Date(object.end.seconds * 1000);

      if (now <= startAsDate) {
        return EventState.NotBegan;
      }
      if (now > endAsDate) {
        return EventState.Past;
      }
      return EventState.Running;
    }
    return '';
  }

  public getDayName(date: Date) {
    if (date) {
      var days = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ];
      return days[date.getDay()];
    }
    return '';
  }

  public isOver18(birthDate: firebase.firestore.Timestamp): boolean {
    // Get the current date
    const currentDate = new Date();

    // Convert Firestore timestamp to Date object
    const birthDateObj = birthDate.toDate();

    // Calculate the age
    const age = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const dayDiff = currentDate.getDate() - birthDateObj.getDate();

    // Adjust the age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      return age > 18;
    }

    return age >= 18;
  }

  //register on user data loaded
  public registerEventEmitterHandlerUserData(callback) {}

  // Calculate the work hours from a given start and end timestamp.
  // Will result in full hours. If above half an hour -> 1, if below -> 0.
  public calculateWorkHoursFromStartAndEnd(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): number {
    // Convert Timestamps to JavaScript Date objects
    const startDate = start.toDate();
    const endDate = end.toDate();

    // Calculate the difference in milliseconds
    const diffMs = endDate.getTime() - startDate.getTime();

    // Convert the difference from milliseconds to hours
    const diffHours = diffMs / (1000 * 60 * 60);

    // Round to the nearest hour based on whether the duration is above or below half an hour
    return Math.round(diffHours);
  }

  //Checks if the work package is of kind 'Aufbau (COnstruction)' or 'Abbau (Dismantling)'.
  public isConstructionOrDismantling(wp: IWorkPackage): boolean {
    if (
      wp?.kind == WorkPackageKind.Construction ||
      wp?.kind == WorkPackageKind.Dismantling
    )
      return true;
    return false;
  }

  // Decide if to show a wp
  public showWpView(
    wp: IWorkPackage,
    viewMode: EventViewMode,
    user: User
  ): boolean {
    var ret = true; //default: visible

    if (viewMode == EventViewMode.Admin) {
      // console.log('Admin view. Show WP.');
    } else if (viewMode == EventViewMode.MyShifts) {
      //View: Meine Schichten
      //Anzeigen, wenn: Schichtleiter oder teilnehmer und Schicht freigegeben ist

      //check if this user is shift leader
      var isShiftLeader = this.isShiftOrganizer(wp, user.uid);
      var participates = this.participatesInShift(wp, user.uid);
      var isShiftReleased = wp.releasedForUsers;

      if (isShiftReleased && (isShiftLeader || participates)) {
        // console.log(
        //   `(${EventViewMode[viewMode]}) User is shift leader or participates on a shift. Show WP.`
        // );
      } else {
        ret = false;
      }
    } else if (viewMode == EventViewMode.ShiftRegistration) {
      //View: Schichtanmeldung
      //Anzeigen, wenn: noch plaetze zur Anmeldung vorhanden (ausser bei Auf- und Abbau)
      //hide wenn beendet oder nicht freigegeben

      var isShiftReleased = wp.releasedForUsers;
      var ended = this.getRunningState(wp) == EventState.Past;
      var shiftFull = this.isShiftFull(wp);
      var isConstrOrDismantling = this.isConstructionOrDismantling(wp);

      //construction or dismantling
      if (isConstrOrDismantling) {
        // console.log(`(${EventViewMode[viewMode]}) Construction or dismantling. Show WP.`);
      } else {
        //shift full
        if (shiftFull) ret = false;
      }

      //shift not released
      if (!isShiftReleased) ret = false;

      //shift ended
      if (ended) ret = false;
    }
    return ret;
  }
}
