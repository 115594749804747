<div (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
  <mat-tab-group
    mat-stretch-tabs="true"
    mat-align-tabs="center"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab label="Meine Schichten">
      <div
        *ngFor="let data of allEvents; let i = index; count as cnt"
        class="event-view"
      >
        {{ showDebugInfo(data, 0) }}

        <event-view
          [data]="data"
          [users]="users"
          [userOptions]="userOptions"
          [accountingYear]="accountingYear"
          [viewMode]="enumViewMode.MyShifts"
          (componentLoaded)="eventViewLoaded(data)"
          (componentChanged)="loadEvents()"
        ></event-view>
      </div>

      <div class="no-events-text">
        <p>{{ centerTextMyShifts }}</p>
      </div>
    </mat-tab>

    <mat-tab label="Schichtanmeldung">
      <div
        *ngFor="let data of allEvents; index as i; count as cnt"
        class="event-view"
      >
        {{ showDebugInfo(data, 1) }}

        <event-view
          [data]="data"
          [users]="users"
          [userOptions]="userOptions"
          [accountingYear]="accountingYear"
          [viewMode]="enumViewMode.ShiftRegistration"
          (componentLoaded)="eventViewLoaded(data)"
          (componentChanged)="loadEvents()"
        ></event-view>
      </div>
      <div class="no-events-text">
        <p>{{ centerTextShiftRegister }}</p>
      </div>
    </mat-tab>

    <mat-tab label="Geleistete Schichten">
      <div *ngFor="let data of pastEvents">
        {{ showDebugInfo(data, 0) }}

        <report-wp-view
          *ngFor="let wp of data.workPackages"
          [data]="wp"
          [event]="data"
        ></report-wp-view>
      </div>

      <div class="no-events-text" *ngIf="pastEvents.length == 0">
        <p>Keine Schichten abgeschlossen</p>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
