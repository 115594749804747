<div *ngIf="isVisible(visibility.HintText)">
  <mat-divider></mat-divider>
  <div style="width: 100%; padding: 10px; padding-top: 20px">
    <p *ngIf="participatesShift() && shiftRunning()">
      Bitte melde dich beim Schichtverantwortlichen wenn du dich abmelden
      willst.
    </p>
    <p *ngIf="onlyForAdults()">Diese Schicht ist nur für Erwachsene</p>
    <p *ngIf="!participatesShift() && isShiftFull()">Die Schicht ist bereits voll</p>
    <p *ngIf="!participatesShift() && isShiftOrganizer()">Du bist Schichtverantwortlicher
    <p>
  </div>
</div>

<div>
  <mat-divider></mat-divider>
  <div style="width: 100%; padding: 10px">
    <!-- Participate -->
    <button *ngIf="isVisible(visibility.ParticipateButton)" mat-raised-button class="btns"
      style="background-color: blue" (click)="registerForWorkPackage()">
      Anmelden
    </button>

    <!-- Unparticipate -->
    <button *ngIf="isVisible(visibility.UnparticipateButton)" mat-raised-button class="btns"
      style="background-color: red" (click)="unregisterForWorkPackage()">
      Abmelden
    </button>

    <!-- Start Shift -->
    <button *ngIf="isVisible(visibility.StartShiftButton)" mat-raised-button class="btns" style="background-color: blue"
      (click)="startEndConstructionDismantling(wp.kind, true)">
      {{ wp.kind | wpKind }} starten
    </button>

    <!-- Stop Shift -->
    <button *ngIf="isVisible(visibility.StopShiftButton)" mat-raised-button class="btns" style="background-color: red"
      (click)="startEndConstructionDismantling(wp.kind, false)">
      {{ wp.kind | wpKind }} beenden
    </button>

    <!-- Call shift organizer -->
    <button *ngIf="isVisible(visibility.CallShiftOrganizerButton)" mat-raised-button class="btns"
      style="background-color: blue" (click)="callShiftOrganizer(getShiftOrganizerTelNumber())">
      <mat-icon style="margin-right: 8px">call</mat-icon>
      Anrufen
    </button>

    <!-- QR Code scan to set user as present -->
    <button *ngIf="isVisible(visibility.QrCodePresenceRegister)" mat-raised-button class="btns"
      style="background-color: darkmagenta" (click)="markUserAsPresent()">
      <mat-icon style="margin-right: 8px">qr_code</mat-icon>
      Anwesenheit melden
    </button>
  </div>
</div>