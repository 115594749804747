import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import {
  DataModelService,
  EncouragementPointEntryAction,
} from '../services/data-model.service';
import {
  Sub_EncouragementPoints,
  Properties,
  UserDataTableView,
  User,
} from '../services/definitions.service';
import { UniversalDialogDialogData } from '../universal-dialog/universal-dialog';
import { map, startWith } from 'rxjs/operators';
import { LogService } from '../services/log.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
})
export class StatisticsComponent implements OnInit {
  public userData: User; //muss public sein
  form = new FormGroup({});
  userDataLoaded: boolean = false;
  userEntryControl = new FormControl();
  options: User[] = [];
  filteredOptions: Observable<string[]>;
  filteredNameOptions: Observable<User[]>;
  allUsersData: User[];
  showTable: boolean;
  allUsersEncouragementPoints: any[] = [];
  givenPoints: any[] = [];
  gotPoints: any[] = [];

  //-------- Table data: Vergebene Punkte ------------
  @ViewChild(MatPaginator) paginatorTable1: MatPaginator;
  @ViewChild(MatSort) sortTable1: MatSort;
  dataSourceTable1: MatTableDataSource<UserDataTableView>;
  displayedColumnsTable1: string[] = [
    'amount',
    'reason',
    'comment',
    'givenTo',
    'timestamp',
    'createdBy'
  ];
  //---------------------------------------------------

  //-------- Table data: Erhaltene Punkte ------------
  @ViewChild(MatPaginator) paginatorTable2: MatPaginator;
  @ViewChild(MatSort) sortTable2: MatSort;
  dataSourceTable2: MatTableDataSource<UserDataTableView>;
  displayedColumnsTable2: string[] = [
    'amount',
    'reason',
    'comment',
    'gotFrom',
    'timestamp',
    'createdBy'
  ];
  //---------------------------------------------------

  constructor(
    private dataModel: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private spinnerService: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private router: Router,
    private log: LogService,
  ) {
    spinnerService.show();

    //check if in mobile mode
    if (this.deviceService.isMobile()) {
      commonFunctions
        .openDialog(
          'Achtung',
          'Diese Seite ist nicht für die Mobile Ansicht optimiert. Bitte öffne sie auf dem PC.',
          DialogType.INFO,
          'Abbrechen',
          'Fortfahren'
        )
        .afterClosed()
        .subscribe((result: UniversalDialogDialogData) => {
          if (result.result != 1) {
            this.router.navigateByUrl('/');
            return;
          }
        });
    }

    commonFunctions.registerEventEmitterHandlerUserData((userData: User) => {
      this.userData = userData;
    });
    this.dataSourceTable1 = new MatTableDataSource([]);
    this.dataSourceTable2 = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    //table 1
    this.dataSourceTable1.paginator = this.paginatorTable1;
    this.dataSourceTable1.sort = this.sortTable1;
    //table 2
    this.dataSourceTable2.paginator = this.paginatorTable2;
    this.dataSourceTable2.sort = this.sortTable2;
  }

  applyFilterTable1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable1.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceTable1.paginator) {
      this.dataSourceTable1.paginator.firstPage();
    }
  }

  applyFilterTable2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable2.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceTable2.paginator) {
      this.dataSourceTable2.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.commonFunctions.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.allUsersData = allUsersData;
          this.options = options;
          this.userDataLoaded = true;

          this.filteredNameOptions = this.userEntryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
        }
      }
    );
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  //Loads the points a single user
  private loadUserEncPoints(userData: User, callbackFn) {
    var errors = [];
    var retData = [];

    this.dataModel
      .getUserSubcollectionDoc(userData.uid, Sub_EncouragementPoints())
      .then(
        (value) => {
          //check if property is available
          value.forEach((x) => {
            if (Properties.ReceiverUserName in x) {
              if (x[Properties.ReceiverUserName] == "") {
                x[Properties.ReceiverUserName] = "<nicht vorhanden>";
              }
            } else
              x[Properties.ReceiverUserName] = "<nicht vorhanden>";
          });

          retData = retData.concat(value);
        },
        (rejectedReason) => {
          errors.push(userData.email);
        }
      )
      .catch((error) => {
        errors.push(userData.email);
      })
      .finally(() => {
        this.spinnerService.hide();
        callbackFn(retData);

        if (errors.length > 0) {
          var preText =
            'Förderpunkte konnten nicht geladen werden für user: ';
          this.commonFunctions.openDialog(
            'Laden fehlgeschlagen',
            preText + errors.join(', '),
            DialogType.ERROR
          );
        }
      });
  }

  public displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  //name selection done
  public optionSelected(val: any) {
    this.gotPoints = [];
    this.givenPoints = [];

    var data: User = this.userEntryControl.value;
    if (data) {
      this.loadUserEncPoints(data, (encDataList: []) => {
        encDataList.forEach(item => {
          //Vergebene Punkte:
          if (Properties.Action in item && item[Properties.Action] == EncouragementPointEntryAction.REMOVE)
            this.givenPoints.push(item);

          //Erhaltene Punkte:
          if (Properties.Action in item && item[Properties.Action] == EncouragementPointEntryAction.ADD)
            this.gotPoints.push(item);
        });

        //set data source - table 1
        this.dataSourceTable1 = new MatTableDataSource(this.givenPoints);
        this.dataSourceTable1.paginator = this.paginatorTable1;
        this.dataSourceTable1.sort = this.sortTable1;
        //set data source - table 2
        this.dataSourceTable2 = new MatTableDataSource(this.gotPoints);
        this.dataSourceTable2.paginator = this.paginatorTable2;
        this.dataSourceTable2.sort = this.sortTable2;

        this.showTable = true;

      })
    }
  }

  /**
   * Calculates the sum of points and takes care of the kind (add/substract).
   * 
   * @param list 
   * @returns 
   */
  public calcSum(list: []) {
    var sum = 0;
    list.forEach((element) => {
      if (Properties.Amount in element) {
        if (Properties.Action in element) {
          var action = +element[Properties.Action];
          //add
          if (action == EncouragementPointEntryAction.ADD.valueOf())
            sum += +element[Properties.Amount];
          //remove
          if (action == EncouragementPointEntryAction.REMOVE.valueOf())
            sum -= +element[Properties.Amount];
        } else {
          this.log.error("Unable to calculate points. No action property in data element.")
        }
      }
    });
    return Math.abs(sum)
  }
}
