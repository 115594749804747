import { Component, OnInit, ViewChild } from '@angular/core';
import { DataModelService } from '../services/data-model.service';
import { MatAccordion } from '@angular/material/expansion';
import {
  AddEventDialogData,
  Event,
  EventViewMode,
  IComponentChanged,
  IEvent,
  IWorkPackage,
  Properties,
  User,
} from './../services/definitions.service';
import { CommonFunctionsService } from '../services/common-functions.service';
import { LogService } from '../services/log.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEventComponent } from './dialogs/add-event/add-event.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../services/auth.service';
import 'firebase/firestore';
import { FormControl } from '@angular/forms';
import { groupBy } from 'lodash';
import { EventState } from '../testing/mock-common-functions.service';

@Component({
  selector: 'app-events-admin',
  templateUrl: './events-admin.component.html',
  styleUrls: ['./events-admin.component.css'],
})
export class EventsAdminComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public currentEvents: IEvent[] = [];
  public futureEvents: IEvent[] = [];
  public pastEvents: IEvent[] = [];
  public allEvents: IEvent[] = [];
  public users: User[] = [];
  public accountingYear: any = undefined;
  public userOptions: User[];
  public viewMode: EventViewMode = EventViewMode.Admin;
  public enumViewMode = EventViewMode;
  swipeCoord: [number, number];
  selectedTab: number;
  swipeTime: number;
  selectedEvent: IEvent;
  eventControl = new FormControl();
  public hideNames: boolean = false;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private authService: AuthService
  ) {
    if (this.deviceService.isMobile()) {
      this.viewMode = EventViewMode.SimplifiedAdmin;
    }
  }

  ngOnInit(): void {
    this.loadEvents();
    this.loadAccountingYear();
    this.loadUsers();

    //slection dropdown for shift overview
    this.eventControl.valueChanges.subscribe((value) => {
      this.selectedEvent = this.allEvents.find((event) => event.id === value);
    });
  }

  //Load all events
  private async loadEvents() {
    this.currentEvents = [];
    this.futureEvents = [];
    this.pastEvents = [];
    var ret = await this.dataModel.get(Event());
    if (ret.result) {
      //differentiate
      var today = new Date();
      this.allEvents = ret.data;

      this.allEvents.forEach((element: IEvent) => {
        var start = new Date(element.start.seconds * 1000);
        var end = new Date(element.end.seconds * 1000);

        //past
        if (today > end) {
          this.pastEvents.push(element);
        }
        //future
        else if (today < start) {
          this.futureEvents.push(element);
        }
        //current
        else {
          this.currentEvents.push(element);
        }
      });
      //Info: load of sub data, like work packages and participants will be done automatically, because the components are rendered somewhere on the page

      //sort
      this.pastEvents.sort((a, b) =>
        a[Properties.End] < b[Properties.End] ? 1 : -1
      );
      this.futureEvents.sort((a, b) =>
        a[Properties.End] < b[Properties.End] ? 1 : -1
      );
      this.currentEvents.sort((a, b) =>
        a[Properties.End] < b[Properties.End] ? 1 : -1
      );
    }
  }

  //Loads all user
  private async loadUsers() {
    this.common.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.users = allUsersData;
          this.userOptions = options;
        }
      }
    );
  }

  //loads the current accounting year
  private loadAccountingYear() {
    this.dataModel.loadCurrentAccountingYear().then(
      (fulfilled) => {
        this.accountingYear = fulfilled;
      },
      (rejectMsg) => {
        this.common.showErrorToast(
          'Konnte Daten des Abrechnungsjahr nicht laden'
        );
        this.log.error(rejectMsg, EventsAdminComponent.name);
      }
    );
  }

  //Gets the state of this event: not started, running, past
  public getRunningState(event: IEvent): string {
    return this.common.getRunningState(event);
  }

  public shiftIsPassed(event: IEvent): boolean {
    return this.common.getRunningState(event) == EventState.Past;
  }

  //Opens a dialog to add an event
  public async addEvent() {
    if (this.accountingYear == undefined) {
      this.common.showErrorToast('Daten zum Abrechnungsjahr nicht geladen');
      return;
    }
    var data: AddEventDialogData = {
      result: 0,
      allUserData: this.users,
      userOptions: this.userOptions,
      idAccountingYear: this.accountingYear.id,
    };
    this.dialog
      .open(AddEventComponent, {
        width: '550px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          this.loadEvents();
        }
      });
  }

  //Raised when something has change in the child component
  public eventChanged(change: IComponentChanged) { }

  //Called when sub compoenent is loaded
  public subComponentIsLoaded() {
  }

  //Handle swipe between tabs
  public swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;
      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        if (this.selectedTab == undefined) this.selectedTab = 0;

        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        console.info(swipe);
        if (swipe === 'next') {
          const isFirst = this.selectedTab === 0;
          if (this.selectedTab <= 3) {
            this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
          }
          console.log('Swipe left — INDEX: ' + this.selectedTab);
        } else if (swipe === 'previous') {
          const isLast = this.selectedTab === 4;
          if (this.selectedTab >= 1) {
            this.selectedTab = this.selectedTab - 1;
          }
          console.log('Swipe right — INDEX: ' + this.selectedTab);
        }
      }
    }
  }

  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public onlyEventsAdmin(): boolean {
    return this.authService.isEventsAdmin;
  }

  public getShiftsByDay(workPackages: IWorkPackage[]) {
    const groupedByDay = groupBy(workPackages, wp => wp.start.toDate().toLocaleDateString());
    return Object.keys(groupedByDay).map(day => {
      const date = new Date(day);
      const dayName = date.toLocaleDateString('de-DE', { weekday: 'long' });
      return {
        day: dayName,
        shifts: groupedByDay[day]
      };
    });
  }

  public async onChangeShowNames(event) {
    if (event) {
      this.hideNames = event.checked;
    }
  }
}
