import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { Logs, MethodReturn, Properties } from './definitions.service';
import { LogService } from './log.service';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    private firestore: AngularFirestore,
    private log: LogService,
    private spinner: NgxSpinnerService
  ) { }


  get getFirestore(): firebase.firestore.Firestore {
    return this.firestore.firestore;
  }

  get getAngularFirestore(): AngularFirestore {
    return this.firestore;
  }

  /**
   * Adds a new document.
   *
   * @param data the data
   * @param collectionPath the collection path
   * @returns A method result data structure.
   */
  public async add(data: any, collectionPath: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    await this.firestore.firestore
      .collection(collectionPath)
      .add(data)
      .then(
        (fulfilled) => {
          ret.result = true;
          ret.data = fulfilled.id;
          ret.documentPath = fulfilled.path;
          ret.parentPath = fulfilled.parent.path;
          ret.id = fulfilled.id;
        },
        (rejected) => {
          this.log.error(rejected);
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        this.log.error(error);
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });
    return ret;
  }

  /**
   * Deletes a document.
   *
   * @param collectionPath the collection path.
   * @returns A method result data structure.
   */
  public async delete(collectionPath: string, log: boolean): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    await this.firestore.firestore
      .doc(collectionPath)
      .delete()
      .then(
        () => {
          ret.result = true;
        },
        (rejected) => {
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });

    if (ret.result && log) {
      var logData = {
        collectionPath: collectionPath,
      };

      //create log entry
      this.createLogEntry(logData, "Unknown", "delete");
    }
    return ret;
  }

  /**
   * Gets the data defined by the collection path.
   *
   * @param collectionPath the collection path.
   * @returns A method result data structure.
   */
  public async get(
    collectionPath: string,
    queryFor: boolean = false,
    property: string = '',
    equalityValue: string = '',
    delaySpinner: boolean = false,
    hideSpinner: boolean = false,
    useDocumentUidAsId: boolean = true,
    clearSpinnerAtEnd: boolean = true
  ): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    if (!hideSpinner) {
      this.spinner.show();
    }
    const dataReturn = [];
    var col = this.firestore.collection(collectionPath);
    var x = undefined;

    if (queryFor) {
      x = col.ref.where(property, '==', equalityValue);
    } else {
      x = col.ref;
    }

    await x
      .get()
      .then(
        (querySnapshot) => {
          ret.result = true;
          querySnapshot.forEach((doc) => {
            var entry = doc.data();
            entry[Properties.Path] = doc.ref.path;
            if (useDocumentUidAsId) {
              entry[Properties.ID] = doc.id;
            } else {
              entry[Properties.UID] = doc.id;
            }
            entry[Properties.ParentPath] = doc.ref.parent.path;
            dataReturn.push(entry);
          });
          ret.data = dataReturn;
        },
        (rejected) => {
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        ret.errorMessage = error;
      })
      .finally(() => {
        if (!hideSpinner) {
          if (!delaySpinner) {
            if (clearSpinnerAtEnd) {
              this.spinner.hide();
            }
          } else {
            setTimeout(() => {
              if (clearSpinnerAtEnd) {
                this.spinner.hide();
              }
            }, 1000);
          }
        }
      });
    return ret;
  }

  /**
   * Gets a specific document.
   *
   * @param collectionPath the collection path.
   * @param docId Document id.
   * @returns A method result data structure.
   */
  public async getDoc(
    collectionPath: string,
    docId: string,
    delaySpinner: boolean = false,
    hideSpinner: boolean = false,
    delayTime: number = 1000,
    clearSpinnerAtEnd: boolean = true
  ): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    if (!hideSpinner) {
      this.spinner.show();
    }
    var col = this.firestore.collection(collectionPath).doc(docId);

    await col.ref
      .get()
      .then(
        (doc) => {
          if (doc.exists) {
            ret.result = true;
            var entry = doc.data();
            entry[Properties.Path] = doc.ref.path;
            entry[Properties.ID] = doc.id;
            entry[Properties.ParentPath] = doc.ref.parent.path;
            ret.data = entry;
          }
        },
        (rejected) => {
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        ret.errorMessage = error;
      })
      .finally(() => {
        if (!hideSpinner) {
          if (!delaySpinner) {
            if (clearSpinnerAtEnd) {
              this.spinner.hide();
            }
          } else {
            setTimeout(() => {
              if (clearSpinnerAtEnd) {
                this.spinner.hide();
              }
            }, delayTime);
          }
        }
      });
    return ret;
  }

  public async getDocDirect(docPath: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    var col = this.firestore.doc(docPath);

    await col.ref
      .get()
      .then(
        (doc) => {
          if (doc.exists) {
            ret.result = true;
            var entry = doc.data();
            entry[Properties.Path] = doc.ref.path;
            entry[Properties.ID] = doc.id;
            entry[Properties.ParentPath] = doc.ref.parent.path;
            ret.data = entry;
          }
        },
        (rejected) => {
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });
    return ret;
  }

  /**
   * Updates a document.
   *
   * @param data the data
   * @param collectionPath the collection path
   * @returns A method result data structure.
   */
  public async update(
    data: any,
    collectionPath: string,
    docId: string
  ): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    await this.firestore.firestore
      .collection(collectionPath)
      .doc(docId)
      .update(data)
      .then(
        (fulfilled) => {
          ret.result = true;
        },
        (rejected) => {
          this.log.error(rejected);
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        this.log.error(error);
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });
    return ret;
  }

  /**
   * Updates a document by its id.
   *
   * @param data the data
   * @param docPath the document path
   * @returns A method result data structure.
   */
  public async updateDirect(data: any, docPath: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    await this.firestore.firestore
      .doc(docPath)
      .update(data)
      .then(
        (fulfilled) => {
          ret.result = true;
        },
        (rejected) => {
          this.log.error(rejected);
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        this.log.error(error);
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });
    return ret;
  }

  /**
   * Adds all given data to the given path.
   *
   * @param data data list
   * @param collectionPath path to the parent document
   * @returns a promise for a method return.
   */
  public async batchAdd(
    data: any[],
    collectionPath: string
  ): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    this.spinner.show();
    var db = this.firestore.firestore;

    // Get a new write batch
    var batch = db.batch();

    data.forEach((element) => {
      //create a new doc ref
      var newEntry = db.collection(collectionPath).doc();
      batch.set(newEntry, element);
    });

    await batch
      .commit()
      .then(
        () => {
          ret.result = true;
        },
        (rejected) => {
          this.log.error(rejected);
          ret.errorMessage = rejected;
        }
      )
      .catch((error) => {
        this.log.error(error);
        ret.errorMessage = error;
      })
      .finally(() => {
        this.spinner.hide();
      });
    return ret;
  }

  /**
 * Writes a new log entry.
 * 
 * @param input - the input data to save to the logs
 * @param userId - the id of the user who initiated the action to log.
 * @returns 
 */
  public async createLogEntry(
    input: any,
    userId: string,
    intention: string
  ): Promise<MethodReturn> {
    var data: any = {
      data: input,
      userId: userId,
      ts: firebase.firestore.Timestamp.fromDate(new Date()),
      intention: intention
    };
    var ret = await this.add(data, Logs());
    if (ret.result)
      this.log.info("Successfully logged action");
    return ret;
  }
}
