<form [formGroup]="myForm" style="padding-top: 5px">
  <mat-form-field class="form-fields">
    <input matInput [ngxTimepicker]="toggleTimepickerStart" [formControl]="startFormControl" [format]="24"
      placeholder="Von" />
    <mat-icon matSuffix>access_time</mat-icon>

    <!--https://github.com/Agranom/ngx-material-timepicker-->
    <ngx-material-timepicker #toggleTimepickerStart [hoursOnly]="true"
      (timeSet)="startTimeChanged($event)"></ngx-material-timepicker>
  </mat-form-field>

  <mat-form-field class="form-fields">
    <input matInput [ngxTimepicker]="toggleTimepickerEnd" [formControl]="endFormControl" [format]="24"
      placeholder="Bis" />

    <mat-icon matSuffix>access_time</mat-icon>

    <!--https://github.com/Agranom/ngx-material-timepicker-->
    <ngx-material-timepicker #toggleTimepickerEnd [hoursOnly]="true"
      (timeSet)="endTimeChanged($event)"></ngx-material-timepicker>
  </mat-form-field>
</form>