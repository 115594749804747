<div class="calendar-header">
  <button mat-button aria-label="Example icon button with a menu icon">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <p style="padding-top: 20px;">{{ currentDate | date: 'MMMM yyyy' }}</p>
  <button mat-button (click)="goToToday()" aria-label="Example icon button with a menu icon">
    <mat-icon>today</mat-icon>
  </button>
  <button mat-button (click)="nextMonth()" aria-label="Example icon button with a menu icon">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
<hr>
<div class="calendar-grid">
  <div class="day-header" *ngFor="let day of daysOfWeek">{{ day }}</div>
  <div class="day-cell" *ngFor="let day of calendarDays"
    [ngClass]="{'today': isToday(day.date), 'has-events': hasEvents(day.date), 'other-month': !day.currentMonth, 'selected': isSelectedDate(day.date)}"
    (click)="selectDate(day.date)" (contextmenu)="onRightClick($event, day.date)">
    <span>{{ day.date.getDate() }}</span>
    <div class="events">
      <ng-container *ngFor="let dot of getEventDots(day.date).dots; let i = index">
        <span class="event-dot" [style.background-color]="getDotColor(dot.type)"
          (click)="editEvent(dot, day.date, $event)"></span>
      </ng-container>
      <span class="more-dots" *ngIf="getEventDots(day.date).extra">...</span>
    </div>
  </div>
</div>

<mat-card class="events-container" *ngIf="selectedDate">
  <h3>Events on {{ selectedDate | date: 'fullDate' }}</h3>
  <div *ngFor="let event of getEvents(selectedDate)">
    <p>{{ event.title }} ({{ event.start | date: 'shortTime' }} - {{ event.end | date: 'shortTime' }})</p>
  </div>
</mat-card>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed" [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <button mat-menu-item (click)="openEventDialog(selectedDate)">
    Add Event
  </button>
  <button mat-menu-item *ngIf="selectedEvent" (click)="openEventDialog(selectedDate, selectedEvent)">Edit Event</button>
</mat-menu>


<!--TODO: rechtebasiert komm mit rein -> isVisible()-->
<button mat-fab style="z-index: 9998 !important" class="history-btn" color="primary" aria-label="Termin hinzufügen"
  (click)="openEventDialog(selectedDate)" *ngIf="selectedDate">
  <mat-icon class="history-icon">add</mat-icon>
</button>