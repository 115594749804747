import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  EventViewMode,
  IComponentChanged,
  IDateRange,
  IEvent,
  IParticipant,
  IWorkPackage,
  User,
} from 'src/app/services/definitions.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'participants-view',
  templateUrl: './participants-view.component.html',
  styleUrls: ['./participants-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ParticipantsViewComponent implements OnInit {
  @Input() data: IParticipant[];
  @Input() event: IEvent;
  @Input() workPackage: IWorkPackage;
  @Input() viewMode: EventViewMode;
  @Output() componentChanged = new EventEmitter<IComponentChanged>();
  changedTimesValid: boolean;
  public enumViewMode = EventViewMode;
  private userData: User;

  constructor(
    private commonFunctions: CommonFunctionsService,
    private dataModel: DataModelService,
    private authService: AuthService, private log: LogService
  ) {
    // Assign the data to the data source for the table to render
    this.commonFunctions.registerEventEmitterHandlerUserData(
      async (data: User) => {
        this.userData = data;
      }
    );
  }

  ngOnInit(): void { }

  //Converts a firestore timestamp to a JS Date object
  asDate(firestoreTimestamp: firebase.firestore.Timestamp): Date {
    if (firestoreTimestamp) {
      return new Date(firestoreTimestamp.seconds * 1000);
    }
    return undefined;
  }

  timestampToDate(timestamp: firebase.firestore.Timestamp): Date {
    if (timestamp) {
      return new Date(timestamp.seconds * 1000);
    }
    return undefined;
  }

  //Save data
  private async save(participant: IParticipant) {
    var set = await this.dataModel.updateParticipant(participant);
    if (set.result) {
      this.commonFunctions.showSuccessToast('Schichtzeit aktualisiert', 2000);
    } else {
      this.commonFunctions.showErrorToast(
        'Schichtzeit konnte nicht aktualisiert werden'
      );
    }
  }

  // Raised when child component (date range) signals, that the times have changed
  timeChanged(dateRange: IDateRange, participant: IParticipant) {
    if (participant) {
      if (dateRange.start)
        participant.start = firebase.firestore.Timestamp.fromDate(dateRange.start);
      if (dateRange.end)
        participant.end = firebase.firestore.Timestamp.fromDate(dateRange.end);

      this.save(participant);
    } else {
      this.commonFunctions.showErrorToast("Konnte Zeiten nicht speichern.");
    }
  }

  // Removes a participant from the list
  async removeParticipant(participant: IParticipant) {
    if (participant && participant?.path) {
      var set = await this.dataModel.deleteGeneric(participant.path, false);
      if (set.result) {
        //remove from internal list
        this.data = this.data.filter((x) => {
          return x.id != participant.id;
        });
        this.emitChange();
      } else {
        this.commonFunctions.showErrorToast(
          'Teilnehmer konnte nicht geloescht werden'
        );
      }
    }
  }

  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public onlyEventsAdmin(): boolean {
    return this.authService.isEventsAdmin;
  }

  //Signals the parent that something has changed in this component
  private emitChange() {
    var changed: IComponentChanged = {};
    this.componentChanged.emit(changed);
  }

  //Raised when a presence toggle is hit
  public async onChangePresence(
    event: MatCheckboxChange,
    participant: IParticipant
  ) {
    if (participant) {
      var ret = await this.dataModel.setPresenceForEventParticipant(
        participant.path,
        event.checked
      );

      if (!ret.result) {
        this.commonFunctions.showErrorToast(
          'Konnte Anwesenheitsstatus nicht setzen'
        );
      }
    }
  }

  public visibleForAdminOrShiftOrg(): boolean {
    return (
      this.authService.isEventsAdmin ||
      this.commonFunctions.isShiftOrganizer(this.workPackage, this.userData.uid)
    );
  }

  public isAdminViewMode(): boolean {
    return this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.SimplifiedAdmin;
  }

  public eventNotProcessed(): boolean {
    return this.event.processedInBackend == undefined || (this.event.processedInBackend != undefined && this.event.processedInBackend == false);
  }
}
