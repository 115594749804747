import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataModelService } from './data-model.service';
import { GroupEntity, RightEntity, Route, User } from './definitions.service';
import { CommonFunctionsService } from './common-functions.service';
import { LogService } from './log.service';
import { EventEmitterService } from '../event-emitter.service';
import { UserRightsManagementService } from './user-rights-management.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<firebase.default.User>;
  userDetails: firebase.default.User;
  subs: Subscription;
  loginBlocked: boolean = false;
  blockReason: string = '';
  private _userData: User = undefined;
  loginFailCount: number = 0;
  private _showEventPlanningForShiftOrganzier: boolean = undefined;

  constructor(
    public router: Router,
    private angularFireAuth: AngularFireAuth,
    private dataModelService: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private eventEmitter: EventEmitterService,
    private log: LogService,
    private userRightsManagement: UserRightsManagementService
  ) { }


  public get showEventPlanningForShiftOrganizer(): boolean {
    return this._showEventPlanningForShiftOrganzier;
  }

  public get isLoggedIn(): boolean {
    return this.userDetails != null;
  }

  public get userId(): string {
    return this.userDetails?.uid;
  }

  public get isEventsAdmin(): boolean {
    if (this._userData) {
      return this.userRightsManagement.isPartOfGroup(GroupEntity.EVENT_ADMIN.valueOf());
    }
    return false;
  }

  public get participatesEncModel(): boolean {
    if (this._userData) {
      return this.commonFunctions.extractData(
        this._userData,
        'membership.participatesEncouragementModel'
      );
    }
    return false;
  }

  /**
   * Log in user.
   * 
   */
  public logIn() {
    if (this.loginBlocked) {
      setTimeout(() => {
        this.commonFunctions.showErrorToast(this.blockReason, 10000);
      }, 1000);
      return false;
    }
    if (this.userDetails != null) {
      return true;
    }
    var promise = new Promise<boolean>((resolve, reject) => {
      this.subs = this.angularFireAuth.authState.subscribe((user) => {
        if (user) {
          this.userDetails = user;
          if (this._userData == undefined) {
            this.retrieveUserData();
          }
          resolve(true);
        } else {
          this.userDetails = null;
          var msg = 'Logged out. Redirecting to login component...';
          this.log.error(msg, AuthService.name);
          this.router.navigate(['/login']);
          this.subs.unsubscribe();
          reject(msg);
        }
      });
    });
    return promise;
  }

  //Check if user has the right to access
  async hasRightToAccess(expectedGroups: GroupEntity[], expectedRights: RightEntity[], route: ActivatedRouteSnapshot = undefined) {
    try {
      var loggedIn = await this.logIn();
    } catch (error) {
      this.log.error('Login failed', error, AuthService.name);
      return false;
    }

    if (loggedIn) {
      var uid = this.userDetails.uid;
      var userData = await this.retrieveUserData();

      //Special cases:
      //check if user is shift organizer. If so, allow access to event planning
      if (route?.routeConfig?.path == Route.EVENTS_ADMIN.toString() && this.isShiftOrganizerOrEventsAdmin) {
        return true;
      }

      if (userData) {
        var promise = new Promise<boolean>(async (resolve, reject) => {
          var hasAllRights = this.userRightsManagement.hasAllRights(expectedRights);
          var isPartOfAllGroups = this.userRightsManagement.isPartOfGroups(expectedGroups);

          // if assignment to groups are set, check for match of every group
          if (expectedGroups?.length > 0 && expectedRights?.length > 0) {
            //check both
            resolve(hasAllRights && isPartOfAllGroups);
          } else if (expectedGroups?.length > 0) {
            resolve(isPartOfAllGroups);
          } else if (expectedRights?.length > 0) {
            resolve(hasAllRights);
          } else {
            this.log.error('Unable to determine access rights.', AuthService.name);
          }

          reject(false);
        });
        return promise;
      } else {
        this.log.error('No user found for id: ' + uid, AuthService.name);
      }
    }
    return false;
  }

  async logout() {
    await this.angularFireAuth.signOut().then((res) => {
      this.log.info('Successfully logged out', AuthService.name);
      this.userDetails = null;
      this.eventEmitter.clearAllData();
      this.router.navigate(['/login']);
    });
  }

  public blockLogin(reason: string) {
    this.loginBlocked = true;
    this.blockReason = reason;
  }

  public unblockLogin() {
    this.loginBlocked = false;
    this.blockReason = '';
  }

  public isLoginBlocked() {
    return this.loginBlocked;
  }


  //Retrieves the user data if not available
  private async retrieveUserData(): Promise<User | undefined> {
    if (this.loginFailCount > 3) {
      this.commonFunctions.showErrorToast("Login fehlgeschlagen");
      return;
    }
    var uid = this.userDetails.uid;
    //use cache data to reduce server traffic (could be a little security lag but ok i think)
    if (this.eventEmitter.cachedUserData) {
      console.log('Use cached data');
      var userData = this.eventEmitter.cachedUserData; //get cached data
    } else {
      try {
        userData = await this.dataModelService.loadUser(uid);
      } catch (error) {
        this.loginFailCount++;
        this.log.error('Failed to load user data', error, AuthService.name);
        return undefined;
      }
    }
    this._userData = userData;
    await this.getShowEventPlanningForShiftOrganizer();
    return userData;
  }

  //Retrieves the data needed to decide if to show the event planning for shift organizer.
  //This method is outlined here, to prevent continoues loading of backend data when used in an isVisible() method.
  private async getShowEventPlanningForShiftOrganizer() {
    if (this._userData && this._showEventPlanningForShiftOrganzier == undefined) {
      this._showEventPlanningForShiftOrganzier = await this.commonFunctions.showEventPlanningForShiftOrganzier(this._userData);
    }
  }

  //Is user either a shift organizer or an events admin (user group member)
  public get isShiftOrganizerOrEventsAdmin(): boolean {
    if (this._showEventPlanningForShiftOrganzier) {
      return true;
    }
    //check if event admin, then show it although
    if (this.userRightsManagement.isPartOfGroup(GroupEntity.EVENT_ADMIN.valueOf())) {
      return true;
    }
    return false;
  }
}
