import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { EventEmitterService } from '../event-emitter.service';
import { GetPointsFromWorkHours } from './../shared/shared.js';
import {
  NotiBottomSheetComponent,
  NotiSheetData,
  NotiType,
} from '../noti-bottom-sheet/noti-bottom-sheet.component';
import {
  UniversalDialog,
  UniversalDialogDialogData,
} from '../universal-dialog/universal-dialog';
import {
  WaitingBottomSheetComponent,
  WaitingSheetData,
} from '../waiting-bottom-sheet/waiting-bottom-sheet.component';
import {
  DataModelService,
  EncouragementPointEntryAction,
} from './data-model.service';
import {
  EventState,
  EventViewMode,
  IEvent,
  Event,
  IWorkPackage,
  Properties,
  User,
  WorkPackageKind,
  Sub_WorkPackage,
  IParticipant,
  Sub_Participant,
} from './definitions.service';
import { LogService } from './log.service';

export enum DialogType {
  INFO,
  ERROR,
  SUCCESS,
  PERMANENT,
}

export enum DialogPosition {
  BOTTOM,
  TOP,
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service that provides common utility functions for the application.
 */
/**
 * Service class that provides common utility functions.
 */
export class CommonFunctionsService {
  constructor(
    public dialog: MatDialog,
    private dataModel: DataModelService,
    private eventEmitterService: EventEmitterService,
    private log: LogService,
    private bottomSheet: MatBottomSheet
  ) { }

  public openDialog(
    title: string,
    contentText: string,
    type: DialogType = DialogType.INFO,
    buttonNoName: string = '',
    buttonYesName: string = 'Ok',
    disableClose: boolean = false
  ): MatDialogRef<UniversalDialog, any> {
    const dialogRef = this.dialog.open(UniversalDialog, {
      width: '350px',
      data: {
        title: title,
        contentText: contentText,
        buttonNoName: buttonNoName,
        buttonYesName: buttonYesName,
        type: type,
        result: 0,
      },
      disableClose: disableClose,
    });
    return dialogRef;
  }

  async showErrorToast(
    message: string,
    timeout: number = 5000,
    sleepWhileShowing: boolean = false
  ) {
    await this.showToastMessage(
      DialogType.ERROR,
      message,
      timeout,
      false,
      sleepWhileShowing
    );
  }

  async showSuccessToast(
    message: string,
    timeout: number = 3000,
    sleepWhileShowing: boolean = false
  ) {
    await this.showToastMessage(
      DialogType.SUCCESS,
      message,
      timeout,
      false,
      sleepWhileShowing
    );
  }

  async showInfoToast(
    message: string,
    timeout: number = 3000,
    sleepWhileShowing: boolean = false
  ) {
    await this.showToastMessage(
      DialogType.INFO,
      message,
      timeout,
      false,
      sleepWhileShowing
    );
  }

  showPermanentToast(message: string) {
    this.showToastMessage(DialogType.PERMANENT, message, 50, true);
  }

  hidePermanentToast() {
    this.bottomSheet?.dismiss();
  }

  async showToastMessage(
    type: DialogType,
    message: string,
    timeout: number = 4000,
    permanent: boolean = false,
    sleepWhileShowing: boolean = false
  ) {
    let data: NotiSheetData;
    switch (type) {
      case DialogType.SUCCESS:
        data = new NotiSheetData(message, NotiType.Success, timeout, permanent);
        var cls = 'noti-bottom-sheet-success';
        break;
      case DialogType.INFO:
        data = new NotiSheetData(message, NotiType.Info, timeout, permanent);
        var cls = 'noti-bottom-sheet-info';
        break;
      case DialogType.PERMANENT:
        data = new NotiSheetData(message, NotiType.Info, timeout, permanent);
        var cls = 'noti-bottom-sheet-permanent';
        break;
      case DialogType.ERROR:
        data = new NotiSheetData(message, NotiType.Error, timeout, permanent);
        var cls = 'noti-bottom-sheet-error';
        break;
    }
    this.bottomSheet.open(NotiBottomSheetComponent, {
      data: data,
      panelClass: cls,
      disableClose: true,
    });
    if (sleepWhileShowing) {
      await this.sleep(timeout);
    }
  }

  showWaitingDialog(
    message: string,
    timeout: number,
    cancellationTriggerFn: any,
    callbackTimeout: any,
    successCallback: any
  ) {
    let data = new WaitingSheetData(
      message,
      timeout,
      cancellationTriggerFn,
      callbackTimeout,
      successCallback
    );
    var cls = 'noti-bottom-sheet-transfer';
    this.bottomSheet.open(WaitingBottomSheetComponent, {
      data: data,
      panelClass: cls,
      disableClose: true,
    });
  }

  public unixTimeToDate(timestamp) {
    if (timestamp) {
      var date = new Date(timestamp * 1000);
      return (
        date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        //  +
        // ' ' +
        // date.getHours() +
        // ':' +
        // date.getMinutes() +
        // ':' +
        // date.getSeconds()
      );
    }
    return '<undefined>';
  }

  public firebaseTimestampToTime(timestamp: firebase.firestore.Timestamp) {
    if (timestamp) {
      var date = new Date(timestamp.seconds * 1000);
      var hours = date.getHours().toString();
      var minutes = date.getMinutes().toString().padStart(2, '0');
      return hours + ':' + minutes;
    }
    return '<undefined>';
  }

  public calculateDuration(
    startTimestamp: firebase.firestore.Timestamp,
    endTimestamp: firebase.firestore.Timestamp
  ): string {
    if (startTimestamp && endTimestamp) {
      var startDate = new Date(startTimestamp.seconds * 1000);
      var endDate = new Date(endTimestamp.seconds * 1000);

      var diffInSeconds = (endDate.getTime() - startDate.getTime()) / 1000;
      var hours = Math.floor(diffInSeconds / 3600);
      var minutes = Math.floor((diffInSeconds % 3600) / 60);
      var seconds = Math.floor((diffInSeconds % 3600) % 60);

      return hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds';
    }
    return '<undefined>';
  }

  //Saves the token in the users collection
  public async saveNotiTokenInUserCollection(userId: string, token: string) {
    let msg = 'Notification token konnte nicht gespeichert werden';
    var ret = await this.dataModel.addNotificationToken(userId, token);
    if (ret.result) {
      this.log.debug(
        'Notification token successfully saved to db',
        CommonFunctionsService.name
      );
      this.showSuccessToast('Registrierung erfolgreich');
    } else {
      this.showErrorToast(msg + ret.errorMessage);
      this.log.error(msg, ret.errorMessage, CommonFunctionsService.name);
    }
  }

  //Checks if a property is present and not empty in the users data
  public isPropAvailable(
    userData: User,
    propName: string,
    collectionName: string = null
  ) {
    if (userData) {
      if (collectionName == null) {
        if (propName in userData && userData[propName] != '') return true;
      } else {
        if (
          collectionName in userData &&
          propName in userData[collectionName] &&
          userData[collectionName][propName] != ''
        )
          return true;
      }
    }
    return false;
  }

  //Extracts data from an object even if nested
  public extractData(dataHeap: any, dataPath: string) {
    if (dataHeap && dataPath) {
      var splitted = dataPath.split('.');
      var currentItem = dataHeap;
      var found = false;
      splitted.forEach((element) => {
        if (element in currentItem) {
          currentItem = currentItem[element];
          found = true;
        }
      });
      if (found) return currentItem;
    }
    return '';
  }

  //Loads all the data of all users from the cloud db
  public loadAllUsers(
    callback,
    delaySpinner: boolean = false,
    hideSpinner: boolean = false
  ) {
    var options = [];
    this.dataModel
      .loadAllUsers(delaySpinner, hideSpinner)
      .then(
        (value) => {
          if (value) {
            value.forEach((element) => {
              var extractedName = this.extractData(
                element,
                'personalData.firstName'
              );

              if (extractedName && extractedName != '') options.push(element);
            });
          } else {
            this.showErrorToast('Userdaten fehlerhaft');
          }
          callback(true, value, options);
        },
        (rejectedReason) => {
          this.showErrorToast(
            'Die Daten aller Nutzer konnten nicht geladen werden ' +
            rejectedReason
          );
          callback(false);
        }
      )
      .catch((error) => {
        this.showErrorToast(
          'Die Daten aller Nutzer konnten nicht geladen werden: ' + error
        );
        callback(false);
      });
  }

  //register on user data loaded
  public registerEventEmitterHandlerUserData(callback) {
    if (this.eventEmitterService.subsLoadedUserData == undefined) {
      if (!this.eventEmitterService.cachedUserData) {
        this.eventEmitterService.subsLoadedUserData =
          this.eventEmitterService.userDataLoaded.subscribe((user: User) => {
            callback(user);
          });
      } else callback(this.eventEmitterService.cachedUserData);
    } else {
      if (!this.eventEmitterService.cachedUserData) {
        this.eventEmitterService.subsLoadedUserData =
          this.eventEmitterService.userDataLoaded.subscribe((user: User) => {
            callback(user);
          });
      } else callback(this.eventEmitterService.cachedUserData);
    }
  }

  //Registriert die event emitter handler (EncouragementPoints)
  public registerEventEmitterHandlerEncouragementPoints(callback) {
    if (
      this.eventEmitterService.subsLoadedEncouragementPointsData == undefined
    ) {
      if (!this.eventEmitterService.cachedEncouragementPointsData) {
        this.eventEmitterService.subsLoadedEncouragementPointsData =
          this.eventEmitterService.encouragementPointsDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedEncouragementPointsData);
    } else {
      if (!this.eventEmitterService.cachedEncouragementPointsData) {
        this.eventEmitterService.subsLoadedEncouragementPointsData =
          this.eventEmitterService.encouragementPointsDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedEncouragementPointsData);
    }
  }

  //register on work hours loaded
  public registerEventEmitterHandlerUserWorkHours(callback) {
    if (this.eventEmitterService.subsLoadedWorkHoursData == undefined) {
      if (!this.eventEmitterService.cachedWorkHoursData) {
        this.eventEmitterService.subsLoadedWorkHoursData =
          this.eventEmitterService.workHoursDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedWorkHoursData);
    } else {
      if (!this.eventEmitterService.cachedWorkHoursData) {
        this.eventEmitterService.subsLoadedWorkHoursData =
          this.eventEmitterService.workHoursDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedWorkHoursData);
    }
  }

  //register on protocol work hours loaded
  public registerEventEmitterHandlerProtocolWorkHours(callback) {
    if (this.eventEmitterService.subsLoadedProtocolWorkHoursData == undefined) {
      if (!this.eventEmitterService.cachedProtocolWorkHoursData) {
        this.eventEmitterService.subsLoadedProtocolWorkHoursData =
          this.eventEmitterService.protocolWorkHoursDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedProtocolWorkHoursData);
    } else {
      if (!this.eventEmitterService.cachedProtocolWorkHoursData) {
        this.eventEmitterService.subsLoadedProtocolWorkHoursData =
          this.eventEmitterService.protocolWorkHoursDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedProtocolWorkHoursData);
    }
  }

  //register on accounting year data loaded
  public registerEventEmitterHandlerAccountingYear(callback) {
    if (this.eventEmitterService.subsLoadedAccountingYearData == undefined) {
      if (!this.eventEmitterService.cachedAccountingYearData) {
        this.eventEmitterService.subsLoadedAccountingYearData =
          this.eventEmitterService.accountingYearDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedAccountingYearData);
    } else {
      if (!this.eventEmitterService.cachedAccountingYearData) {
        this.eventEmitterService.subsLoadedAccountingYearData =
          this.eventEmitterService.accountingYearDataLoaded.subscribe(
            (user: any) => {
              callback(user);
            }
          );
      } else callback(this.eventEmitterService.cachedAccountingYearData);
    }
  }

  /**
   * Die Anzahl der Förderpunkte wird berechnet.
   * Anhand der Einträge in der entsprechenden collection
   *
   * @param userData
   * @param usersEncouragemenPointsData
   * @param allAvailable if true, calculates all points that are available now. If false, calculates the points that are not unlocked by now.
   * @returns the count of points
   */
  public getUnlockedEncouragementPointsCount(
    userData: User,
    usersEncouragemenPointsData: any,
    allAvailable: boolean = true
  ) {
    if (userData) {
      try {
        //Anzahl potentieller Förderpunkte
        return this.calculateEncouragementPointsCount(
          allAvailable,
          usersEncouragemenPointsData
        );
      } catch (error) {
        this.log.error(
          'Anzahl Förderpunkte konnte nicht berechnet werden.',
          error,
          CommonFunctionsService.name
        );
      }
    }
    return 0;
  }

  /**
   * Calculates the total count of encouragement points. Takes care of points that are not unlocked by now.
   *
   * @param allAvailable if true, calculates all points that are available now. If false, calculates the points that are not unlocked by now.
   * @returns
   */
  public calculateEncouragementPointsCount(
    allAvailable: boolean,
    usersEncouragemenPointsData: any
  ) {
    var allAvailableRightNow = 0;
    var notAvailableRightNow = 0;

    //calculate the points that are available right now
    var allPoints = usersEncouragemenPointsData;
    var currentDate = firebase.firestore.Timestamp.fromDate(new Date());
    allPoints?.forEach((element) => {
      if (element.hasOwnProperty('unlockedAt')) {
        var unlockedAt = element.unlockedAt;
        if (currentDate >= unlockedAt) {
          //Points are available at the moment
          allAvailableRightNow += this.sumEncouragementPointsByAction(element);
        } else {
          //Points that are not availbale right now
          notAvailableRightNow += this.sumEncouragementPointsByAction(element);
        }
      } else {
        //hat keinen timestamp ab wann er frei ist, also ist er sofort verfuegbar
        allAvailableRightNow += this.sumEncouragementPointsByAction(element);
      }
    });
    if (allAvailable) {
      return allAvailableRightNow;
    } else {
      return notAvailableRightNow;
    }
  }

  //All Encouragement Points
  public sumEncouragementPoints(usersEncouragemenPointsData: any) {
    var ret = 0;
    var allPoints = usersEncouragemenPointsData;
    allPoints?.forEach((element) => {
      ret += this.sumEncouragementPointsByAction(element);
    });
    return ret;
  }

  private sumEncouragementPointsByAction(element) {
    var sum = 0;
    if (element.action == EncouragementPointEntryAction.ADD) {
      sum += element.amount;
    } else if (element.action == EncouragementPointEntryAction.REMOVE) {
      sum -= element.amount;
    }
    return sum;
  }

  //Berechne Punkte die aus Arbeitsstunden entstehen
  public getPointsFromWorkHours(userData: User) {
    return GetPointsFromWorkHours(userData?.sumWorkHours);
  }

  public getPrettyPrintedUserName(userData: User) {
    if (!userData) return '';
    var firstName = this.extractData(userData, 'personalData.firstName');
    var lastName = this.extractData(userData, 'personalData.lastName');
    return firstName + ' ' + lastName;
  }

  public getPrettyPrintedFirstName(userData: User) {
    if (!userData) return '';
    return this.extractData(userData, 'personalData.firstName');
  }

  public getPrettyPrintedLastName(userData: User) {
    if (!userData) return '';
    return this.extractData(userData, 'personalData.lastName');
  }

  public makeRandomId(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public makeRandomNumber(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  //Returns the first day of the first month of the next year
  public getStartOfNextYear() {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear + 1, 0, 1);
  }

  /**
   * Finds a specific user in the given data array.
   *
   * @param userData array of user data
   * @param userIdToFind id to search for (property to match: uid)
   * @returns the found entry or undefined if not found.
   */
  public findUser(userData: User[], userIdToFind: string) {
    if (userData) {
      return userData.find((x) => {
        return x[Properties.UID] == userIdToFind;
      });
    }
    return undefined;
  }

  //Gets the state of this object: not started, running, past
  public getRunningState(object: any): string {
    if (object && object.start != undefined && object.end != undefined) {
      var now = new Date();
      var startAsDate = new Date(object.start.seconds * 1000);
      var endAsDate = new Date(object.end.seconds * 1000);

      if (now <= startAsDate) {
        return EventState.NotBegan;
      }
      if (now > endAsDate) {
        return EventState.Past;
      }
      return EventState.Running;
    }
    return '';
  }

  // Calculate the work hours from a given start and end timestamp.
  // Will result in full hours. If above half an hour -> 1, if below -> 0. 
  // public calculateWorkHoursFromStartAndEnd(
  //   start: firebase.firestore.Timestamp,
  //   end: firebase.firestore.Timestamp
  // ): number {
  //   // Convert Timestamps to JavaScript Date objects
  //   const startDate = start.toDate();
  //   const endDate = end.toDate();

  //   // Calculate the difference in milliseconds
  //   const diffMs = endDate.getTime() - startDate.getTime();

  //   // Convert the difference from milliseconds to hours
  //   const diffHours = diffMs / (1000 * 60 * 60);

  //   // Round to the nearest hour based on whether the duration is above or below half an hour
  //   return Math.round(diffHours);
  // }

  //Checks if a specific work packages has already been created for this event
  public isWpKindAlreadyAvailable(
    workPackages: IWorkPackage[],
    kind: WorkPackageKind
  ): boolean {
    if (workPackages && workPackages.length > 0) {
      return workPackages.find((x) => x.kind == kind) != undefined;
    }
    return false;
  }

  //Wait a specific delay
  public sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Decide if to show a wp
  public showWpView(
    wp: IWorkPackage,
    viewMode: EventViewMode,
    user: User
  ): boolean {
    var ret = true; //default: visible

    if (viewMode == EventViewMode.Admin) {
      // console.log('Admin view. Show WP.');
    } else if (this.hideForUnder18(wp, user)) {
      return false;
    } else if (viewMode == EventViewMode.MyShifts) {
      //View: Meine Schichten
      //Anzeigen, wenn: Schichtleiter oder teilnehmer und Schicht freigegeben ist

      //check if this user is shift leader
      var isShiftLeader = this.isShiftOrganizer(wp, user.uid);
      var participates = this.participatesInShift(wp, user.uid);
      var isShiftReleased = wp.releasedForUsers;

      if (isShiftReleased && (isShiftLeader || participates)) {
        // console.log(
        //   `(${EventViewMode[viewMode]}) User is shift leader or participates on a shift. Show WP.`
        // );
        ret = true;
      } else {
        ret = false;
      }
    } else if (viewMode == EventViewMode.ShiftRegistration) {
      //View: Schichtanmeldung
      //Anzeigen, wenn: noch plaetze zur Anmeldung vorhanden (ausser bei Auf- und Abbau)
      //hide wenn beendet oder nicht freigegeben

      var isShiftReleased = wp.releasedForUsers;
      var ended = this.getRunningState(wp) == EventState.Past;
      var shiftFull = this.isShiftFull(wp);
      var isConstrOrDismantling = this.isConstructionOrDismantling(wp);

      //construction or dismantling
      if (isConstrOrDismantling) {
        // console.log(`(${EventViewMode[viewMode]}) Construction or dismantling. Show WP.`);
      } else {
        //shift full
        if (shiftFull) ret = false;
      }

      //shift not released
      if (!isShiftReleased) ret = false;

      //shift ended
      if (ended) ret = false;
    }
    return ret;
  }

  //Decide to show a event
  public showEventView(
    event: IEvent,
    viewMode: EventViewMode,
    userData: User
  ): boolean {
    //Zeige event, wenn mindestens ein Work package anzeigt, dass es angezeigt werden soll
    var processedInBackend = event.processedInBackend ?? false;
    var adminView = viewMode == EventViewMode.Admin || viewMode == EventViewMode.SimplifiedAdmin;

    //Show even when processed when admin view
    if (adminView) {
      return true;
    } else if (!adminView && processedInBackend) {
      return false;
    }
    return event.workPackages?.some((wp) => this.showWpView(wp, viewMode, userData));
  }

  public hideForUnder18(wp: IWorkPackage, user: User): boolean {
    if (wp.isUe18 && !this.isOver18(user?.personalData?.birthDate)) return true;
    return false;
  }

  //Shows debug info about a work package
  public showWpDebugInfo(
    wp: IWorkPackage,
    currentUser: User,
    viewMode: EventViewMode
  ) {
    return `Name: ${wp.type} | released: ${wp.releasedForUsers} | respPerson: ${wp.responsiblePerson
      } | ended: ${this.getRunningState(wp) == EventState.Past
      } | shift leader: ${this.isShiftOrganizer(
        wp,
        currentUser.uid
      )} | participates: ${this.participatesInShift(
        wp,
        currentUser.uid
      )} | shift full: ${this.isShiftFull(
        wp
      )} | decided visible: ${this.showWpView(
        wp,
        viewMode,
        currentUser
      )} | viewMode: ${EventViewMode[viewMode]} | id: ${wp.id}`;
  }

  public participatesInShift(wp: IWorkPackage, currentUserId: string): boolean {
    if (wp && wp.participants && wp.participants.length > 0) {
      //check if this user is a participant. If so, show the event
      var found = wp.participants?.find((x) => x.userId == currentUserId);
      if (found != undefined) return true;

      //check if responsible person for this shift
      if (wp.responsiblePersonUid == currentUserId) return true;
    }
    return false;
  }

  public getUserByIdFromShift(
    wp: IWorkPackage,
    currentUserId: string
  ): IParticipant {
    if (wp.participants && wp.participants.length > 0) {
      //check if this user is a participant. If so, show the event
      var found = wp.participants?.find((x) => x.userId == currentUserId);
      if (found != undefined) return found;
    }
    return undefined;
  }

  public isShiftFull(wp: IWorkPackage): boolean {
    return wp.participants?.length >= wp.minParticipants;
  }

  public shiftHasEnded(wp: IWorkPackage): boolean {
    return this.getRunningState(wp) == EventState.Past;
  }

  //Checks if the shift is running
  public isShiftRunning(wp: IWorkPackage): boolean {
    if (wp == undefined)
      return false;
    var now = new Date();
    var shiftStart = new Date(wp.start.seconds * 1000);
    var shiftEnd = new Date(wp.end.seconds * 1000);
    return (
      now.getTime() >= shiftStart.getTime() &&
      now.getTime() <= shiftEnd.getTime()
    );
  }

  /**
   * Checks if the event has ended
   * 
   * @param event data
   * @param delayAtEnd - add ms at the end
   * @returns 
   */
  public eventHasEnded(event: IEvent, delayAtEnd: number = 0): boolean {
    if (event == undefined)
      return false;
    var now = new Date();
    // var start = new Date(event.start.seconds * 1000);
    var end = new Date((event.end.seconds * 1000) + delayAtEnd);
    return (
      now.getTime() >= end.getTime()
    );
  }

  /**
   * Loads all events that are released for users and have not expired.
   *
   * @param {IEvent[]} allEvents - The list of all events.
   * @param {boolean} filterEnded - Filter out events that are outdated.
   * @returns {IEvent[]} - Returns a list of events that are released for users and have not expired.
   */
  public loadNonOutdatedEvents(
    allEvents: IEvent[],
    filterEnded: boolean = true
  ): IEvent[] {
    var today = new Date();

    //filtere nach events die freigegeben und nicht abgelaufen sind
    var data: IEvent[] = allEvents;
    if (filterEnded) {
      var ret = data.filter((x) => {
        var end = new Date(x.end.seconds * 1000);
        if (x.releasedForUsers && today.getTime() < end.getTime()) {
          return true;
        }
        return false;
      });
      return ret;
    }
    return data;
  }

  /**
   * Loads all events that are from past.
   *
   * @param {IEvent[]} allEvents - The list of all events.
   * @param {boolean} filterEnded - Filter out events that are outdated.
   * @param {boolean} ofThisYearOnly - Filter out only events that are outdated from this year.
   * @returns {IEvent[]} - Returns a list of events that are from past.
   */
  public loadPastEvents(allEvents: IEvent[], ofThisYearOnly: boolean = false): IEvent[] {
    var today = new Date();
    var currentYear = new Date().getFullYear();

    var data: IEvent[] = allEvents;
    var ret = data.filter((x) => {
      var end = new Date(x.end.seconds * 1000);
      if (today.getTime() > end.getTime()) {
        if (ofThisYearOnly) {
          if (end.getFullYear() == currentYear) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    });
    return ret;
  }


  /**
 * Checks if the current user is a shift organizer and shows the events administration.
 * @param user - The user object to check.
 * @returns A promise that resolves to a boolean indicating whether to show the events administration or not.
 */
  public async showEventPlanningForShiftOrganzier(user: User): Promise<boolean> {
    var ret = await this.dataModel.get(Event(), false, true);
    if (ret.result) {
      if (ret.data && ret.data.length == 0) {
        this.log.debug('No events found.', CommonFunctionsService.name);
        return false;
      }
      var allEvents = this.loadNonOutdatedEvents(ret.data, false);
      if (allEvents && allEvents.length > 0) {

        //Note: has to be a for-loop instead of a for-each. Because async foreach is problematic
        for (let index = 0; index < allEvents.length; index++) {
          const event = allEvents[index];
          var path = `${Event()}/${event.id}/${Sub_WorkPackage()}`;

          // get work package
          var ret = await this.dataModel.get(path, false, true);
          if (ret.result) {
            var wps = ret.data || [];

            //Note: has to be a for-loop instead of a for-each. Because async foreach is problematic
            for (let index = 0; index < wps.length; index++) {
              const workPackage = wps[index];
              if (this.isShiftOrganizer(workPackage, user.uid)) {
                return true;
              }
            }
          } else {
            this.log.error(
              `Error while loading work packages for event ${event.id}: ${ret.errorMessage}`
            );
          }
        }
      }
    }
    return false;
  }


  //checks if current user is the shift organizer
  public isShiftOrganizer(wp: IWorkPackage, userUId: string): boolean {
    if (wp && userUId) {
      return wp.responsiblePersonUid == userUId;
    }
    return false;
  }

  public isEventOrganizer(event: IEvent, userData: User): boolean {
    return event.responsiblePersonId == userData.uid;
  }

  public isShiftOrganizerInEventWOEO(event: IEvent, userData: User): boolean {
    if (event && userData) {
      var wps = event.workPackages || [];
      return (
        wps.find((x) => x.responsiblePersonUid == userData.uid) != undefined
      );
    }
    return false;
  }

  //Checks if user is either event organizer, or shift leader in any of the available shifts
  public isShiftOrganizerInEvent(event: IEvent, userData: User): boolean {
    if (event && userData) {
      var wps = event.workPackages || [];
      if (event.responsiblePersonId == userData.uid) {
        return true;
      }
      return (
        wps.find((x) => x.responsiblePersonUid == userData.uid) != undefined
      );
    }
    return false;
  }

  public getDayName(date: Date) {
    if (date) {
      var days = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ];
      return days[date.getDay()];
    }
    return '';
  }

  public isOver18(birthDate: firebase.firestore.Timestamp): boolean {
    // Get the current date
    const currentDate = new Date();

    // Convert Firestore timestamp to Date object
    const birthDateObj = birthDate.toDate();

    // Calculate the age
    const age = currentDate.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDateObj.getMonth();
    const dayDiff = currentDate.getDate() - birthDateObj.getDate();

    // Adjust the age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      return age > 18;
    }

    return age >= 18;
  }

  //Checks if the work package is of kind 'Aufbau (COnstruction)' or 'Abbau (Dismantling)'.
  public isConstructionOrDismantling(wp: IWorkPackage): boolean {
    if (wp == undefined)
      return false;
    if (
      wp?.kind == WorkPackageKind.Construction ||
      wp?.kind == WorkPackageKind.Dismantling
    )
      return true;
    return false;
  }
}
