import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { WpKindPipe } from 'src/app/pipes/wp-kind.pipe';
import {
  CommonFunctionsService,
  DialogType,
} from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import 'firebase/firestore';
import firebase from 'firebase/app';
import {
  IWorkPackage,
  User,
  IEvent,
  EventViewMode,
  WpViewButtonVisibilityIndex,
  EventState,
  WorkPackageKind,
  Sub_Participant,
  IParticipant,
  ParticipantState,
  AbortShiftDialogData,
  QrCodeDialogData,
  QrCodeTrigger,
  CamScanDialogData,
  IComponentChanged,
  MethodReturn,
  Users,
  Properties,
} from 'src/app/services/definitions.service';
import { LogService } from 'src/app/services/log.service';
import { ShiftAbortComponent } from 'src/app/dialogs/shift-abort/shift-abort.component';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeDialog } from 'src/app/dialogs/qr-code/qr-code.component';
import { CamScanDialog } from 'src/app/dialogs/cam-scan/cam-scan-dialog/cam-scan-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserRightsManagementService } from 'src/app/services/user-rights-management.service';

@Component({
  selector: 'wp-view-button',
  templateUrl: './wp-view-button.component.html',
  styleUrls: ['./wp-view-button.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WpViewButtonComponent implements OnInit {
  @Input() wp: IWorkPackage;
  @Input() event: IEvent;
  @Input() viewMode: EventViewMode;
  @Input() users: User[];
  @Output() componentChanged = new EventEmitter<IComponentChanged>();

  private userData: User;
  public visibility = WpViewButtonVisibilityIndex;
  public hintText: string = '';

  constructor(
    private common: CommonFunctionsService,
    private wpPipe: WpKindPipe,
    private dataModel: DataModelService,
    private log: LogService,
    public dialog: MatDialog,
    private authService: AuthService,
    private userRightsManagemetService: UserRightsManagementService
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  ngOnInit(): void { }

  /**
   * Rergister for a work package
   * 
   * @returns 
   */
  public async registerForWorkPackage(silent: boolean = false): Promise<MethodReturn> {
    var wp = this.wp;
    var ret: MethodReturn = { result: false };

    //Note: this is currently disabled
    //If construction or dismantling, start showing qr code scan
    // if (this.isConstructionOrDismantling()) {
    //   wp = await this.handleQrCodeShiftRegistration(wp);
    //   if (!wp) {
    //     return;
    //   }
    // }

    //initial check
    if (!this.userData || !wp) {
      var msg = 'Deine User-Daten konnten nicht geladen werden';
      if (!silent)
        this.common.showErrorToast(
          msg
        );
      ret.errorMessage = msg;
      return ret;
    }

    var firstName = this.common.getPrettyPrintedFirstName(this.userData);
    var lastName = this.common.getPrettyPrintedLastName(this.userData);
    var startDate = wp.start;
    var endDate = wp.end;

    //if the shift is already running, take the current time as the start time
    if (this.getRunningShiftRunningState() == EventState.Running)
      startDate = firebase.firestore.Timestamp.fromDate(new Date());

    //check if already added (in case of early abortion)
    var existing = await this.participantAlreadyExisting(wp);
    if (existing) {
      var msg = 'Du bist bereits für diese Schicht angemeldet. Eventuell hast du sie schon vorzeitig beendet. Melde dich beim Schichtleiter.'
      if (!silent)
        this.common.showErrorToast(
          msg,
          10000
        );
      ret.errorMessage = msg;
      return ret;
    }

    //check if already full
    var full = await this.shiftIsFull();
    if (full) {
      var msg = 'Die Schicht ist bereits voll.'
      if (!silent)
        this.common.showErrorToast(
          msg,
          3000, true
        );
      ret.errorMessage = msg;
      this.update();
      return ret;
    }

    // check if user is shift organizer -> not allowed to register as participant
    if (this.isShiftOrganizer()) {
      var msg = 'Schichtleiter können sich nicht als Teilnehmer anmelden'
      if (!silent)
        this.common.showErrorToast(
          msg,
          7000
        );
      ret.errorMessage = msg;
      return ret;
    }

    //leave present flag to false. Admin shall define if the person is at his working place. 
    var participantData: IParticipant = {
      state: ParticipantState.PARTICIPATING,
      firstName: firstName,
      lastName: lastName,
      userId: this.userData.uid,
      start: startDate,
      end: endDate,
      present: false,
    };

    if (this.isConstructionOrDismantling()) {
      participantData.present = true;
    }

    var path = wp.path; //path to work package
    var set = await this.addParticipant(path, participantData);
    if (set.result) {
      if (!silent)
        this.common.showSuccessToast('Anmeldung Erfolgreich');
      ret.result = true;
      this.update();
      return ret;
    } else {
      if (!silent)
        this.common.showErrorToast('Anmeldung fehlgeschlagen');
      ret.errorMessage = msg;
      return ret;
    }
  }


  /**
   * Unregister from work package 
   * @returns 
   */
  public async unregisterForWorkPackage() {
    var eventOrganizer = this.findEventOrganizer();
    var shiftOrganizer = this.findWpOrganizer();
    var nameAbortee = `${this.common.getPrettyPrintedUserName(this.userData)}`;
    var isConstructionOrDismantling = this.isConstructionOrDismantling();

    //Wenn Auf- oder Abbau:
    if (isConstructionOrDismantling) {
      //  - Wenn aktuelle Zeit < Start der Schicht: Normal verfahren. Shift-Abort Dialog anzeigen
      //  - Wenn aktuelle Zeit innerhalb Schicht-Zeit: Nichts tun. Also auch keinen Button zur abmeldung anzeigen (wird im visibility check gehandelt). Admin muss das klaeren.
      //  - Wenn aktuelle Zeit > Schicht-Ende: Keinen abmelde-button anzeigen
      if (this.getRunningShiftRunningState() == EventState.NotBegan) {
        //do nothing (pass)
      } else if (this.getRunningShiftRunningState() == EventState.Running) {
        //do nothing (return)
        return;
      } else {
        // do nothing
        return;
      }
      //Wenn normale Schicht:
    } else {
      //  - Wenn aktuelle Zeit < Start der Schicht: Normal verfahren. Shift abort dialog zeigen.
      //  - Wenn aktuelle Zeit innerhalb Schicht-Zeit: Schicht schliessen bzw. den Endzeitpunkt auf den aktuellen Zeitpunkt setzen.
      //  - Wenn aktuelle Zeit > Schicht-Ende: Out of scope (button wird nicht mehr angezeigt).

      if (this.getRunningShiftRunningState() == EventState.NotBegan) {
        //do nothing (pass)
      } else if (this.getRunningShiftRunningState() == EventState.Running) {
        var now = firebase.firestore.Timestamp.fromDate(new Date());

        //find particapant data
        var participant = this.common.getUserByIdFromShift(
          this.wp,
          this.userData.uid
        );
        if (participant) {
          participant.end = now;
          participant.state = ParticipantState.ENDED_EARLIER;
          var updatedParticipant = await this.dataModel.updateParticipant(
            participant
          );
          if (!updatedParticipant.result) {
            this.log.error(
              'Failed to update participants data. Id: ' + participant.id
            );
            this.common.showErrorToast(
              'Schicht-Daten konnten nicht gespeichert werden'
            );
          } else {
            this.common.showSuccessToast('Schicht erfolgreich beendet');
            this.update();
          }
        } else {
          this.common.showErrorToast(
            'Schicht-Daten konnten nicht geladen werden'
          );
        }
        return;
      } else {
        // do nothing
        return;
      }
    }

    var data: AbortShiftDialogData = {
      decisionYes: false,
      userDataOrganizer: eventOrganizer,
      userDataShiftRespPerson: shiftOrganizer,
      eventData: this.event,
      shiftData: this.wp,
      aborteeName: nameAbortee,
      shiftOrganizerPhoneNumber: this.getShiftOrganizerTelNumber(),
    };
    var dialogResult: AbortShiftDialogData = await this.dialog
      .open(ShiftAbortComponent, {
        width: '90%',
        data: data,
      })
      .afterClosed()
      .toPromise();

    if (dialogResult.decisionYes) {
      if (this.wp.participants) {
        var found = this.common.getUserByIdFromShift(
          this.wp,
          this.userData.uid
        );
        if (found) {
          var set = await this.dataModel.deleteGeneric(found.path, false);
          if (set.result) {
            this.common.showSuccessToast('Abmeldung Erfolgreich');
            this.update();
            return;
          }
        }
      }
      this.common.showErrorToast('Konnte dich nicht abmelden');
    }
  }

  //Notifies the parent to update
  public update() {
    var changed: IComponentChanged = { id: this.wp.id };
    this.componentChanged.emit(changed);
  }

  //Starts/Ends Aufbau/Abbau
  //Sets the start time of the shift to now and the end time to 4 hours later.
  public startEndConstructionDismantling(kind: WorkPackageKind, start: boolean) {
    var title = '';
    var msg = '';

    if (start) {
      title = `${this.wpPipe.transform(kind, 'pipeFilter')} starten`;
      msg = `Willst du den ${this.wpPipe.transform(
        kind,
        'pipeFilter'
      )} starten?`;
    } else {
      title = `${this.wpPipe.transform(kind, 'pipeFilter')} beenden`;
      msg = `Willst du den ${this.wpPipe.transform(
        kind,
        'pipeFilter'
      )} beenden?`;
    }

    //show dialog
    this.common
      .openDialog(title, msg, DialogType.INFO, 'Nein', 'Ja', true)
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          var ret = await this.dataModel.markWorkPackageStarted(
            this.wp.path,
            start
          );
          var now = firebase.firestore.Timestamp.fromDate(new Date());
          var failedToUpdateAnyParticipant = false;

          //mark all participants as started/ended
          if (this.wp.participants) {

            //Note: has to be a for-loop instead of a for-each. Because async foreach is problematic
            for (let index = 0; index < this.wp.participants.length; index++) {
              const participant = this.wp.participants[index];

              if (this.shiftRunning()) participant.end = now;
              else participant.start = now;
              var updatedParticipant = await this.dataModel.updateParticipant(
                participant
              );
              if (!updatedParticipant.result) {
                this.log.error(
                  'Failed to update participants data. Id: ' + participant.id
                );
                failedToUpdateAnyParticipant = true;
              }
            }
          }

          var text = start ? 'gestartet' : 'beendet';
          if (ret) {
            await this.common.showSuccessToast(
              `${this.wpPipe.transform(
                kind,
                'pipeFilter'
              )} erfolgreich ${text}`,
              3000,
              true
            );

            //show QR Code dialog (in case the event was started)
            if (start) {
              this.showQrCode(QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT);
            }
          } else {
            await this.common.showErrorToast(
              `${this.wpPipe.transform(
                kind,
                'pipeFilter'
              )} konnte nicht ${text} werden`,
              5000,
              true
            );
          }

          if (failedToUpdateAnyParticipant) {
            await this.common.showErrorToast(
              'Nicht alle Teilnehmer konnten upgedated werden',
              5000,
              true
            );
          }
          //reload
          this.update();
        }
      });
  }

  //Finds the organizers data
  private findEventOrganizer(): User | undefined {
    if (this.event && this.event?.responsiblePersonId && this.users) {
      return this.users.find((x) => x.uid == this.event.responsiblePersonId);
    }
    this.log.error("Unable to find Event Organizer's data");
    return undefined;
  }

  //Finds the organizers data
  private findWpOrganizer(): User | undefined {
    if (this.wp && this.users) {
      return this.users.find((x) => x.uid == this.wp.responsiblePersonUid);
    }
    this.log.error("Unable to find WorkPackage Organizer's data");
    return undefined;
  }

  public callShiftOrganizer(telNumber: number) {
    window.open(`tel:${telNumber}`);
  }

  //Checks if a user has a telephone number set
  public getShiftOrganizerTelNumber(): string | undefined {
    var shiftOrganizer = this.findWpOrganizer();
    if (shiftOrganizer) {
      if (shiftOrganizer.contact.telMobile) {
        return shiftOrganizer.contact.telMobile;
      }
      if (shiftOrganizer.contact.telPrivate) {
        return shiftOrganizer.contact.telPrivate;
      }
    }
    return undefined;
  }

  public setUserData(userData: User) {
    this.userData = userData;
  }

  public getUserData() {
    return this.userData;
  }

  //Decide if to show the participate button
  private showParticipateButton(): boolean {
    //only these are allowed to participate
    if (
      this.viewMode == EventViewMode.ShiftRegistration ||
      this.viewMode == EventViewMode.MyShifts
    ) {
      //if user is shift organizer, hide button
      if (this.isShiftOrganizer()) return false;

      //if work package is only for adults, and current user is under 18, hide
      if (
        this.wp?.isUe18 &&
        !this.common.isOver18(this.userData?.personalData?.birthDate)
      )
        return false;

      //if it is an construction or dismantling shift
      if (this.isConstructionOrDismantling()) {
        //check if shift is over. If yes, hide button
        if (this.shiftOver()) return false;

        //Participation at that time via the qr code. Code to scan qr code is implemented in register function.
        if (this.shiftRunning()) {
          //return false to hide (intended) the shift abort button
          if (this.participatesShift()) return false;

          //to show shift register button
          if (!this.participatesShift()) return true;
        }
        return true;
      } else {
        //If shift is full and the user is not assigned to this shift, dont show button to participate
        //Note: This seems weired, but the behaviour of the button changes due to the participation state. The button shall be shown to allow to unregister.
        if (
          this.wp?.participants?.length >= this.wp?.minParticipants &&
          !this.participatesShift()
        ) {
          return false;
        }

        //running: don't show (admin has to handle abortion or registration of user) or if shift has ended, hide button
        if (this.shiftOver()) return false;
        return true;
      }
    }
    return false;
  }


  public participatesShift(): boolean {
    return this.common.participatesInShift(this.wp, this.userData.uid);
  }

  public isShiftOrganizer(): boolean {
    return this.common.isShiftOrganizer(this.wp, this.userData.uid);
  }

  /**
   * Check if user is the responsible person for the event or the user is part of the events admin group.
   * 
   * @returns 
   */
  public isEventOrganizer(): boolean {
    return this.common.isEventOrganizer(this.event, this.userData) || this.authService.isEventsAdmin;
  }

  public isAdmin(): boolean {
    return this.userRightsManagemetService.isPartOfAdminGroup();
  }

  public shiftOver(): boolean {
    return this.common.getRunningState(this.wp) == EventState.Past;
  }

  public shiftRunning(): boolean {
    return this.common.isShiftRunning(this.wp);
  }

  public isShiftFull(): boolean {
    return this.wp?.participants?.length >= this.wp?.minParticipants;
  }

  public isConstructionOrDismantling(): boolean {
    return this.common.isConstructionOrDismantling(this.wp);
  }

  public isEventOver(): boolean {
    return this.common.eventHasEnded(this.event);
  }

  public isEventOverWithDelay(): boolean {
    return this.common.eventHasEnded(this.event, 24 * 60 * 60 * 1000); //delay: 24h
  }

  public getRunningShiftRunningState(): string {
    return this.common.getRunningState(this.wp);
  }

  public isShiftOrganizerNumberAvailable(): boolean {
    return this.getShiftOrganizerTelNumber() != undefined;
  }

  //Special time property for a construction or dismantling shift
  public cdDefinedStartSet(): boolean {
    return this.wp && this.wp.definedStart != undefined;
  }

  //Special time property for a construction or dismantling shift
  public cdDefinedEndSet(): boolean {
    return this.wp && this.wp.definedEnd != undefined;
  }

  //Special time property for a construction or dismantling shift.
  //Defines if the shift is still running
  public cdRunning(): boolean {
    return this.wp && this.wp.definedStart != undefined && this.wp.definedEnd == undefined;
  }

  // If shift is for under 18 people and the user is under 18
  public onlyForAdults(): boolean {
    return (
      this.wp?.isUe18 &&
      !this.common.isOver18(this.userData?.personalData?.birthDate)
    );
  }

  //Checks if the current user has already been added to the assigned work package
  public async participantAlreadyExisting(wp: IWorkPackage): Promise<boolean> {
    //check if already added (in case of early abortion)
    var existing = await this.dataModel.entryExisting(
      wp.path + '/' + Sub_Participant(),
      this.userData.uid
    );
    return existing;
  }

  //Checks if the shift is already full
  public async shiftIsFull(): Promise<boolean> {
    if (this.wp.path == undefined) {
      this.log.error('Path not set in work package');
      return false;      
    }
    var loaded = await this.dataModel.get(this.wp.path + "/" + Sub_Participant());
    if (!loaded.result) {
      this.common.showErrorToast('Konnte Teilnehmer nicht checken');
      this.log.error(loaded.errorMessage);
    } else {
      var count = loaded.data.length;
      return count >= this.wp.minParticipants;
    }
    return false;
  }

  public async addParticipant(wpCollPath: string, participant: IParticipant): Promise<MethodReturn> {
    return await this.dataModel.addParticipant(wpCollPath, participant);
  }

  //Shows the qr code dialog for construction and dismantling registration
  private showQrCode(trigger: QrCodeTrigger) {
    //open dialog
    var data: QrCodeDialogData = {
      title: this.wp.type,
      decisionYes: true,
      qrCodeLink: this.wp.path,
      qrCodeTrigger: trigger,
    };
    this.dialog
      .open(QrCodeDialog, {
        width: '500px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: false,
      })
      .afterClosed();
  }

  //Handles the registration for a construction or dismantling shift.
  public async markUserAsPresent() {
    var wpPath = '';
    var res = await this.showCamScanDialog(
      QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT
    );
    if (res.decisionYes) {
      wpPath = res.payload;
    } else {
      return;
    }
    var wpLoaded = await this.checkWp(wpPath);
    if (wpLoaded == undefined) return;

    //mark all participants as started/ended
    if (this.wp.participants) {
      //find participant
      var foundParticipant = this.wp.participants.find((participant) => {
        if (participant.userId == this.userData.uid) {
          return true;
        }
      });
      if (!foundParticipant) {
        this.log.error('Participant not found that is this user');
        this.common.showErrorToast('Konnte dich nicht als Teilnehmer finden');
        return;
      }

      foundParticipant.present = true;
      foundParticipant.start = firebase.firestore.Timestamp.fromDate(
        new Date()
      );
      var updatedParticipant = await this.dataModel.updateParticipant(
        foundParticipant
      );

      if (!updatedParticipant.result) {
        this.log.error(
          'Failed to update participants data. Id: ' + foundParticipant.id
        );
        this.common.showErrorToast('Konnte dich nicht als anwesend melden');
      } else {
        this.common.showSuccessToast('Du bist jetzt als anwesend gemeldet');
      }
    } else {
      this.log.error('No participants found in shift');
      this.common.showErrorToast('Keine Teilnehmer gefunden');
    }
  }

  //Handles the registration for a construction or dismantling shift.
  private async handleQrCodeShiftRegistration(
    wp: IWorkPackage
  ): Promise<IWorkPackage> {
    var wpPath = '';
    var res = await this.showCamScanDialog(
      QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT
    );
    if (res.decisionYes) {
      wpPath = res.payload;
    } else {
      return;
    }
    return await this.checkWp(wpPath);
  }

  //Shows a dialog to scan a qr code
  private showCamScanDialog(qrCodeTrigger: QrCodeTrigger) {
    var data: CamScanDialogData = {
      title: 'Bitte scanne den QR-Code',
      decisionYes: true,
      qrCodeTrigger: qrCodeTrigger,
    };

    //open dialog
    return new Promise<CamScanDialogData>((resolve, reject) => {
      this.dialog
        .open(CamScanDialog, {
          width: '500px',
          height: 'auto',
          data: data,
          autoFocus: true,
          disableClose: false,
        })
        .afterClosed()
        .subscribe((data: QrCodeDialogData) => {
          resolve(data);
        });
    });
  }

  //Check if a work package is valid for registration
  private async checkWp(wpPath: string): Promise<IWorkPackage | undefined> {
    var wpLoaded = await this.dataModel.getByPath(wpPath);
    if (!wpLoaded.result) {
      this.log.error('Error loading WP by path:' + wpPath);
      this.common.showErrorToast('Schicht konnte nicht geladen werden.');
      return undefined;
    }
    var loadedWp: IWorkPackage = wpLoaded.data;
    var runningState = this.common.getRunningState(loadedWp);

    //check work package
    if (!loadedWp.releasedForUsers) {
      this.common.showErrorToast('Schicht nicht freigegeben.');
      return undefined;
    }
    if (runningState == EventState.Past) {
      this.common.showErrorToast('Schicht bereits zu Ende.');
      return undefined;
    }
    if (runningState == EventState.NotBegan) {
      this.common.showErrorToast('Schicht noch nicht begonnen.');
      return undefined;
    }
    return loadedWp;
  }

  //Controls the visibility of specific ui elements depending on the view mode
  public isVisible(index: WpViewButtonVisibilityIndex): boolean {
    switch (index) {
      case WpViewButtonVisibilityIndex.ParticipateButton:
        if (this.event?.processedInBackend) return false;
        return this.showParticipateButton() && !this.participatesShift();
      case WpViewButtonVisibilityIndex.UnparticipateButton:
        if (this.event?.processedInBackend) return false;
        return (
          this.showParticipateButton() &&
          this.participatesShift() &&
          !this.shiftRunning()
        );
      case WpViewButtonVisibilityIndex.StartShiftButton:
        if (this.event?.processedInBackend) return false;
        if (this.isEventOver()) return false;
        var viewModeCorrect = this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.MyShifts || this.viewMode == EventViewMode.SimplifiedAdmin;
        if (this.cdRunning()) return false;
        if (this.isConstructionOrDismantling() && viewModeCorrect && (this.isEventOrganizer() || this.isShiftOrganizer() || this.isAdmin())) return true;
        return false;
      case WpViewButtonVisibilityIndex.StopShiftButton:
        if (this.event?.processedInBackend) return false;
        if (this.isEventOverWithDelay()) return false;
        if (!this.cdRunning()) return false;
        var viewModeCorrect = this.viewMode == EventViewMode.Admin || this.viewMode == EventViewMode.MyShifts || this.viewMode == EventViewMode.SimplifiedAdmin;
        if (this.isConstructionOrDismantling() && viewModeCorrect && (this.isEventOrganizer() || this.isShiftOrganizer() || this.isAdmin())) return true;
        return false;
      case WpViewButtonVisibilityIndex.HintText:
        if (this.event?.processedInBackend) return false;
        if (this.onlyForAdults()) return true;
        if (this.participatesShift() && this.shiftRunning()) return true;
        if (!this.participatesShift() && this.isShiftFull()) return true;
        if (!this.participatesShift() && this.isShiftOrganizer()) return true;
        return false;
      case WpViewButtonVisibilityIndex.PresenceRegisterButton:
      case WpViewButtonVisibilityIndex.QrCodePresenceRegister:
        var viewModeCorrect = this.viewMode == EventViewMode.ShiftRegistration || this.viewMode == EventViewMode.MyShifts;
        if (this.isAdmin()) return true;
        if (!viewModeCorrect) return false;
        if (this.isShiftOrganizer()) return false;// if user is shift organizer, hide button
        if (this.shiftOver()) return false;
        if (this.isConstructionOrDismantling() && this.shiftRunning() && this.participatesShift()) return true;
        return false;
      case WpViewButtonVisibilityIndex.CallShiftOrganizerButton:
        if (this.event?.processedInBackend) return false;
        return (this.participatesShift() && this.shiftRunning() && this.isShiftOrganizerNumberAvailable() && !this.isShiftOrganizer());
      default:
        return false;
    }
  }
}
