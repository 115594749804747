import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import { LogService } from '../services/log.service';
import { Router } from '@angular/router';
import { MessagingService } from '../services/messaging.service';

/** Error when invalid control is dirty, touched, or submitted. */
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(
//     control: FormControl | null,
//     form: FormGroupDirective | NgForm | null
//   ): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(
//       control &&
//       control.invalid &&
//       (control.dirty || control.touched || isSubmitted)
//     );
//   }
// }

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  // public userData: any;
  // form = new FormGroup({});
  // matcher = new MyErrorStateMatcher();
  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  // emailCompanyFormControl = new FormControl('', [Validators.email]);
  // emailPrivateFormControl = new FormControl('', [Validators.email]);
  // telPrivateFormControl = new FormControl('');
  // telCompanyFormControl = new FormControl('');
  // telMobileFormControl = new FormControl('');
  // entryDateFormControl = new FormControl('');
  // leavingDateFormControl = new FormControl('');
  // membershipNumberFormControl = new FormControl('');
  // stateFormControl = new FormControl('');
  // typeFormControl = new FormControl('');
  // firstNameFormControl = new FormControl('');
  // lastNameFormControl = new FormControl('');
  // birthdayFormControl = new FormControl('');
  // streetFormControl = new FormControl('');
  // houseNumberFormControl = new FormControl('');
  // plzFormControl = new FormControl('');
  // cityFormControl = new FormControl('');
  // public isCollapsedContactInformations = false;
  // public isCollapsedMembership = true;
  // public isCollapsedPersonal = true;
  // public isCollapsedFurtherOptions = true;

  constructor(
    public commonFunctions: CommonFunctionsService,
    private formBuilder: FormBuilder,
    public angularFireAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    private authService: AuthService,
    private messagingService: MessagingService,
    private log: LogService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    // this.form = this.formBuilder.group({
    //   email: this.emailFormControl,
    //   emailCompany: this.emailCompanyFormControl,
    //   emailPrivate: this.emailPrivateFormControl,
    //   telPrivate: this.telPrivateFormControl,
    //   telCompany: this.telCompanyFormControl,
    //   telMobile: this.telMobileFormControl,
    //   entryDate: this.entryDateFormControl,
    //   leavingDate: this.leavingDateFormControl,
    //   membershipNumber: this.membershipNumberFormControl,
    //   state: this.stateFormControl,
    //   type: this.typeFormControl,
    //   firstName: this.firstNameFormControl,
    //   lastName: this.lastNameFormControl,
    //   birthday: this.birthdayFormControl,
    //   street: this.streetFormControl,
    //   houseNumber: this.houseNumberFormControl,
    //   plz: this.plzFormControl,
    //   city: this.cityFormControl,
    // });
    // this.spinner.show();
    // this.commonFunctions.registerEventEmitterHandlerUserData((userData) => {
    //   this.userData = userData;
    //   this.fillData(this.userData);
    //   this.spinner.hide();
    // });
  }

  // onSubmit() {
  // this.spinnerService.show();
  // this.dataModelService
  //   .updateUser(this.authService.getCurrentUserId())
  //   .then(
  //     (done) => {
  //       if (done)
  //         this.commonFunctions.showSuccessToast('User-Daten gespeichert');
  //     },
  //     (rejected) => {
  //       this.commonFunctions.showErrorToast(
  //         'User-Daten konnten nicht gespeichert werden: ' + rejected
  //       );
  //     }
  //   )
  //   .catch((error) => {
  //     this.commonFunctions.showErrorToast(
  //       'User-Daten konnten nicht gespeichert werden: ' + error
  //     );
  //   })
  //   .finally(() => {
  //     this.spinnerService.hide();
  //   });
  // }

  //Sends the logs to the developer
  public sendLogsToDev() {
    this.commonFunctions
      .openDialog(
        'Logs senden',
        'Willst du nun die Logs an den Entwickler schicken?',
        DialogType.INFO,
        'Nein',
        'Senden'
      )
      .afterClosed()
      .subscribe((res) => {
        if (res && res.result == 1) {
          this.log.sendLogsToDev(this.authService.userId).then(
            (fulfilled) => {
              this.commonFunctions.showSuccessToast(
                'Logs erfolgreich versandt'
              );
            },
            (rejectMsg) => {
              this.commonFunctions.showErrorToast(
                'Logs nicht versandt: ' + rejectMsg
              );
            }
          );
        }
      });
  }

  //Clear log files
  public clearLogs() {
    this.commonFunctions
      .openDialog(
        'Logs löschen',
        'Willst du deine Log-Dateien wirklich löschen?',
        DialogType.INFO,
        'Nein',
        'Löschen'
      )
      .afterClosed()
      .subscribe((res) => {
        if (res && res.result == 1) {
          this.log.clearLogs();
          this.commonFunctions.showSuccessToast('Logs gelöscht');
        }
      });
  }

  //Starts asking if user wants to receive push notification
  public tryRegisterPushNoti() {
    this.messagingService.requestPermission(true);
  }

  public logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  // //Fills the user data into the forms
  // private fillData(userData: any) {
  //   this.emailFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'email')
  //   );
  //   this.emailCompanyFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'contact.emailCompany')
  //   );
  //   this.emailPrivateFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'contact.emailPrivate')
  //   );
  //   this.telCompanyFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'contact.telCompany')
  //   );
  //   this.telPrivateFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'contact.telPrivate')
  //   );
  //   this.telMobileFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'contact.telMobile')
  //   );
  //   this.birthdayFormControl.setValue(
  //     this.commonFunctions.unixTimeToDate(
  //       this.commonFunctions.extractData(userData, 'personalData.birthDate')
  //         .seconds
  //     )
  //   );
  //   this.cityFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.city')
  //   );
  //   this.firstNameFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.firstName')
  //   );
  //   this.lastNameFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.lastName')
  //   );
  //   this.plzFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.plz')
  //   );
  //   this.houseNumberFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.houseNumber')
  //   );
  //   this.streetFormControl.setValue(
  //     this.commonFunctions.extractData(userData, 'personalData.street')
  //   );
  // }

  // MustMatch(controlName: string, matchingControlName: string) {
  //   return (formGroup: FormGroup) => {
  //     const control = formGroup.controls[controlName];
  //     const matchingControl = formGroup.controls[matchingControlName];

  //     if (matchingControl.errors && !matchingControl.errors.mustMatch) {
  //       // return if another validator has already found an error on the matchingControl
  //       return;
  //     }

  //     // set error on matchingControl if validation fails
  //     if (control.value !== matchingControl.value) {
  //       matchingControl.setErrors({ mustMatch: true });
  //     } else {
  //       matchingControl.setErrors(null);
  //     }
  //   };
  // }
}
