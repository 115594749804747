<div class="login-page">
  <div class="form">
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
      <img *ngIf="!showInstallText" class="residenz-logo"
        src="../../../../../assets/images/logo-black-small-size.png" />

      <div *ngIf="!hideLogin">
        <mat-form-field class="input-field">
          <mat-label>E-Mail</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
            placeholder="Bsp.: max@web.com" cdkFocusInitial />
          <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          <mat-error *ngIf="
              emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')
            ">
            Bitte eine gültige E-Mail Adresse eingeben
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            E-Mail ist <strong>notwendig</strong>
          </mat-error>
        </mat-form-field>

        <button class="button" type="submit" [disabled]="!loginForm.valid"
          [ngStyle]="{ 'background-color': !loginForm.valid ? '#d8d8d8' : '' }">
          Login Code anfordern
        </button>

        <!--------------------------------Debug--------------------------------------->
        <button class="button" type="button" *ngIf="isTestingSystemActive() && isAdminAccessing()"
          (click)="debugLogin(false)" [ngStyle]="{ 'background-color': 'green' }">
          Debug Login
        </button>
        <button class="button" type="button" *ngIf="isTestingSystemActive() && isAdminAccessing()"
          (click)="debugLogin(true)" [ngStyle]="{ 'background-color': 'green' }">
          Debug Login (Productive)
        </button>
        <button class="button" type="button" [disabled]="!loginForm.valid"
          *ngIf="isTestingSystemActive() && isAdminAccessing()" (click)="debugLoginOtherUser()"
          [ngStyle]="{ 'background-color': !loginForm.valid ? 'gray' : 'green' }">
          Debug Login with other User (Productive!)
        </button>
      </div>

      <!--Show installed text-->
      <div *ngIf="showInstallText" class="installed-section">
        <img class="green-check" src="../../../../../assets/icons/green-checkmark.svg" />
        <p>
          Die App wird gerade installiert. Du kannst diese Seite schliessen und
          die App wie jede andere aus dem Menü öffnen.
        </p>
      </div>
    </form>
  </div>
</div>