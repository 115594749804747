<div class="flex-container">
  <div *ngFor="let participant of data; let i = index">
    <mat-accordion>
      <mat-expansion-panel [disabled]="!visibleForAdminOrShiftOrg()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <table>
              <tr>
                <!--Checkmark presence-->
                <th *ngIf="visibleForAdminOrShiftOrg() && isAdminViewMode()" rowspan="2" style="
                                  border-right: 1px solid rgb(63, 62, 62);
                                  padding-right: 20px;
                                ">
                  <mat-checkbox [checked]="participant.present" [disabled]="!eventNotProcessed()" color="primary"
                    matTooltip="Anwesend/Abwesend setzen" (click)="$event.stopPropagation()"
                    (change)="onChangePresence($event, participant)">
                  </mat-checkbox>
                </th>

                <!--Person icon and name-->
                <td style="text-align: center;">
                  <mat-icon matPrefix>person</mat-icon>
                </td>
                <td style="vertical-align: middle;">{{ participant.firstName }} {{ participant.lastName }}</td>
              </tr>

              <!--Presence time-->
              <tr *ngIf="visibleForAdminOrShiftOrg()" style="text-align: center;">
                <td style="vertical-align: middle;">
                  <mat-icon>access_time</mat-icon>
                </td>
                <td style="text-align: left;">
                  {{ participant.start?.seconds * 1000 | date : "H:mm" }} -
                  {{ participant.end?.seconds * 1000 | date : "H:mm" }}
                </td>
              </tr>
            </table>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <table style="margin-top: 20px">
          <tr>
            <td>
              <!-- <time-range-picker
                [start]="asDate(participant?.start)"
                [end]="asDate(participant?.end)"
                [rangeStart]="timestampToDate(workPackage.start)"
                [rangeEnd]="timestampToDate(workPackage.end)"
                (timeChanged)="timeChanged($event, participant)"
                [disable]="event?.processedInBackend"
              ></time-range-picker> -->
              <time-range-picker [start]="asDate(participant?.start)" [end]="asDate(participant?.end)"
                (timeChanged)="timeChanged($event, participant)"
                [disable]="event?.processedInBackend"></time-range-picker>
            </td>
          </tr>
          <tr>
            <td>
              <button mat-raised-button style="width: 90%" color="warn" matTooltip="Teilnehmer entfernen"
                (click)="removeParticipant(participant)" *ngIf="visibleForAdminOrShiftOrg()"
                [disabled]="event?.processedInBackend">
                Teilnehmer entfernen
              </button>
            </td>
          </tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>