<div *ngIf="showComponent">
  <mat-card style="margin: 2% 2% 5% 2%;">
    <p style="font-size: 1.5rem; padding-left: 10px">
      {{ data.type }}
    </p>
    <hr />
    <table>
      <tr>
        <td class="tbl-icons"><mat-icon>calendar_month</mat-icon></td>
        <td class="tbl-content">
          {{ data.start?.seconds * 1000 | date : "dd.MM.yyyy" }} -
          {{ data.end?.seconds * 1000 | date : "dd.MM.yyyy" }}
        </td>
      </tr>
      <tr>
        <td class="tbl-icons"><mat-icon>schedule</mat-icon></td>
        <td class="tbl-content">
          {{ data.start?.seconds * 1000 | date : "H:mm" }} Uhr -
          {{ data.end?.seconds * 1000 | date : "H:mm" }} Uhr
        </td>
      </tr>
      <tr>
        <td class="tbl-icons"><mat-icon>person</mat-icon></td>
        <td class="tbl-content">
          Schichtleiter:
          {{
            data.responsiblePerson == ""
              ? "nicht festgelegt"
              : data.responsiblePerson
          }}
        </td>
      </tr>
      <tr>
        <td class="tbl-icons"><mat-icon>work_history</mat-icon></td>        
        <td class="tbl-content" [ngStyle]="{'font-style': !event.processedInBackend ? 'italic' : 'normal'}">{{ !event.processedInBackend ? 'Die Arbeitszeit wurde noch nicht gutgeschrieben' : "Deine geleisteten Arbeitsstunden wurden gutgeschrieben"}} </td>
      </tr>
    </table>
  </mat-card>
</div>
