import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FirebaseConfig } from './shared/shared.js';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { UniversalDialog } from './universal-dialog/universal-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatRadioModule } from '@angular/material/radio';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EventEmitterService } from './event-emitter.service';
import { WorkingHourAdminComponent } from './working-hour-admin/working-hour-admin.component';
import { ImageUploadModule } from 'angular2-image-upload';
import { IdCardComponent } from './id-card/id-card.component';
import { PointAccountsAdminComponent } from './point-accounts-admin/point-accounts-admin.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { AngularFireModule } from '@angular/fire';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastModule } from 'ng-uikit-pro-standard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SettingsComponent } from './settings/settings.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QRCodeModule } from 'angular2-qrcode';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { WorkHourEntryActionPipe } from './pipes/work-hour-entry-action.pipe';
import { WorkHourEntryReasonPipe } from './pipes/work-hour-entry-reason.pipe';
import { WorkHourEntryDialog } from './dialogs/work-hour-entry/work-hour-entry';
import { EncPointEntryActionPipe } from './pipes/enc-point-entry-action.pipe';
import { EncPointEntryReasonPipe } from './pipes/enc-point-entry-reason.pipe';
import { EncouragementPointEntryDialog } from './dialogs/encouragement-point-entry/encouragement-point-entry';
import { LogService } from './services/log.service';
import { LogPublisherService } from './services/log-publisher.service';
import { MatCardModule } from '@angular/material/card';
import { NotiBottomSheetComponent } from './noti-bottom-sheet/noti-bottom-sheet.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PointSendComponent } from './point-send/point-send.component';
import { PointReceiveComponent } from './point-receive/point-receive.component';
import { WaitingBottomSheetComponent } from './waiting-bottom-sheet/waiting-bottom-sheet.component';
import { ProtocolViewComponent } from './protocol-view/protocol-view.component';
import { OneTimeAccessCodeDialog } from './dialogs/one-time-access-code/one-time-access-code';
import { CodeInputModule } from 'angular-code-input';
import { CustomInstallPromptService } from './services/custom-install-prompt.service';
import { PromptComponent } from './prompt-component/prompt-component.component';
import { EventsAdminComponent } from './events-admin/events-admin.component.js';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddEventComponent } from './events-admin/dialogs/add-event/add-event.component';
import { AddWpComponent } from './events-admin/dialogs/add-wp/add-wp/add-wp.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import {
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
} from 'ng-pick-datetime';
import { AddParticipantsComponent } from './events-admin/dialogs/add-participants/add-participants.component';
import { EventViewComponent } from './events-admin/components/event-view/event-view.component';
import { WpViewComponent } from './events-admin/components/wp-view/wp-view.component';
import { ParticipantsViewComponent } from './events-admin/components/participants-view/participants-view.component';
import { UpdateAvailableComponent } from './dialogs/update-available/update-available.component';
import { EventsComponent } from './events/events.component';
import { TimeRangePickerComponent } from './small-components/time-range-picker/time-range-picker.component.js';
import { GroupedWorkPackagesComponent } from './events-admin/components/grouped-work-packages/grouped-work-packages.component.js';
import { ShiftAbortComponent } from './dialogs/shift-abort/shift-abort.component';
import { WpKindPipe } from './pipes/wp-kind.pipe';
import { DbSyncUploadComponent } from './db-sync-upload/db-sync-upload.component';
import { ThreadSafeService } from './services/thread-safe-component-state.service.js';
import { CalendarComponent } from './calendar/calendar/calendar.component';
import { CalendarEventComponent } from './calendar/calendar-event/calendar-event.component';
import { EventDialogComponent } from './calendar/event-dialog/event-dialog.component';
import { UserRightsManagementService } from './services/user-rights-management.service.js';
import { MessagingService } from './services/messaging.service.js';
import { QrCodeDialog } from './dialogs/qr-code/qr-code.component.js';
import { CamScanDialog } from './dialogs/cam-scan/cam-scan-dialog/cam-scan-dialog.component';
import { ReportWpViewComponent } from './events-admin/components/report-wp-view/report-wp-view.component';
import { WpViewButtonComponent } from './events-admin/components/wp-view-button/wp-view-button.component';

const initializer = (pwaService: CustomInstallPromptService) => () =>
  pwaService.initPwaPrompt();

// Texts for OwlDateTimePicker
export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Abbrechen';
  setBtnLabel = 'Setzen';
  rangeFromLabel = 'Von';
  rangeToLabel = 'Bis';
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UniversalDialog,
    OneTimeAccessCodeDialog,
    WorkHourEntryDialog,
    EncouragementPointEntryDialog,
    WorkingHourAdminComponent,
    IdCardComponent,
    PointAccountsAdminComponent,
    WorkingHoursComponent,
    SettingsComponent,
    BottomSheetComponent,
    WorkHourEntryActionPipe,
    WorkHourEntryReasonPipe,
    EncPointEntryActionPipe,
    EncPointEntryReasonPipe,
    NotiBottomSheetComponent,
    StatisticsComponent,
    PointSendComponent,
    PointReceiveComponent,
    WaitingBottomSheetComponent,
    ProtocolViewComponent,
    PromptComponent,
    EventsAdminComponent,
    AddEventComponent,
    AddWpComponent,
    AddParticipantsComponent,
    EventViewComponent,
    WpViewComponent,
    ParticipantsViewComponent,
    UpdateAvailableComponent,
    EventsComponent,
    TimeRangePickerComponent,
    GroupedWorkPackagesComponent,
    ShiftAbortComponent,
    WpKindPipe,
    DbSyncUploadComponent,
    CalendarComponent,
    CalendarEventComponent,
    EventDialogComponent,
    QrCodeDialog,
    CamScanDialog,
    ReportWpViewComponent,
    WpViewButtonComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    MatDialogModule,
    AppRoutingModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    QRCodeModule,
    ZXingScannerModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    MatSortModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatRadioModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaterialTimepickerModule,
    MatRippleModule,
    CodeInputModule,
    MatBottomSheetModule,
    ImageUploadModule.forRoot(),
    ToastModule.forRoot({ maxOpened: 2, preventDuplicates: true }),
    NgCircleProgressModule.forRoot({}),
    AngularFireModule.initializeApp(FirebaseConfig()),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgbModule,
    NgxMatTimepickerModule,
  ],
  providers: [
    EventEmitterService,
    LogService,
    LogPublisherService,
    ThreadSafeService,
    MessagingService,
    UserRightsManagementService,
    WpKindPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [CustomInstallPromptService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'de' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UniversalDialog,
    WorkHourEntryDialog,
    EncouragementPointEntryDialog,
    OneTimeAccessCodeDialog,
    AddEventComponent,
    AddWpComponent,
    QrCodeDialog,
    CamScanDialog
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
