import { Injectable } from '@angular/core';
import {
  AccountingYear,
  Sub_EncouragementPoints,
  PointTransfer,
  ProtocolEncouragementPoints,
  ProtocolWorkHours,
  Users,
  Sub_WorkHours,
  Properties,
  TransferState,
  Event,
  Sub_WorkPackage,
  Sub_Participant,
  MethodReturn,
  IParticipant,
  WorkPackageKind,
  Groups,
  Sub_UserRights,
  User,
  Group,
  Calendars,
  Calendar,
  CalendarEvent,
  CalendarEventParticipant,
  Right
} from './definitions.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { LogService } from './log.service';
import { DatabaseService } from './database-service.service';

export enum WorkHourEntryAction {
  UNDEFINED = 0,
  ADD = 1,
  REMOVE = 2,
}

export enum WorkHourEntryReason {
  UNDEFINED = 0,
  STANDARD = 1,
  DELETED_ALL_FOR_YEAR_TRANSFER = 10, //on every change of a year, all work hours will be cleared
  EVENT_POST_PROCESSING_WH_CREDIT = 11,
  FIX_SUM_WORK_HOURS = 12,
}

export enum EncouragementPointEntryAction {
  UNDEFINED = 0,
  ADD = 1,
  REMOVE = 2,
}

export enum EncouragementPointReason {
  UNDEFINED = 0,
  STANDARD = 1,
  POINT_TRANSFER = 2,
  TRANSFER_FROM_WORK_HOUR_BY_YEAR_END = 11,
  FIX_SUM_ENC_POINTS = 12,
}

@Injectable({
  providedIn: 'root',
})
export class DataModelService {
  public list: any[];

  constructor(
    private log: LogService,
    private db: DatabaseService
  ) { }

  /**
   * Erstellt einen neuen Arbeitsstunden-Eintrag bei einem user.
   * Dabei wird auch ein Protokoll-Eintrag geschrieben.
   *
   * @returns true if successful, false if not
   */
  public async createWorkHourEntry(
    destinationDocumentId: string,
    amount: number,
    action: WorkHourEntryAction,
    comment: string = '',
    reason: WorkHourEntryReason = WorkHourEntryReason.STANDARD,
    actionDoneBy: string,
    entryCreatorName: string
  ) {
    if (!destinationDocumentId) {
      this.log.error('Keine user document id angegeben', DataModelService.name);
      return false;
    }

    try {
      var data = await this.loadCurrentAccountingYear();
      var accountingYearDocId = data.id;
    } catch (error) {
      this.log.error(
        'Konnte Daten des Abrechnungsjahr nicht laden',
        error,
        DataModelService.name
      );
      return false;
    }

    //create work hour entry
    var whId = '';
    var creationTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      await this.createWorkHourEntryHelper(
        destinationDocumentId,
        +amount,
        action,
        comment,
        reason,
        accountingYearDocId,
        creationTimestamp,
        actionDoneBy,
        entryCreatorName
      );
    } catch (error) {
      this.log.error(
        'Konnte WorkHour-entry nicht anlegen',
        error,
        DataModelService.name
      );
      return false;
    }

    //get newly created workHour entry id
    try {
      var docId = await this.getUserSubcollectionDocByTimestamp(
        destinationDocumentId,
        creationTimestamp,
        Sub_WorkHours()
      );
      whId = docId;
    } catch (error) {
      this.log.error(
        'Konnte WorkHour-entry nicht anlegen',
        error,
        DataModelService.name
      );
      return false;
    }

    if (whId == undefined) {
      this.log.error(
        'WorkHour-entry document id ist undefiniert',
        DataModelService.name
      );
      return false;
    }

    var logData = {
      destinationDocumentId: destinationDocumentId,
      amount: amount,
      action: action,
      comment: comment,
      reason: reason,
      actionDoneBy: actionDoneBy,
      entryCreatorName: entryCreatorName,
      idAccountingYearSetting: accountingYearDocId,
      idWorkHour: whId,
    };

    //create log entry
    this.db.createLogEntry(logData, actionDoneBy, "createWorkHourEntry")
    return true;
  }

  /**
   * Creates a new entry in the sub-collection 'workHour'.
   *
   * @returns a promise for this transaction
   */
  private async createWorkHourEntryHelper(
    userDocumentid: string,
    amount: number,
    action: WorkHourEntryAction,
    comment: string = '',
    reason: WorkHourEntryReason = WorkHourEntryReason.STANDARD,
    accountingYearSettingId: string,
    creationTimestamp: firebase.firestore.Timestamp,
    actionDoneBy: string,
    entryCreatorName: string
  ) {
    var data = {
      accountingYearSetting: accountingYearSettingId,
      action: action,
      actionComment: comment,
      actionDoneBy: actionDoneBy,
      actionDoneByName: entryCreatorName,
      amount: amount,
      reason: reason,
      timestamp: creationTimestamp,
    };

    var workHoursCollRef = this.db.getFirestore
      .collection(Users())
      .doc(userDocumentid)
      .collection(Sub_WorkHours())
      .doc();
    var userRef = this.db.getFirestore
      .collection(Users())
      .doc(userDocumentid);

    return this.db.getFirestore.runTransaction((transaction) => {
      return transaction.get(userRef).then((res) => {
        if (!res.exists) {
          throw 'User does not exist!';
        }

        // berechne Gesamtzahl
        var sumWorkHours = +res.data().sumWorkHours;
        var newSum = 0;
        if (sumWorkHours) {
          if (data.action == WorkHourEntryAction.ADD) {
            newSum = sumWorkHours + data.amount;
          } else {
            newSum = sumWorkHours - data.amount;
          }
        } else {
          newSum = data.amount;
        }

        // Commit to Firestore
        transaction.update(userRef, {
          sumWorkHours: newSum,
        });
        transaction.set(workHoursCollRef, data);
      });
    });
  }


  /**
   * Erstellt einen neuen Bonuspunkt-eintrag bei einem user.
   * dabei wird auch ein protokoll-Eintrag geschrieben.
   * 
   * @param userDocumentid - the user id (which is the document id as well)
   * @param amount - the amount of points
   * @param action - either add or substract the points
   * @param comment - a comment for th action
   * @param reason - a specific reason (see: EncouragementPointReason)
   * @param actionDoneById - the id of the user that initiated the creation of this points 
   * @param unlockTimestamp - a timestamp that defines when this points are unlocked
   * @param tags - additional tags for this entry
   * @param actionDoneByName - the pretty printed name of the user that created the entry 
   * @param receiverUserId - User id that receives points (only applicable if points are substracted (e.g. point transfer))
   * @param receiverUserName - User name that receives points (only applicable if points are substracted (e.g. point transfer))
   * @returns 
   */
  public async createEncouragementPointEntry(
    userDocumentid: string,
    amount: number,
    action: EncouragementPointEntryAction,
    comment: string = '',
    reason: EncouragementPointReason = EncouragementPointReason.STANDARD,
    actionDoneById: string,
    unlockTimestamp: firebase.firestore.Timestamp,
    tags: string[],
    actionDoneByName: string,
    receiverUserId: string = "<nicht vorhanden>",
    receiverUserName: string = "<nicht vorhanden>"
  ) {
    if (!userDocumentid) {
      this.log.error('Keine user document id angegeben', DataModelService.name);
      return false;
    }

    //create work hour entry
    var epId = '';
    var creationTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      await this.createEncouragementPointEntryHelper(
        userDocumentid,
        +amount,
        action,
        comment,
        reason,
        actionDoneById,
        unlockTimestamp,
        tags,
        actionDoneByName,
        receiverUserId,
        receiverUserName
      );
    } catch (error) {
      console.error(error);
      this.log.error('Konnte Förderpunkt-Eintrag nicht anlegen', error);
      return false;
    }

    //get newly created encouragement points entry id
    try {
      var docId = await this.getUserSubcollectionDocByTimestamp(
        userDocumentid,
        creationTimestamp,
        Sub_EncouragementPoints()
      );
      epId = docId;
    } catch (error) {
      this.log.error(
        'Konnte Förderpunkt-Eintrag nicht anlegen',
        error,
        DataModelService.name
      );
      return false;
    }

    if (epId == undefined) {
      this.log.error(
        'Förderpunkt-Eintrag id nicht angegeben',
        DataModelService.name
      );
      return false;
    }

    var logData = {
      userDocumentid: userDocumentid,
      amount: amount,
      action: action,
      comment: comment,
      reason: reason,
      actionDoneById: actionDoneById,
      unlockTimestamp: unlockTimestamp,
      tags: tags,
      actionDoneByName: actionDoneByName,
      receiverUserId: receiverUserId,
      receiverUserName: receiverUserName,
      idEncouragementPoint: epId
    };

    //create log entry
    this.db.createLogEntry(logData, actionDoneById, "createEncouragementPointEntry")
    return true;
  }


  /**
   * Erstellt einen neuen Bonuspunkt-eintrag bei einem user.
   * dabei wird auch ein protokoll-Eintrag geschrieben.
   * 
   * @param userDocumentid - the user id (which is the document id as well)
   * @param amount - the amount of points
   * @param action - either add or substract the points
   * @param comment - a comment for th action
   * @param reason - a specific reason (see: EncouragementPointReason)
   * @param actionDoneById - the id of the user that initiated the creation of this points 
   * @param unlockTimestamp - a timestamp that defines when this points are unlocked
   * @param tags - additional tags for this entry
   * @param actionDoneByName - the pretty printed name of the user that created the entry 
   * @param receiverUserId - User id that receives points (only applicable if points are substracted (e.g. point transfer))
   * @param receiverUserName - User name that receives points (only applicable if points are substracted (e.g. point transfer))
   * @returns 
   */
  private async createEncouragementPointEntryHelper(
    userDocumentid: string,
    amount: number,
    action: EncouragementPointEntryAction,
    comment: string = '',
    reason: EncouragementPointReason = EncouragementPointReason.STANDARD,
    actionDoneById: string,
    unlockTimestamp: firebase.firestore.Timestamp,
    tags: string[],
    actionDoneByName: string,
    receiverUserId: string = "<nicht vorhanden>",
    receiverUserName: string = "<nicht vorhanden>"
  ) {
    var creationTimestamp = firebase.firestore.Timestamp.fromDate(new Date());

    //Note: receiver only applicable when points are reduced (e.g. point transfer)
    var data = {
      action: action,
      actionComment: comment,
      actionDoneBy: actionDoneById,
      actionDoneByName: actionDoneByName,
      amount: amount,
      reason: reason,
      timestamp: creationTimestamp,
      unlockedAt: unlockTimestamp,
      tags: tags,
      receiverUserId: receiverUserId,
      receiverUserName: receiverUserName
    };

    var encouragementPointsCollRef = this.db.getFirestore
      .collection(Users())
      .doc(userDocumentid)
      .collection(Sub_EncouragementPoints())
      .doc();
    var userRef = this.db.getFirestore
      .collection(Users())
      .doc(userDocumentid);

    return this.db.getFirestore.runTransaction((transaction) => {
      return transaction.get(userRef).then((res) => {
        if (!res.exists) {
          throw 'User does not exist!';
        }

        // berechne Gesamtzahl
        var sumEncouragementPoints = +res.data().sumEncouragementPoints;
        var newSum = 0;
        if (sumEncouragementPoints) {
          if (data.action == EncouragementPointEntryAction.ADD) {
            newSum = sumEncouragementPoints + data.amount;
          } else {
            newSum = sumEncouragementPoints - data.amount;
          }
        } else {
          newSum = data.amount;
        }

        // Commit to Firestore
        transaction.update(userRef, {
          sumEncouragementPoints: newSum,
        });
        transaction.set(encouragementPointsCollRef, data);
      });
    });
  }

  /**
   * Updates the state of a point transfer entry.
   *
   * @returns a promise for this operation.
   */
  public updateTransferEntryState(id: string, state: TransferState) {
    return new Promise<boolean>((resolve, reject) => {
      var ref = this.db.getAngularFirestore.collection(PointTransfer()).doc(id).ref;
      var data = {
        state: state,
      };
      ref
        .update(data)
        .then(() => {
          this.log.debug(
            'PointTransfer-document successfully updated on db',
            id,
            data,
            DataModelService.name
          );
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Updates the data of a user.
   *
   * @returns a promise for this operation.
   */
  public updateUser(uid: string, data: any, log: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      var ref = this.db.getAngularFirestore.collection(Users()).doc(uid).ref;
      ref
        .update(data)
        .then(() => {
          this.log.debug(
            'User-document successfully saved on db',
            uid,
            data,
            DataModelService.name
          );

          if (log) {
            var logData = {
              data: data,
              uid: uid,
            };

            //create log entry
            this.db.createLogEntry(logData, "Unknown", "updateUser");
          }
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Gets a subcollection document of a user.
   *
   * @returns a promise for this operation.
   */
  public getUserSubcollectionDocByTimestamp(
    uid: string,
    timestamp: firebase.firestore.Timestamp,
    subCollectionName: string
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var ref = this.db.getAngularFirestore
        .collection(Users())
        .doc(uid)
        .collection(subCollectionName).ref;

      // Create a query against the collection.
      var query = ref.where('timestamp', '==', timestamp);

      return query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            resolve(doc.id);
          });
        })
        .catch((error) => {
          var msg =
            'Error getting ' + subCollectionName + ' work hour data: ' + error;
          reject(msg);
        });
    });
  }

  // ------------To be checked -----------------------------

  //Delete all docs of a sub collection
  private async deleteSubCollectionData(path: string): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };
    var loaded = await this.db.get(path);
    if (loaded.result) {
      var subs = loaded.data;

      for (let index = 0; index < subs.length; index++) {
        const element = subs[index];
        var p = element[Properties.Path];
        var deleted = await this.db.delete(p, false);
        if (!deleted.result) {
          return deleted;
        }
      }
      ret.result = true;
    }
    return ret;
  }

  // ----------------------------------------------------- Done


  /**
   * creates a new entry in the point transfer collection
   *
   * @returns true if successful, false if not.
   */
  public async createPointTransferEntry(
    senderUserId: string,
    senderUserName: string,
    receiverUserId: string,
    receiverUserName: string,
    amountPoints: number,
    comment: string,
    state: string
  ) {
    if (
      !senderUserId ||
      !senderUserName ||
      !receiverUserId ||
      !receiverUserName ||
      !amountPoints ||
      !comment ||
      !state
    ) {
      this.log.error('Ungültige Daten angegeben', DataModelService.name);
      return false;
    }
    var ts = firebase.firestore.Timestamp.fromDate(new Date());
    var data = {
      senderUserId: senderUserId,
      senderUserName: senderUserName,
      receiverUserId: receiverUserId,
      receiverUserName: receiverUserName,
      amountPoints: +amountPoints,
      comment: comment,
      state: state,
      timestamp: ts,
    };
    var res = await this.db.add(data, PointTransfer());
    if (!res.result) {
      this.log.error(
        'Konnte Punkte-Transfer daten nicht speichern.',
        DataModelService.name
      );
      return false;
    } else {
      return res.id;
    }
  }

  /**
   * Loads the data of a collection
   *
   * @returns a promise for this operation.
   */
  public loadAllCollectionsData(
    collectionName: string,
    constraintKey: string = null,
    constraintValue: string = null
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var ret = await this.db.get(
        collectionName,
        true,
        constraintKey,
        constraintValue
      );
      if (ret.result) {
        resolve(ret.data);
      } else {
        reject(ret.errorMessage);
      }
    });
  }

  /**
   * Loads a collection item by a given id.
   *
   * @returns a promise for this operation
   */
  public async getCollectionItemById(
    collectionName: string,
    id: string
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var path = `${collectionName}/${id}`;
      var ret = await this.db.get(path);
      if (ret.result) {
        resolve(ret.data);
      } else {
        reject(ret.errorMessage);
      }
    });
  }

  public async getByPath(path: string): Promise<MethodReturn> {
    return await this.db.getDocDirect(path);
  }

  /**
   * Loads a user by its email.
   *
   * @returns a promise for this operation.
   */
  public async loadUserByEmail(email: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var data = await this.db.get(Users(), true, Properties.Email, email);
      if (data.result) {
        if (data?.data?.length == 0) {
          reject('Benutzer nicht vorhanden');
        } else {
          if (data?.data.length > 1) {
            reject('Mehrere Nutzer mit dieser E-Mail registriert');
          }
          var ret = data?.data[0];
          resolve(ret);
        }
      } else {
        reject(undefined);
      }
    });
  }

  /**
   * Checks if an entry is already existing
   *
   * @param collectionPath
   * @param userId
   * @returns the object if existing, undefined if not.
   */
  public async entryExisting(
    collectionPath: string,
    userId: string
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var data = await this.db.get(
        collectionPath,
        true,
        Properties.UserId,
        userId
      );
      if (data.result) {
        var ret = data?.data[0];
        resolve(ret);
      } else {
        reject(undefined);
      }
    });
  }

  /**
   * Gets any specified direct subcollection of a user.
   *
   * @returns a promise for this operation.
   */
  public async getUserSubcollectionDoc(
    uid: string,
    subCollectionName: string
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var path = `${Users()}/${uid}/${subCollectionName}`;
      var ret = await this.db.get(path);
      if (ret.result) {
        resolve(ret.data);
      } else {
        reject(ret.errorMessage);
      }
    });
  }

  /**
   * Loads all users data.
   *
   * @returns a promise for this operation.
   */
  public async loadAllUsers(
    delaySpinner: boolean = false,
    hideSpinner: boolean = false
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      var ret = await this.db.get(
        Users(),
        false,
        '',
        '',
        delaySpinner,
        hideSpinner
      );
      if (ret.result) {
        resolve(ret.data);
      } else {
        reject(ret.errorMessage);
      }
    });
  }

  /**
   * Loads the data of a user
   *
   * @returns a promise for this operation.
   */
  public async loadUser(
    uid: string,
    forceFromProduction: boolean = false,
    delaySpinner: boolean = false,
    hideSpinner: boolean = false,
    delayTime: number = 1000
  ): Promise<any> {
    var collection = Users();
    if (forceFromProduction) {
      collection = 'users';
    }
    return new Promise<any>(async (resolve, reject) => {
      var ret = await this.db.getDoc(
        collection,
        uid,
        delaySpinner,
        hideSpinner,
        delayTime
      );
      if (ret.result) {
        resolve(ret.data);
      } else {
        reject(ret.errorMessage);
      }
    });
  }

  /**
   * Loads the currently active accounting year.
   *
   * @returns a promise for this operation.
   */
  public loadCurrentAccountingYear(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      // Create a reference to the cities collection
      var ref = this.db.getAngularFirestore.collection(AccountingYear()).ref;
      var currentTime = firebase.firestore.Timestamp.fromDate(new Date());
      // Create a query against the collection.
      var query = ref.where('end', '>=', currentTime);

      query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var accountingYearData: any = doc.data();
            var endTime: firebase.firestore.Timestamp = accountingYearData.end;
            var showWarning: boolean = false;

            //check if currentTime is below end time
            if (currentTime > endTime) {
              showWarning = true;
            }

            var data = {
              data: accountingYearData,
              id: doc.id,
              showWarning: showWarning,
            };
            resolve(data);
          });
          reject('Kein Eintrag gefunden der den aktuellen Tag beinhaltet');
        })
        .catch((error) => {
          this.log.error(
            'Error getting current AccountingYearSetting',
            error,
            DataModelService.name
          );
          reject(error);
        });
    });
  }

  /**
   * Deletes a work hour entry from a user.
   *
   * @returns a promise for this operation.
   */
  public async deleteWorkHourEntryDocument(
    userDocumentId: string,
    docRef: string,
    userFirstName: string,
    userLastName: string,
    elementData: any
  ): Promise<MethodReturn> {
    var one = `${Users()}/${userDocumentId}/${Sub_WorkHours()}`;
    return this.deleteDocAndWriteProtEntry(
      one,
      docRef,
      ProtocolWorkHours(),
      userDocumentId,
      userFirstName,
      userLastName,
      elementData
    );
  }

  /**
   * Deletes a encouragement point entry from a user
   *
   * @returns a promise for this operation.
   */
  public deleteEncouragementPointEntryDocument(
    userDocumentId: string,
    docRef: string,
    userFirstName: string,
    userLastName: string,
    elementData: any
  ): Promise<any> {
    var path = `${Users()}/${userDocumentId}/${Sub_EncouragementPoints()}`;
    return this.deleteDocAndWriteProtEntry(
      path,
      docRef,
      ProtocolEncouragementPoints(),
      userDocumentId,
      userFirstName,
      userLastName,
      elementData
    );
  }

  /**
   * Deletes the document and writes an according protocol entry.
   *
   * @param collectionPath The path to the collection (e.g.: users/<id>/workHours)
   * @param entryId The id of the document
   * @param protocolCollectionName The name of the according protocol collection
   * @param userId The id of the user who has deleted the entry
   * @returns A promise of a method return.
   */
  public async deleteDocAndWriteProtEntry(
    collectionPath: string,
    entryId: string,
    protocolCollectionName: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    elementData: any
  ): Promise<MethodReturn> {
    //----------- find entry
    var command = await this.db.getDoc(collectionPath, entryId);
    if (!command.result) return command;

    //----------- delete entry
    var path = `${collectionPath}/${entryId}`;
    command = await this.db.delete(path, false);
    if (!command.result) {
      return command;
    }

    //---------- create protocol log entry
    var logData = {
      collectionPath: collectionPath,
      entryId: entryId,
      protocolCollectionName: protocolCollectionName,
      userId: userId,
      userFirstName: userFirstName,
      userLastName: userLastName,
      elementData: elementData
    };

    //create log entry
    this.db.createLogEntry(logData, "Unknown", "deleteDocAndWriteProtEntry");
    return command;
  }

  /**
   * Creates a new event.
   *
   * @returns The id of the event or undefined if rejected or error occurred.
   */
  public async setEvent(
    idAccountingYear: string,
    name: string,
    description: string,
    locationName: string,
    houseNumber: string,
    street: string,
    city: string,
    plz: number,
    responsiblePerson: string = '',
    responsiblePersonUid: string = '',
    start: Date,
    end: Date,
    eventId: string = '',
    update: boolean = false
  ): Promise<MethodReturn> {
    var ret: MethodReturn = { result: false };

    var data = {
      idAccountingYear: idAccountingYear,
      name: name,
      locationName: locationName,
      description: description,
      houseNumber: houseNumber,
      street: street,
      plz: plz,
      city: city,
      responsiblePerson: responsiblePerson,
      responsiblePersonId: responsiblePersonUid,
      start: firebase.firestore.Timestamp.fromDate(start),
      end: firebase.firestore.Timestamp.fromDate(end),
      releasedForUsers: false,
      processedInBackend: false,
      numericCode: 0,
    };

    //check date ranges
    if (start > end) {
      var msg = 'Das Start-Datum muss vor dem End-Datum liegen';
      this.log.error(msg, DataModelService.name);
      ret.errorMessage = msg;
      return ret;
    }
    var set: MethodReturn;
    if (update) {
      var set = await this.db.update(data, Event(), eventId);
    } else {
      var set = await this.db.add(data, Event());

      //set numeric code
      if (set.result) {
        var codeSet = await this.setEventNumericCode(set.id);
        if (codeSet.result) {
          return set;
        } else {
          ret.errorMessage = 'Konnte event code nicht setzen';
          return ret;
        }
      }
    }
    return set;
  }

  public async setEventProcessedInBackend(
    eventId: string,
    processed: boolean
  ): Promise<MethodReturn> {
    var data = {
      processedInBackend: processed,
    };
    return await this.db.update(data, Event(), eventId);
  }

  public async setEventReleasedForUsers(
    eventId: string,
    released: boolean
  ): Promise<MethodReturn> {
    var data = {
      releasedForUsers: released,
    };
    return await this.db.update(data, Event(), eventId);
  }

  public async setWpReleasedForUsers(
    docPath: string,
    released: boolean
  ): Promise<MethodReturn> {
    var data = {
      releasedForUsers: released,
    };
    return await this.db.updateDirect(data, docPath);
  }

  //Sets the presence state of a participant
  public async setPresenceForEventParticipant(
    docId: string,
    present: boolean
  ): Promise<MethodReturn> {
    var data = {
      present: present,
    };
    return await this.db.updateDirect(data, docId);
  }

  //Sets the numeric code of an event
  private async setEventNumericCode(eventId: string): Promise<MethodReturn> {
    var hash = Math.abs(+this.generateHashCode(eventId));
    var data = {
      numericCode: hash,
    };
    return await this.db.update(data, Event(), eventId);
  }

  //Updates an event with the given data
  public async updateEvent(eventId: string, data: any): Promise<MethodReturn> {
    return await this.db.update(data, Event(), eventId);
  }

  /**
   * Adds a new work package to an existing event.
   *
   * @returns the id of the workPackage or undefined if rejected or error occurred.
   */
  public async setWorkPackage(
    collectionPath: string,
    type: string,
    minParticipants: number,
    start: Date,
    end: Date,
    responsiblePersonName: string = '',
    responsiblePersonUid: string = '',
    wpId: string = '',
    kind: WorkPackageKind = WorkPackageKind.Standard,
    update: boolean = false,
    releasedForUsers: boolean = false,
    isUe18: boolean = true,
    skipShiftCollision: boolean = false
  ): Promise<MethodReturn> {
    var collPath = `${collectionPath}/${Sub_WorkPackage()}`;
    if (update) {
      collPath = collectionPath;
    }

    var data = {
      type: type,
      minParticipants: minParticipants,
      responsiblePerson: responsiblePersonName,
      responsiblePersonUid: responsiblePersonUid,
      start: firebase.firestore.Timestamp.fromDate(start),
      end: firebase.firestore.Timestamp.fromDate(end),
      kind: kind,
      releasedForUsers: releasedForUsers,
      isUe18: isUe18,
      skipShiftCollision: skipShiftCollision,
    };

    var set: MethodReturn;
    if (update) {
      var set = await this.db.update(data, collPath, wpId);
    } else {
      var set = await this.db.add(data, collPath);
    }
    return set;
  }

  //Marks a work packages as started or stopped. This is used for special work packages like 'Aufbau' and 'Abbau'.
  //Sets the defined start/end time to current time.
  public async markWorkPackageStarted(
    collectionPath: string,
    start: boolean
  ): Promise<MethodReturn> {
    var data = {};
    var now = new Date();

    if (start) {
      // //set end 4 hours later
      // var later = new Date(now);
      // later.setHours(now.getHours() + 4);
      data = {
        definedStart: firebase.firestore.Timestamp.fromDate(now),
        // end: firebase.firestore.Timestamp.fromDate(later),
        running: true,
      };
    } else {
      data = {
        definedEnd: firebase.firestore.Timestamp.fromDate(now),
        running: false,
      };
    }
    return await this.db.updateDirect(data, collectionPath);
  }

  //Add a single participant
  public async addParticipant(
    collectionPathWorkPackage: string,
    data: IParticipant
  ): Promise<MethodReturn> {
    data[Properties.RegistrationTime] = firebase.firestore.Timestamp.fromDate(new Date());
    var path = `${collectionPathWorkPackage}/${Sub_Participant()}`;
    return await this.db.add(data, path);
  }

  /**
   * Adds the whole list of participants to a work package
   *
   * @param collectionPath path to the according work package
   * @param data participant data list to add
   * @returns a method return object
   */
  public async setParticipants(
    collectionPath: string,
    data: any[]
  ): Promise<MethodReturn> {
    // delete all participants
    var deleted = await this.deleteParticipants(collectionPath);
    if (!deleted.result) {
      return deleted;
    }
    data.forEach(element => {
      element[Properties.RegistrationTime] = firebase.firestore.Timestamp.fromDate(new Date());
    });
    var path = `${collectionPath}/${Sub_Participant()}`;
    return await this.db.batchAdd(data, path);
  }

  //Update a participant
  public async updateParticipant(data: IParticipant): Promise<MethodReturn> {
    return await this.db.update(data, data.parent, data.id);
  }

  //Delete all participants of an work package
  public async deleteParticipants(pathWP: string): Promise<MethodReturn> {
    var path = `${pathWP}/${Sub_Participant()}`;
    return this.deleteSubCollectionData(path);
  }

  //Deletes all work packages of an event
  public async deleteWorkPackages(pathEvent: string): Promise<MethodReturn> {
    var path = `${pathEvent}/${Sub_WorkPackage()}`;
    return this.deleteSubCollectionData(path);
  }

  public async deleteGeneric(path: string, log: boolean): Promise<MethodReturn> {
    var ret = await this.db.delete(path, log);
    return ret;
  }

  public async get(
    collectionName: string,
    delaySpinner: boolean = false,
    hideSpinner: boolean = false,
    useDocumentUidAsId: boolean = true,
    clearSpinnerAtEnd: boolean = true
  ): Promise<MethodReturn> {
    return await this.db.get(
      collectionName,
      false,
      '',
      '',
      delaySpinner,
      hideSpinner, useDocumentUidAsId,
      clearSpinnerAtEnd
    );
  }

  public async getDoc(
    collectionName: string,
    docId: string,
    delaySpinner: boolean = false,
    hideSpinner: boolean = false,
    delayTime: number = 1000
    , clearSpinnerAtEnd: boolean = true
  ): Promise<MethodReturn> {
    return await this.db.getDoc(
      collectionName,
      docId,
      delaySpinner,
      hideSpinner,
      delayTime, clearSpinnerAtEnd
    );
  }

  /**
   * Add notification token.
   *
   * @returns a promise for this operation.
   */
  public async addNotificationToken(
    userDocumentid: string,
    token: string
  ): Promise<MethodReturn> {
    var data = {
      notificationIds: firebase.firestore.FieldValue.arrayUnion(token),
    };
    return await this.db.update(data, Users(), userDocumentid);
  }

  //Generates a hash code from a given string
  private generateHashCode(value: string) {
    var hash = 0,
      i,
      chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  public async getUserSpecificRights(id: string, hideSpinner: boolean = false): Promise<MethodReturn> {
    var path = Users() + '/' + id + '/' + Sub_UserRights();
    return await this.get(path, false, hideSpinner, false);
  }

  public async getUser(id: string, hideSpinner: boolean = false, clearSpinnerAtEnd: boolean = true): Promise<MethodReturn> {
    return await this.getDoc(Users(), id, false, hideSpinner, 1000, clearSpinnerAtEnd);
  }

  public async getGroups(useDocumentUidAsId: boolean = true, hideSpinner: boolean = false, clearSpinnerAtEnd: boolean = true): Promise<MethodReturn> {
    return await this.get(Groups(), false, hideSpinner, useDocumentUidAsId, clearSpinnerAtEnd);
  }

  public async getGroupRights(groupId: number, hideSpinner: boolean = false) {
    var path = Groups() + '/' + groupId.toString() + '/' + Sub_UserRights();
    return await this.get(path, false, hideSpinner);
  }


  // --------------- Calendars ------------------------------

  /**
   * Adds a new calendar entry to the database.
   * @param calendarId - The ID of the calendar.
   * @param data - The data to be added to the calendar.
   * @returns A promise that resolves with the method return.
   */
  public async addCalendar(calendarId: number, name: string): Promise<MethodReturn> {
    //check if calendar already exists
    var loaded = await this.getCalendar(calendarId);
    if (loaded.result && loaded.data) {
      return { result: false, errorMessage: 'Kalender existiert bereits' };
    }
    var data = {};
    data[Properties.CalendarID] = calendarId
    data[Properties.Name] = name
    data[Properties.Created] = firebase.firestore.Timestamp.fromDate(new Date());
    return await this.db.add(data, Calendars());
  }

  /**
   *  Updates an existing calendar entry in the database.
   *  @param calendarId - The ID of the calendar.
   *  @param data - The data to update the calendar with.
   *  @returns A promise that resolves with the method return.
 */
  public async updateCalendar(calendarId: number, data: Calendar): Promise<MethodReturn> {
    //check if existing
    var res = await this.getCalendar(calendarId);
    if (!res.result) {
      return { result: false, errorMessage: 'Kalender existiert nicht' };
    }
    return await this.db.updateDirect(data, res.documentPath);
  }

  /**
   * Deletes a calendar entry from the database.
   * @param calendarId - The ID of the calendar.
   * @returns A promise that resolves with the method return.
   */
  public async deleteCalendar(calendarId: number): Promise<MethodReturn> {
    //check if existing
    var res = await this.getCalendar(calendarId);
    if (!res.result) {
      return { result: false, errorMessage: 'Kalender existiert nicht' };
    }
    return await this.db.delete(res.documentPath, true);
  }

  /**
   * Gets a calendar entry from the database.
   * @param docPath - The path of the document to be retrieved.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendars(): Promise<MethodReturn> {
    return await this.db.get(Calendars(), false, '', '');
  }

  /**
   * Gets a calendar entry from the database.
   * @param id - The ID of the calendar to be retrieved.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendar(id: number): Promise<MethodReturn> {
    var res = await this.db.get(Calendars(), true, Properties.CalendarID, id.toString());
    if (res.result && res.data?.length == 1) {
      return { result: true, data: res.data[0], documentPath: res.data[0].documentPath };
    }
    if (res.result && res.data?.length > 1) {
      return { result: false, errorMessage: `Multiple Kalender gefunden fuer die id: ${id}` };
    }
    if (res.result && res.data?.length == 0) {
      return { result: false, errorMessage: `Kalender mit id ${id} nicht gefunden` };
    }
    return { result: false, errorMessage: `Undefinierter Fehler aufgetreten.` };
  }

  /**
   * Adds a new event to the database.
   * @param calendarDocPath - The path of the calendar document.
   * @param eventId - The ID of the event.
   * @param data - The data to be added to the event.
   * @returns A promise that resolves with the method return.
   */
  public async addCalendarEvent(calendarDocPath: string, data: CalendarEvent): Promise<MethodReturn> {
    //check if already existing
    var res = await this.getCalendarEvent(calendarDocPath, data.eventId);
    if (res.result) {
      return { result: false, errorMessage: 'Event existiert bereits' };
    }
    return await this.db.add(data, calendarDocPath + '/' + Event());
  }

  /**
   * Updates an existing event in the database.
   * @param eventDocPath - The path of the event document.
   * @param data - The data to update the event with.
   * @returns A promise that resolves with the method return.
   */
  public async updateCalendarEvent(eventDocPath: string, data: CalendarEvent): Promise<MethodReturn> {
    //check if existing
    var res = await this.getByPath(eventDocPath);
    if (!res.result) {
      return { result: false, errorMessage: 'Kalender existiert nicht' };
    }
    return await this.db.updateDirect(data, eventDocPath);
  }

  /**
   * Deletes an event from the database.
   * @param eventPath - The path of the event document.
   * @returns A promise that resolves with the method return.
   */
  public async deleteCalendarEvent(eventDocPath: string): Promise<MethodReturn> {
    //check if existing
    var res = await this.getByPath(eventDocPath);
    if (!res.result) {
      return { result: false, errorMessage: 'Kalender existiert nicht' };
    }
    return await this.db.delete(eventDocPath, true);
  }

  /**
   * Gets all events of a calendar from the database.
   * @param eventPath - The path of the event document.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendarEvents(calendarPath: string): Promise<MethodReturn> {
    return await this.db.get(calendarPath + '/' + Event(), false, '', '');
  }

  /**
   * Gets an event from the database.
   * @param calendarPath - The path of the calendar document.
   * @param eventId - The ID of the event.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendarEvent(calendarPath: string, eventId: number): Promise<MethodReturn> {
    var res = await this.db.get(calendarPath + '/' + Event(), true, Properties.EventID, eventId.toString());
    if (res.result && res.data?.length == 1) {
      return { result: true, data: res.data[0], documentPath: res.data[0].documentPath };
    }
    if (res.result && res.data?.length > 1) {
      return { result: false, errorMessage: `Multiple Events gefunden fuer die id: ${eventId}` };
    }
    if (res.result && res.data?.length == 0) {
      return { result: false, errorMessage: `Event mit id ${eventId} nicht gefunden` };
    }
    return { result: false, errorMessage: `Undefinierter Fehler aufgetreten.` };
  }

  /**
   * Adds a new participant to an event in the database.
   * @param eventPath - The path of the event document.
   * @param data - The data to be added to the participant.
   * @returns A promise that resolves with the method return.
   */
  public async addCalendarEventParticipant(
    calendarEventPath: string,
    data: CalendarEventParticipant
  ): Promise<MethodReturn> {
    // check if already existing
    var loaded = await this.getCalendarEventParticipant(calendarEventPath, data.participantId);
    if (loaded.result) {
      return { result: false, errorMessage: 'Teilnehmer existiert bereits' };
    }
    return await this.db.add(data, calendarEventPath + '/' + Sub_Participant());
  }

  /**
   * Updates an existing participant in the database.
   * @param participantPath - The path of the participant document.
   * @param data - The data to update the participant with.
   * @returns A promise that resolves with the method return.
   */
  public async updateCalendarEventParticipant(
    participantPath: string,
    data: CalendarEventParticipant
  ): Promise<MethodReturn> {
    // check if already existing
    var loaded = await this.getByPath(participantPath);
    if (!loaded.result) {
      return { result: false, errorMessage: 'Teilnehmer existiert nicht' };
    }
    return await this.db.updateDirect(data, participantPath);
  }

  public async deleteCalendarEventParticipant(eventPath: string, participantId: number): Promise<MethodReturn> {
    //TODO: der einzige unterschied ist, das die delete methode direkt auf das doc und nicht auf die collection geht !!!!!!!!!!!!! Eventuell auf doc direkt verzichten
    var before = this.db.getFirestore;
    // check if already existing
    var loaded = await this.getCalendarEventParticipant(eventPath, participantId);
    if (!loaded.result) {
      return { result: false, errorMessage: 'Teilnehmer existiert nicht' };
    }
    var t = await this.db.delete(loaded.documentPath, true);
    var after = this.db.getFirestore;
    return t;
  }

  /**
   * Gets all participants of an event from the database.
   * @param eventPath - The path of the event document.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendarEventParticipants(eventPath: string): Promise<MethodReturn> {
    return await this.db.get(eventPath + '/' + Sub_Participant(), false, '', '');
  }

  /**
   * Gets a participant from the database.
   * @param eventPath - The path of the event document.
   * @param participantId - The ID of the participant.
   * @returns A promise that resolves with the method return.
   */
  public async getCalendarEventParticipant(eventPath: string, participantId: number): Promise<MethodReturn> {
    var res = await this.db.get(eventPath + '/' + Sub_Participant(), true, Properties.ParticipantID, participantId.toString());
    if (res.result && res.data?.length == 1) {
      return { result: true, data: res.data[0], documentPath: res.data[0].documentPath };
    }
    if (res.result && res.data?.length > 1) {
      return { result: false, errorMessage: `Multiple Teilnehmer gefunden fuer die id: ${participantId}` };
    }
    if (res.result && res.data?.length == 0) {
      return { result: false, errorMessage: `Teilnehmer mit id ${participantId} nicht gefunden` };
    }
    return { result: false, errorMessage: `Undefinierter Fehler aufgetreten.` };
  }

  // --------------------------------------------------------


  //-------------------------Testing--------------------------------------
  groups: Group[] = [];
  users: User[] = [];

  public setTestGroups(groups: Group[]) {
    //intentionally do nothing here
  }
  public setTestUsers(users: User[]) {
    //intentionally do nothing here
  }
}


