import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import {
  AddWpDialogData,
  User,
  WorkPackageKind,
} from 'src/app/services/definitions.service';

@Component({
  selector: 'app-add-wp',
  templateUrl: './add-wp.component.html',
  styleUrls: ['./add-wp.component.css'],
})
export class AddWpComponent implements OnInit {
  title: string = 'Schicht hinzufügen';
  buttonOk: string = 'Erstellen';
  modifying: boolean = false;
  userSelectorFormControl = new FormControl('');
  typeFormControl = new FormControl('', [Validators.required]);
  minParticipants = new FormControl(1, [Validators.required]);
  rangeFormControl = new FormControl('', [Validators.required]);
  kindFormControl = new FormControl(WorkPackageKind.Standard);
  ue18FormControl = new FormControl(true);
  skipShiftCollisionFormControl = new FormControl(false);

  myForm: FormGroup;
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  userData: any;
  dataLoaded: boolean;
  allUsersData: User[];
  options: User[];
  removePointsActive: boolean = false;
  filteredNameOptions: Observable<User[]>;
  wpKind = WorkPackageKind;
  wpKindEnum = [
    WorkPackageKind.Standard,
    WorkPackageKind.Construction,
    WorkPackageKind.Dismantling,
  ];
  currentSelectedWpKind: WorkPackageKind = WorkPackageKind.Standard;

  constructor(
    public dialogRef: MatDialogRef<AddWpDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: AddWpDialogData,
    private commonFunctions: CommonFunctionsService,
    private dataModel: DataModelService,
    fb: FormBuilder
  ) {
    this.allUsersData = data.allUserData;
    this.options = data.userOptions;
    this.filteredNameOptions = this.userSelectorFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.myForm = fb.group({
      type: this.typeFormControl,
      minParticipants: this.minParticipants,
      responsiblePerson: this.userSelectorFormControl,
      range: this.rangeFormControl,
      kind: this.kindFormControl,
      ue18: this.ue18FormControl,
      skipShiftCollision: this.skipShiftCollisionFormControl
    });

    // Set the minimum to now and the maximum to two years in the future
    var startEvent = this.data.eventData.start;
    var endEvent = this.data.eventData.end;
    this.minDate = new Date(startEvent.seconds * 1000);
    this.maxDate = new Date(endEvent.seconds * 1000);

    //if data is assigned, then set to form controls
    this.initModification(data);
  }

  ngOnInit(): void { }

  //Init the event modification
  private async initModification(data: AddWpDialogData) {
    if (data.id) {
      this.title = 'Schicht ändern';
      this.buttonOk = 'Ändern';
      this.modifying = true;

      //load user by id and set as value
      if (data.responsiblePersonId) {
        var foundUser = this.commonFunctions.findUser(
          data.allUserData,
          data.responsiblePersonId
        );
        if (foundUser) {
          this.myForm.controls['responsiblePerson'].setValue(foundUser);
        }
      }
      this.myForm.controls['type'].setValue(data.type);
      this.myForm.controls['minParticipants'].setValue(data.minParticipants);
      if (data.isUe18 != undefined)
        this.myForm.controls['ue18'].setValue(data.isUe18);
      if (!data.kind)
        this.myForm.controls['kind'].setValue(WorkPackageKind.Standard);
      else this.myForm.controls['kind'].setValue(data.kind);
      if (data.skipShiftCollisionCheck)
        this.myForm.controls['skipShiftCollision'].setValue(data.skipShiftCollisionCheck);

      //min max zeiten setzen
      var dateTime = [
        new Date(data.start.seconds * 1000),
        new Date(data.end.seconds * 1000),
      ];
      this.myForm.controls['range'].setValue(dateTime);
      if (data.kind) this.changeUiElementsOnKind(data.kind);
    }
  }

  //Raised when kind is changed
  public kindChanged(event: MatRadioChange) {
    this.changeUiElementsOnKind(event.value);
  }

  //Raised when kind is changed
  private changeUiElementsOnKind(wpKind: WorkPackageKind) {
    this.currentSelectedWpKind = wpKind;

    //set validator: required
    switch (this.currentSelectedWpKind) {
      case WorkPackageKind.Standard:
        //set 'range' as required
        this.myForm.get('range').setValidators(Validators.required);
        this.myForm.get('range').updateValueAndValidity();
        //set 'minParticipants' as required
        this.myForm.get('minParticipants').setValidators(Validators.required);
        this.myForm.get('minParticipants').updateValueAndValidity();
        //set 'skipShiftCollision' as enabled
        this.myForm.get('skipShiftCollision').clearValidators();
        this.myForm.get('skipShiftCollision').enable();
        this.myForm.get('skipShiftCollision').updateValueAndValidity();
        break;
      case WorkPackageKind.Construction:
      case WorkPackageKind.Dismantling:
        //set 'range' as not required
        this.myForm.get('range').clearValidators();
        this.myForm.get('range').updateValueAndValidity();
        //set 'minParticipants' as not required
        this.myForm.get('minParticipants').clearValidators();
        this.myForm.get('minParticipants').updateValueAndValidity();
        //set 'skipShiftCollision' as disabled
        this.myForm.get('skipShiftCollision').clearValidators();
        this.myForm.get('skipShiftCollision').disable();
        this.myForm.get('skipShiftCollision').updateValueAndValidity();
        break;
    }
  }

  //Adds a new event
  public async setWp() {
    var type = this.myForm.get('type').value;
    var minParticipants = this.myForm.get('minParticipants').value;
    var respPersonUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.myForm.get('responsiblePerson').value
    );
    var respPersonUserId = this.myForm.get('responsiblePerson').value.uid;
    if (!respPersonUserId) {
      respPersonUserId = '';
    }
    var dateTime = this.myForm.get('range').value;
    var start = dateTime[0];
    var end = dateTime[1];
    var wpKind = this.myForm.get('kind').value;
    var isUe18 = this.myForm.get('ue18').value;
    var skipShiftCollision = this.myForm.get('skipShiftCollision').value;

    if (
      wpKind == WorkPackageKind.Construction &&
      this.data.constructionShiftPresent &&
      !this.modifying
    ) {
      this.commonFunctions.showErrorToast('Aufbau-Schicht bereits vorhanden');
      return;
    }
    if (
      wpKind == WorkPackageKind.Dismantling &&
      this.data.dismantlingShiftPresent &&
      !this.modifying
    ) {
      this.commonFunctions.showErrorToast('Abbau-Schicht bereits vorhanden');
      return;
    }

    //set ignore shift collision to false in case of construction/dismantling shift
    if (wpKind == WorkPackageKind.Dismantling || wpKind == WorkPackageKind.Construction) {
      skipShiftCollision = false;
    }

    if (this.modifying) {
      var id = this.data.id;
      // update wp
      var set = await this.dataModel.setWorkPackage(
        this.data.parentPath,
        type,
        minParticipants,
        start,
        end,
        respPersonUserName,
        respPersonUserId,
        id,
        wpKind,
        true,
        false,
        isUe18,
        skipShiftCollision
      );
    } else {
      // add wp
      var set = await this.dataModel.setWorkPackage(
        this.data.path,
        type,
        minParticipants,
        start,
        end,
        respPersonUserName,
        respPersonUserId,
        '',
        wpKind,
        false,
        true,
        isUe18,
        skipShiftCollision
      );
    }

    if (set.result) {
      var msg = 'Schicht erfolgreich hinzugefügt';
      if (this.modifying) {
        msg = 'Schicht erfolgreich angepasst';
      }
      this.commonFunctions.showSuccessToast(msg);
    } else {
      var msg = 'Schicht konnte nicht hinzugefügt werden';
      if (this.modifying) {
        msg = 'Schicht konnte nicht angepasst werden';
      }
      this.commonFunctions.showErrorToast(msg);
    }
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }
}
