import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { IDateRange } from 'src/app/services/definitions.service';
import { LogService } from 'src/app/services/log.service';

// https://angular.io/guide/view-encapsulation
@Component({
  selector: 'time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TimeRangePickerComponent implements OnInit {
  @Input() start: Date;
  @Input() end: Date;
  @Input() disable: boolean = false;
  @Output() timeChanged = new EventEmitter<IDateRange>();

  startFormControl = new FormControl();
  endFormControl = new FormControl();
  myForm: FormGroup;
  maxStartDate: string;
  minStartDate: string;
  maxEndDate: string;
  minEndDate: string;

  constructor(fb: FormBuilder, private log: LogService) {
    this.myForm = fb.group({
      from: this.startFormControl,
      end: this.endFormControl,
    });
  }

  ngOnInit(): void {
    var currentStart: string = "";
    var currentEnd: string = "";

    //set actual values if available
    if (this.start) {
      currentStart = `${this.asSplittedTime(this.start)[0]}:${this.asSplittedTime(this.start)[1]
        }`;
    } else 
      this.log.error("Start time not set.", TimeRangePickerComponent.name.toString());
    
    if (this.end) {
      currentEnd = `${this.asSplittedTime(this.end)[0]}:${this.asSplittedTime(this.end)[1]
        }`;
    } else 
      this.log.error("End time not set.", TimeRangePickerComponent.name.toString());

    this.myForm.controls['from'].setValue(currentStart);
    this.myForm.controls['end'].setValue(currentEnd);

    if (this.disable) {
      this.myForm.controls['from'].disable();
      this.myForm.controls['end'].disable();
    }
  }

  startTimeChanged(value: string) {
    this.start = this.toDate(value);
    var date: IDateRange = { start: this.start, end: undefined, valid: true };
    this.timeChanged.emit(date);
  }

  endTimeChanged(value: string) {
    this.end = this.toDate(value);
    var date: IDateRange = { start: undefined, end: this.end, valid: true };
    this.timeChanged.emit(date);
  }

  private asSplittedTime(date: Date): string[] {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var minutesStr = minutes.toString();

    if (minutes < 10) 
      minutesStr = '0' + minutesStr;    

    return date
      ? [hours.toString(), minutesStr]
      : ['', ''];
  }

  private toDate(dateAsString: string): Date {
    if (dateAsString) {
      var splitted = dateAsString.split(':');
      var hours = +splitted[0];
      var min = +splitted[1];

      var newDate: Date = new Date();
      newDate.setHours(hours);
      newDate.setMinutes(min);
      return newDate;
    }
    return undefined;
  }
}
